import { Component, ElementRef, Input, TemplateRef } from "@angular/core";
import { ShipmentEntryMode, TaskType } from "@wearewarp/types"
import { FormDataShipmentLocation } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { BaseForm } from "../../../../base/form-base";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { LocationFilterHeper } from "./helper/location";
import { Const } from "@const/Const";
import { ServiceOptionsHelper } from "./helper/service-options";
import { ExtendValidators } from "@app/admin/base/validator";
import { Utils } from "@services/utils";
import { BizUtil } from "@services/biz";
import { FormDataWindowTime, WindowTimeHelper } from "./helper/window";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { ApiService } from "@services/api.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TimeWindow } from "@wearewarp/types/data-model";

@Component({
  selector: '[form-shipment-location]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormShipmentLocation extends BaseForm<FormDataShipmentLocation> {
  // Có thể dùng 1 trong những template đã được implement sẵn hoặc là implement 1 template mới
  @Input() predefinedTemplate: 'default' | 'mobile' = 'default';
  @Input() viewTemplate: TemplateRef<any>;
  @Input() type: TaskType;
  @Input() genId: () => string = () => Utils.generateULID()
  @Input() canChangeLocation: boolean = true;
  @Input() otherLocations: FormDataShipmentLocation[];    // Dùng để tính traffic info
  @Input() shipmentEntryMode: ShipmentEntryMode;

  public get shouldUseTemplateDefault() { return this.predefinedTemplate == 'default' }
  public get shouldUseTemplateMobile() { return this.predefinedTemplate == 'mobile' }

  protected formGroupDeclaration: FormGroupDeclaration = {
    id: {label: '', readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    shipmentId: {label: '', readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    clientId: {label: '', readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    type: {label: '', required: true, readOnly: true, submitReadOnly: true, notAcceptEmpty: true},
    locationName: {label: 'Location Name', notAcceptNull: true},
    warehouseId: {label: ''},
    addr: {label: 'Address', required: true},
    windows: {label: 'Windows', type: 'formArray', required: true, initialValue: [{}], childItem: {
      // from: {label: '', type: 'time', required: true, placeHolder: 'from date time', isChanged: DateUtil.diffHHmm},
      // to: {label: '', type: 'time', required: true, placeHolder: 'to date time', isChanged: DateUtil.diffHHmm},
      range: {label: '', required: true, placeHolder: ['Start date time', 'End date time'], validators: ExtendValidators.validateTimeWindow}    // dùng với <nz-range-picker>
    }},
    requiresAppointment: {label: 'Location requires appointment', type: 'boolean', initialValue: false },
    appointmentInfo: {label: 'Requested Date', type: 'formGroup', required: false, initialValue: [{}], childItem: {
      range: {label: '', required: false, placeHolder: ['Start date time', 'End date time'], validators: []}
    }},
    serviceOptions: {label: 'Service Options'},
    accessCode: {label: 'Access Code', notAcceptNull: true},
    instructions: {label: 'Instruction (External)', notAcceptNull: true, multiline: true},
    note: {label: 'Note (Internal)', notAcceptNull: true, multiline: true},
    refNums: {label: 'Reference number (can be multiple)', type: 'formArray', childItem: {label: 'Ref', notAcceptEmpty: true}, initialValue: ['']},
    primaryContact: {label: 'Primary Contact', type: 'formGroup', childItem: {
      fullName: {label: 'Contact Name'},
      phone: {label: 'Phone Number'},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Contact Email', validators: Validators.email}
    }},
    secondaryContact: {label: 'Secondary Contact', type: 'formGroup', childItem: {
      fullName: {label: 'Contact Name'},
      phone: {label: 'Phone Number'},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Contact Email', validators: Validators.email}
    }},
    settings: { label: 'Settings' , initialValue: Const.DefaultLocationSettings }
  }

  public locationHelper: LocationFilterHeper;
  public serviceOptionsHelper: ServiceOptionsHelper;

  constructor(
    protected hostElement: ElementRef<HTMLElement>,
    protected api: ApiService,
    protected notification: NzNotificationService
  ) {
    super(hostElement);
  }

  ngOnInit(): void {
    if (!this.type) {
      throw Error('type is required');
    }
    this.locationHelper = new LocationFilterHeper(data => this.setLocation(data));
    this.serviceOptionsHelper = new ServiceOptionsHelper(this.type, () => this.getItemValue('serviceOptions'), data => this.setItemValue('serviceOptions', data));
    this.formGroupDeclaration.type.initialValue = this.type;
    if (this.model) {
      this.formGroupDeclaration.id.initialValue = this.model.id;
      if (Utils.isArrayNotEmpty(this.model.serviceOptions)) {
        this.serviceOptionsHelper.bindSelectedItems(this.model.serviceOptions);
      }
    }
    if (!this.canChangeLocation && this.model?.warehouseId) {
      this.formGroupDeclaration.locationName.readOnly = true;
      this.formGroupDeclaration.locationName.submitReadOnly = true;
    }
    super.ngOnInit();
    if (this.model) this.onRequiresAppointmentChanged(this.model.requiresAppointment);
    if (!this.canChangeLocation && this.model?.warehouseId) {
      this.onLocationSelected({isUserInput: true}, {id: this.model.warehouseId});
    }
  }

  onRequiresAppointmentChanged(value: boolean) {
    let windowsChildKeys = [
      // 'from', 'to',
      'range'
    ];
    const isRequired = !value;
    this.formGroupDeclaration.windows.required = isRequired;
    for (let childKey of windowsChildKeys) {
      (<FormGroupDeclaration>this.formGroupDeclaration.windows.childItem)[childKey].required = isRequired;
    }
    const fa = <FormArray>this.formInput.get('windows');
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      for (let childKey of windowsChildKeys) {
        let fc = <FormControl>fg.get(childKey);
        if (fc) {
          // Nếu requires appointment thì ko bắt buộc nhập windows nữa 
          if (!isRequired) {
            fc.removeValidators(Validators.required);
            fc.removeValidators(ExtendValidators.validateTimeWindow);
          } else {
            fc.addValidators(Validators.required);
            fc.addValidators(ExtendValidators.validateTimeWindow);
          }
          fc.updateValueAndValidity();
        }
      }
    }
  }

  private setLocation(locationObject: any) {
    this.setItemValue(`addr`, locationObject?.pickAddr);
    this.setItemValue(`primaryContact.fullName`, locationObject?.contactName);
    this.setItemValue(`primaryContact.phone`, locationObject?.phone);
    this.setItemValue(`primaryContact.phoneExtension`, locationObject?.phoneExtension);
    this.setItemValue(`primaryContact.email`, locationObject?.email);
    this.setItemValue(`secondaryContact.fullName`, locationObject?.secondaryContact?.contactName);
    this.setItemValue(`secondaryContact.phone`, locationObject?.secondaryContact?.phone);
    this.setItemValue(`secondaryContact.phoneExtension`, locationObject?.secondaryContact?.phoneExtension);
    this.setItemValue(`secondaryContact.email`, locationObject?.secondaryContact?.email);
    if (this.type == Const.TaskType.PICKUP && locationObject?.pickDetails) {
      this.setItemValue(`accessCode`, locationObject?.pickDetails?.accessCode);
      this.setItemValue(`instructions`, locationObject?.pickDetails?.instructions);
      this.setItemValue(`note`, locationObject?.pickDetails?.note);
    } else if (this.type == Const.TaskType.DROPOFF && locationObject?.dropDetails) {
      this.setItemValue(`accessCode`, locationObject?.dropDetails?.accessCode);
      this.setItemValue(`instructions`, locationObject?.dropDetails?.instructions);
      this.setItemValue(`note`, locationObject?.dropDetails?.note);
    }
    if (locationObject?.requireAppointment) {
      this.setItemValue(`requiresAppointment`, true);
      this.onRequiresAppointmentChanged(true);
    } else {
      this.setItemValue(`requiresAppointment`, false);
      this.onRequiresAppointmentChanged(false);
    }
    if (locationObject?.name && !this.getItemValue('locationName')) {
      this.setItemValue(`locationName`, locationObject?.name);
    }
    // TODO: handle service options at location level
    // if (Utils.isArrayNotEmpty(locationObject?.serviceOptions)) {
    //   let serviceOptionAddition = [];
    //   for (let item of locationObject?.serviceOptions) {
    //     if (this.serviceOptions.pickInfo.allItems.map(obj => obj._id).includes(item)) {
    //       if (!this.serviceOptions.pickInfo.selectedItems.includes(item)) {
    //         this.serviceOptions.pickInfo.selectedItems.push(item);
    //       }
    //     }
    //     if (MasterData.ShipmentServiceOptionsAddition.map(obj => obj._id).includes(item)) {
    //       serviceOptionAddition.push(item);
    //     }
    //   }
    //   this.serviceOptionsSelectionChange('pickInfo');
    //   // Nếu tồn tại service option có type là addition => update service option tại formCost
    //   if (Utils.isArrayNotEmpty(serviceOptionAddition)) {
    //     this.serviceOptionsAdditionChange(serviceOptionAddition);
    //   }
    // }
    if (!locationObject) {
      this.setItemValue(`locationName`, '');
    } else {
      this.setItemValue(`warehouseId`, locationObject?.id);
    }
  }

  public getFormData(): FormDataShipmentLocation {
    let data = super.getFormData();
    let timezone = data.addr?.metadata?.timeZoneStandard;
    let windows = WindowTimeHelper.formDataToModel(<FormDataWindowTime[]>(data.windows ?? []), timezone);
    data.windows = windows;

    let appointmentInfo = WindowTimeHelper.formDataToModel(<FormDataWindowTime[]>(data.appointmentInfo ? [data.appointmentInfo] : []), timezone);
    data.appointmentInfo = appointmentInfo.length ? appointmentInfo[0] : { from: null, to: null };

    let refNums = [];
    for (let item of data.refNums ?? []) {
      if (item && item.trim().length > 0) {
        refNums.push(item);
      }
    }
    data.refNums = refNums;
    data.contacts = [];
    if (BizUtil.isContactNotEmpty(data.primaryContact)) {
      data.contacts.push({...data.primaryContact, type: Const.ContactType.primary});
    }
    if (BizUtil.isContactNotEmpty(data.secondaryContact)) {
      data.contacts.push({...data.secondaryContact, type: Const.ContactType.secondary});
    }
    delete data.primaryContact;
    delete data.secondaryContact;
    if (!data.id) {
      data.id = this.genId();
      this.setItemValue('id', data.id);
    }
    return data;
  }

  protected beforeBindModel(model: FormDataShipmentLocation): FormDataShipmentLocation {
    if (model && !model.id) {
      // Chỗ này cần đảm bảo server trả về phải có sẵn id rồi, nếu ko thì id sẽ bị override khi save
      throw Error(`FormDataShipmentLocation model must have id before`);
    }
    let timezone = model.addr?.metadata?.timeZoneStandard;
    if (timezone) {
      let windows = WindowTimeHelper.modelToFormData(model.windows ?? [], timezone);
      let data: any[] = [{ from: model.appointmentInfo?.from, to: model.appointmentInfo?.to }];
      let appointmentInfo = WindowTimeHelper.modelToFormData(data, timezone);
      model.appointmentInfo = <any>appointmentInfo[0];

      model.windows = windows.length ? <any>windows : [{}];
    }
    
    for (let item of model.contacts ?? []) {
      switch (item.type) {
        case Const.ContactType.primary:
          model.primaryContact = item;
          break;
        case Const.ContactType.secondary:
          model.secondaryContact = item;
          break;
      }
    }
    model.refNums = model.refNums?.length ? model.refNums : [''];
    return model;
  }

  get isDisableAddressInput() {
    if (this.canChangeLocation || !this.model?.warehouseId) return false;
    return true;
  }

  // call from template outside
  onFilterTextChange(text: string) {
    this.locationHelper.onFilterTextChange(text);
    if (!text) {
      this.setItemValue(`warehouseId`, '');
    }
  }
  get locationsFiltered() { return this.locationHelper.locationsFiltered }
  onLocationSelected(event, data) {
    this.locationHelper.onLocationSelect(event, data)
  }
  getFormArrayControls(key: string) {
    return (<FormArray>this.formInput.get(key))?.controls ?? [];
  }
  shouldShowButtonAddFormArray(key: string, index: number): boolean {
    return index == this.getFormArrayLength(key) - 1;
  }
  shouldShowButtonRemoveFormArray(key: string, index: number): boolean {
    return this.getFormArrayLength(key) > 1;
  }
  onBtnAddFormArray(key: string) {
    this.addItemToFormArray(key);
  }
  onBtnRemoveFormArray(key: string, index: number) {
    let isEmptyValue = false;
    let itemValue = this.getItemValue(`${key}[${index}]`);
    let message = `Remove item at position <b>${index + 1}</b>?`;
    switch (key) {
      case 'windows':
        isEmptyValue = itemValue?.range == null || (itemValue?.range?.[0] == null && itemValue?.range?.[1] == null);
        break;
      case 'appointmentInfo':
        isEmptyValue = itemValue?.range == null || (itemValue?.range?.[0] == null && itemValue?.range?.[1] == null);
        break;
      case 'refNums':
        isEmptyValue = !itemValue;
        message = `Remove reference number <b>${itemValue}</b>?`;
        break;
    }
    if (!isEmptyValue) {
      let message = `Remove item at position <b>${index + 1}</b>?`;
      if (key == 'refNums') {
        message = `Remove reference number <b>${itemValue}</b>?`;
      }
      DialogService.confirmDeletion({
        message: message,
        txtBtnOk: 'Remove',
        fnOk: () => this.removeItemInFormArray(key, index)
      })
    } else {
      // empty value thì cho xoá luôn mà không cần confirm
      this.removeItemInFormArray(key, index);
    }
  }

  // Những logic cho traffic và window suggestion hiện chỉ áp với single mode
  trafficInfo: any;
  dropWindowSuggestion: TimeWindow;

  get isShipmentSingleMode() {
    return this.shipmentEntryMode && this.shipmentEntryMode === Const.ShipmentEntryMode.single;
  }

  onAddressChanged() {
    if (this.isShipmentSingleMode && this.type === Const.TaskType.DROPOFF) {
      this.updateTrafficInfo();
    }
  }

  onTimeWindowChanged() {
    if (this.isShipmentSingleMode && this.type === Const.TaskType.DROPOFF) {
      this.checkValidDeliveryTimeWindow();
    }
  }

  // Hiện chỉ áp dụng cho single mode
  async checkValidDeliveryTimeWindow() {
    const [pickInfo, dropInfo] = this.getPickDropLocations();
    if (!pickInfo?.addr || !dropInfo?.addr) return;
    if (pickInfo?.requiresAppointment || dropInfo?.requiresAppointment) return;
    let pickWindow = pickInfo?.windows[0];
    let dropWindow = dropInfo?.windows[0];
    if (!pickWindow || !dropWindow) return;
    let maxDeliveryWindow = {
      from: pickWindow?.from,
      to: dropWindow?.to
    }
    let minDeliveryWindow = {
      from: pickWindow?.to,
      to: dropWindow?.from
    }

    const maxDuration = DateUtil.getTimeDurationFromWindow(maxDeliveryWindow);
    const minDuration = DateUtil.getTimeDurationFromWindow(minDeliveryWindow);
    if (!this.trafficInfo) {
      const traffic = await this.getTraffic(pickInfo, dropInfo);
      this.trafficInfo = traffic?.costs?.[0] || {};
    }
    // Nếu transit time không phù hợp với time window thì show warning
    let epsTime = 15 * 60;     // 15 minutes
    if (this.trafficInfo?.time > (maxDuration/1000 + epsTime) || this.trafficInfo?.time < (minDuration/1000 - epsTime)) {
      let msg = `Transit time is about ${(this.trafficInfo.time / 3600).toFixed(2)} hours. <br/>Please check delivery time window again.`;
      this.notification.create('warning', '', msg);
    }
  }

  getPickDropLocations(): [FormDataShipmentLocation, FormDataShipmentLocation] {
    let otherLocations = this.otherLocations || [];
    if (otherLocations.length === 0) return;
    let pickInfo: FormDataShipmentLocation;
    let dropInfo: FormDataShipmentLocation;
    if (this.type === Const.TaskType.PICKUP) {
      pickInfo = this.getFormData();
      dropInfo = otherLocations.find(it => it && it.type === Const.TaskType.DROPOFF);
      if (dropInfo) {
        dropInfo.windows = this.getModelTimeWindowsForLocation(dropInfo);
      }
    }
    else if (this.type === Const.TaskType.DROPOFF) {
      dropInfo = this.getFormData();
      pickInfo = otherLocations.find(it => it && it.type === Const.TaskType.PICKUP);
      if (pickInfo) {
        pickInfo.windows = this.getModelTimeWindowsForLocation(pickInfo);
      }
    }
    return [pickInfo, dropInfo];
  }

  // Tránh trường hợp other location truyền vào có window là formdata
  getModelTimeWindowsForLocation(location: FormDataShipmentLocation): TimeWindow[] {
    let windows = <FormDataWindowTime[]>location?.windows || [];
    if (windows[0]?.range) {
      let timezone = location.addr?.metadata?.timeZoneStandard;
      location.windows = WindowTimeHelper.formDataToModel(windows, timezone);
    }
    return location.windows;
  }

  async updateTrafficInfo() {
    this.trafficInfo = null;
    const [pickInfo, dropInfo] = this.getPickDropLocations();
    if (!pickInfo?.addr || !dropInfo?.addr) return;
    const traffic = await this.getTraffic(pickInfo, dropInfo);
    this.trafficInfo = traffic?.costs?.[0] || {};
    this.updateDropWindowSuggestion();
  }

  async getTraffic(pickInfo, dropInfo) {
    const fromLatLng = {
      lat: pickInfo?.addr?.metadata?.latitude!,
      lng: pickInfo?.addr?.metadata?.longitude!,
    }
    const toLatLng = {
      lat: dropInfo?.addr?.metadata?.latitude!,
      lng: dropInfo?.addr?.metadata?.longitude!,
    }
    let params = {
      listFrom: [fromLatLng],
      listTo: [toLatLng]
    }
    let url = Const.APIURI_LTL_ROUTING(`routes/getTraffic`);
    const resp = await this.api.POST(url, params).toPromise();
    return resp.data;
  }

  // Chỉ suggest trong tab dropoff
  updateDropWindowSuggestion() {
    if (!this.isShipmentSingleMode || this.type !== Const.TaskType.DROPOFF) return;
    let otherLocations = this.otherLocations || [];
    let pickInfo = otherLocations.find(it => it && it.type === Const.TaskType.PICKUP);
    if (pickInfo) {
      pickInfo.windows = this.getModelTimeWindowsForLocation(pickInfo);
    }
    let pickupWindow = pickInfo?.windows?.[0];
    if (!pickupWindow || !this.trafficInfo?.time) return;
    this.dropWindowSuggestion = this.getDropoffWindowSuggestion(pickupWindow, this.trafficInfo.time);
  }

  getDropoffWindowSuggestion(pickupWindow: TimeWindow, transitTime: number): TimeWindow {   // transit time (s)
    let dropTimestampFrom = DateUtil.isoDate(pickupWindow.from).getTime() + (transitTime * 1000);  // do timestamp tính theo ms
    let dropTimestampTo = DateUtil.isoDate(pickupWindow.to).getTime() + (transitTime * 1000);
    // Lùi về step (15p) ngay trước
    let suggestMinutesFrom = ((dropTimestampFrom / (1000 * 60)) % 60) - ((dropTimestampFrom / (1000 * 60)) % 15);
    let suggestMinutesTo = ((dropTimestampTo / (1000 * 60)) % 60) - ((dropTimestampTo / (1000 * 60)) % 15);
    let fromDate = DateUtil.timestampToDate(dropTimestampFrom);
    let toDate = DateUtil.timestampToDate(dropTimestampTo);
    fromDate.setMinutes(suggestMinutesFrom);
    toDate.setMinutes(suggestMinutesTo);

    const dropWindowSuggestion: TimeWindow = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    }
    return dropWindowSuggestion;
  }

  displayDropWindowSuggestion() {
    if (!this.isShipmentSingleMode || this.type !== Const.TaskType.DROPOFF) return '';
    if (!this.dropWindowSuggestion) return '';
    const dropAddr = this.getItemValue('addr');
    const timezone = dropAddr?.metadata?.timeZoneStandard || null;
    if (timezone) {
      return DateUtil.displayTimeWindow(this.dropWindowSuggestion, {
        timezone: timezone,
        format: 'MM-DD-YYYY HH:mm',
        formatDateOnly: 'MM-DD-YYYY'
      });
    }
    return "";
  }

}