import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Order, Shipment, ShipmentItem } from '@wearewarp/types/data-model';
import { DateUtil } from '@services/date-utils';
import _ from 'underscore'
import { Const } from '@const/Const';
import { ImageUtil } from '@services/image-util';

@Component({
    selector: '[warehouse-item-content]',
    templateUrl: './item_content.html',
    styleUrls: ['./item_content.scss']
})
export class WarehouseItemContent extends BaseComponent {
    _item: any;
    shipmentItems: any[] = []
    pods: any[] = null
    inboundJobBols: any[] = null
    @Input() viewed: boolean = false
    compactPod: boolean = true
    showNetwork: boolean = false
    inboundShipment

    @Input() set item(value) {
        this._item = value;
        let items = []
        if (this._item.item) {
            for (let x of this._item.item) {
                if (x.internalBarcode) {
                    items.push(x)
                }
                else if (x.children && x.children.length) {
                    items = items.concat(x.children)
                } else {
                    items.push(x)
                }
            }
        }
        items = _.sortBy(items, 'internalBarcode')
        const inventories: any = {}
        if (value.inventory) {
            for (let invt of value.inventory) {
                for (let it of invt.items) {
                    inventories[it.itemId] = invt
                }
            }
        }
        for (let it of items) {
            it.inventory = inventories[it.id]
            if (it.image) {
                console.log(it.id, it.image)
            }
        }

        if (this._item.inboundJob) {
            const metadata = (this._item.inboundJob.warehouseMetadatas || []).filter(it => it.inOutType === 'inbound')[0]
            const { bolImages } = metadata || []
            this.inboundJobBols = (bolImages || []).map(it => Object.assign({
                fullPath: it,
                type: 'ROUTE_BOL',
            }))
        }

        this.shipmentItems = items
        this.inboundShipment = (this._item.inboundShipment || [])[0]
    }

    uploadingPodType: string = null
    onAddPodFile(type) {
        this.uploadingPodType = type
        this.onOpenUploadForm()
    }

    onOpenUploadForm() {
        setTimeout(() => {
          if (this.uploadingPodType) {
            let element: HTMLElement = document.getElementById("uploadPODInput-" + this.inboundShipment.id) as HTMLElement;
            //nếu chưa có element thì đợi thêm 200ms và thử lại
            if (!element) {
              return this.onOpenUploadForm();
            }
            // this.openUploadFormChange.emit(false);
            element.click();
          }
        }, 200);
    }

    onFileSelected(inputElement: HTMLInputElement) {
        let file = inputElement.files[0];
        inputElement.value = "";
        ImageUtil.resizeImageIfNeeded(file).then((blob) =>
            this.uploadPOD(blob, blob.name)
        );
    }
    
    isUploading = false
    private async uploadPOD(file: Blob, fileName: string) {
        this.isUploading = true;
        const url = Const.APIV2(`pods`);
        let formData = new FormData();
        formData.append("file", file, fileName);
        formData.append("params", JSON.stringify({
            shipmentId: this.inboundShipment.id,
            jobId: this.inboundShipment.lastJobId,
            type: this.uploadingPodType
        }));
        this.api.postFormData(url, formData).subscribe(
          (resp) => {
            this.isUploading = false;
            //this.refreshRoute.emit();
            const raw: any = resp

            this.showSuccess("Your POD has been added successfully.");
            if (raw.file) {
                raw.fullPath = this.attachedFileUrl(raw.file)
            }
            this.pods.push(raw)
          },
          (err) => {
            this.isUploading = false;
            this.showErr(err);
          }
        );
    }

    ngOnChanges(): void {
        if (this._item.expanded && !this.pods) {
            this.loadPods()
            if (!this.showNetwork) {
                this.showNetwork = true
            }
        }
    }

    async loadPods() {
        this.pods = (this._item.pods || []).concat(this.inboundJobBols).filter(it => it)
        for (let x of this.pods) {
            if (x.file)
                x.fullPath = this.attachedFileUrl(x.file)
        }
        /*
        if (this._item?.inboundShipment?.length) {
            const url = Const.APIV2(`pods/SHIPMENT_${this._item.inboundShipment[0].id}`);
            this.api.GET(url).subscribe(res => {
                for (let x of res) {
                    if (x.file)
                        x.fullPath = this.attachedFileUrl(x.file)
                }
                this.pods = res.concat(this.inboundJobBols).filter(it => it)
            })    
        }
        */
    }
}