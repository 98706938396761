import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
  selector: '[shipment-photo]',
  templateUrl: './index.html',
  styleUrls: ['index.scss']
})
export class ShipmentPhoto extends BaseComponent {
    _photos: any[] = []
    items: any[] = []
    @Input() set photos(v) {
        this._photos = v
        const itemIds = _.uniq(v.map(it => it.itemId))
        const items = _.groupBy(_.sortBy(v, 'itemId'), 'itemId')
        this.items = itemIds.map(it => {
            const p = items[it]
            return {
                item: p[0].item,
                photos: p
            }
        }).filter(it => it.item).filter(it => it.photos?.length)
        this.selectedPhoto = this.items[0].photos[0]
        for (let p of this._photos) {
            if (p.insert?.when) {
                p.date = dayjs(p.insert.when).format(Const.FORMAT_GUI_DATETIME_SHORT)
            }
        }
    }
    selectedPhoto: any = null

    onSelectPhoto(p) {
        this.selectedPhoto = p
    }
}