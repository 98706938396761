import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortLocationInfo } from './short_location';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [ShortLocationInfo],
  exports: [ShortLocationInfo]
})
export class ModuleShortLocationInfo {}