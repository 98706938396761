<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading">
  <div *ngIf="jobHyperLink" class="bottom40 f18 text-center">
    Related route: <a [routerLink]="[jobHyperLink.hyperLinkUrl]" target="_blank">{{jobHyperLink.hyperLinkText}}</a>
  </div>
  <nz-row>
    <nz-col [nzMd]="24" [nzLg]="{span:16, offset:4}">
      <div class="post-container">
        <third-party-post-detail2 [data]="data" (onDelete)="onBack()" (onBack)="onBack()"></third-party-post-detail2>
      </div>
    </nz-col>
  </nz-row>
</div>
