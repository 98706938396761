
<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap;">
    <!-- <div class="form-item">
      <div class="form-label-v2">Keyword</div>
      <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="txtHintSearch" [searchKeyword]="searchKeyword" style="width: 100%;"></search-box>
    </div> -->

    <ng-container *ngFor="let key of formInputKeys">
      <div class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container [ngSwitch]="key">

          <select-by-searching *ngSwitchCase="'clientIds'"
            [version]="2"
            type="client"
            [apiUrl]="getApiListDataForFilter(key)"
            [formControlName]="key"
            [nzMode]="'multiple'"
            [showSubClient]="true"
            dropdownClassName="customer-warehouse-filter-client"
            [placeholder]="getPlaceHolder(key)"
            (modelChange)="onChange(key, $event)">
          </select-by-searching>

          <nz-select style="width: 100%;"
            *ngSwitchCase="'warpLocation'"
            [formControlName]="key"
            nzDropdownClassName="customer-warehouse-filter-warp-location"
            [nzPlaceHolder]="getPlaceHolder(key)" 
            (ngModelChange)="onChange($event, key)" >
            <nz-option nzLabel="" nzValue=""></nz-option>
            <nz-option nzLabel="Has been linked" nzValue="linked"></nz-option>
            <nz-option nzLabel="Not linked yet" nzValue="not-linked"></nz-option>
          </nz-select>

          <input *ngSwitchDefault nz-input 
            [formControlName]="key" 
            [placeholder]="getPlaceHolder(key)" 
            (keyup)="onInputKeyUp(key, $event)"/>
        </ng-container>
      </div>
    </ng-container>

  </div>
</form>