<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div customer-warehouse-filter style="flex: 1"></div>
    <!-- <button nz-button (click)="inputFile.click()">Import Locations for Gopuff</button> -->
    <button nz-button (click)="onBtnAdd()"><i nz-icon nzType="plus" nzTheme="outline"></i>Create New</button>
    <input #inputFile type="file" hidden accept=".csv,.xlsx,.xls" (change)="onFileSelected_GoPuff(inputFile.files)">
  </div>
  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="clientLocId">Customer Site ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="clientLocName">Customer Site Name</th>
          <th>Address Text</th>
          <th>Customer</th>
          <th>Linked to WARP Location</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="createdWhen">Created when</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a class="link" [routerLink]="[routeAdminCustomerLocationList, item.id]">{{item.clientLocId}}</a>
          </td>
          <td>
            <a class="link" [routerLink]="[routeAdminCustomerLocationList, item.id]">{{item.clientLocName}}</a>
          </td>
          <td>{{item.addressText}}</td>
          <td>
            <a class="link" target="_blank" [routerLink]="[routeAdminClientList, item.client.id]">{{item.client.name}}</a>
          </td>
          <td>
            <a *ngIf="item.warehouse" class="link" target="_blank" [routerLink]="[routeAdminLocationList]" [queryParams]="{search: item.warehouse.warpId}">{{showWarpLocation(item)}}</a>
          </td>
          <td>{{displayDateTimeDB(item.createdWhen)}}</td>
        </tr>
      </tbody>
    </nz-table>
    <div w-paginator
      [currentPage]="currentPage"
      [isLastPage]="isLastPage"
      [fnCount]="countTotal"
      (pageChange)="onDataListPageChanged($event)">
    </div>
  </div>
</div>

<ng-template #tplSelectExcelSheets>
  <div style="font-size: 18px; font-weight: 500; margin-bottom: 15px;">Please select a sheet</div>
  <nz-select style="width: 100%;" [(ngModel)]="selectedExcelSheet">
    <nz-option *ngFor="let item of listExcelSheets" [nzValue]="item" [nzLabel]="item"></nz-option>
  </nz-select>
  <div style="font-size: 14px; font-style: italic; margin-top: 15px;">Please note that the file parser will stop whenever it reaches an empty row.</div>
</ng-template>

<ng-template #tplConfirmUploadGopuff>
  <div style="white-space: pre-wrap;">{{msgConfirmUploadGopuff}}</div>
</ng-template>