import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { ShipmentDeliveryInfo } from './delivery_info';
import { ModuleShortLocationInfo } from '../short_location/module';
import { WarehouseTaskSettingModule } from '../../warehouse-task-setting/module';
import { ModuleShortContactInfo } from '../short_contact/module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzIconModule,
    NzPopoverModule,
    NzSwitchModule,
    WarehouseTaskSettingModule,
    ModuleShortContactInfo,
    ModuleShortLocationInfo,
  ],
  declarations: [ShipmentDeliveryInfo],
  exports: [ShipmentDeliveryInfo]
})
export class ModuleShipmentDeliveryInfo {}