import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { CustomerWarehouseListFilter } from '.';
import { SelectBySearchingModule } from '@libs/select-client/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    SelectBySearchingModule,
  ],
  declarations: [CustomerWarehouseListFilter],
  exports: [CustomerWarehouseListFilter]
})
export class ModuleCustomerWarehouseListFilter {
}