<div class="flex bottom5 title">
  <div class="flex flex1">
    <a class="warp-id inherit" [routerLink]="[routeAdminShipmentList, model.getId()]"
      target="_blank">{{showShipmentWarpId(model.getWarpId())}}</a>
    <ng-container *ngIf="getClient()">
      <div class="left5">/</div>
      <a class="inherit left5" [routerLink]="[routeAdminClientList, getClient()._id]"
        target="_blank">{{getClient()?.name}}</a>
    </ng-container>
    <div *ngIf="onHold()" class="hold-badge">HOLD</div>
  </div>
  <div class="pallet-desc">{{getPalletDesc()}}</div>
</div>

<div class="flex">
  <div class="icon-direction noselect">
    <div class="dot">⬤</div>
    <div class="line"></div>
    <div class="dot triangle">▼</div>
  </div>
  <div class="flex1">
    <div class="pick-addr" nz-popover [nzPopoverContent]="getAddress('pick')" nzPopoverPlacement="right">
      {{getLocationName('pick')}}</div>
    <div class="pick-time bottom5" [ngClass]="{'error': isWindowError('pick')}">{{getWindow('pick')}}</div>
    <div class="pick-addr" nz-popover [nzPopoverContent]="getAddress('drop')" nzPopoverPlacement="right">
      {{getLocationName('drop')}}</div>
    <div class="pick-time" [ngClass]="{'error': isWindowError('drop')}">{{getWindow('drop')}}</div>

    <div *ngIf="getRouteId()" class="error">This shipment has been routed on a different route.
      RouteID:
      <a target="_blank" style="font-size: 13px;" [routerLink]="[routeAdminDispatchList, getRouteId()]" nz-tooltip
        nzTooltipTitle="Go to dispatch">
        <code>{{getRouteCode()}}</code>
      </a>
    </div>
  </div>
</div>

<div *ngIf="canRemove" style="display: flex; flex-direction: row-reverse;">
  <button nz-popover nzPopoverContent="Exclude this shipment" nzPopoverPlacement="right" nz-button nzType="text"
    nzShape="circle" nzDanger (click)="onBtnRemove()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</div>