import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { EditShipmentEntryServiceOption } from "@app/admin/shipment-entry/components/edit-shipment/service-option";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { ContactType, TaskType } from '@wearewarp/types';
import { EditShipmentEntryTextField, EditShipmentEntryTextFieldKeys } from "@app/admin/shipment-entry/components/edit-shipment/access-code";
import { EditShipmentEntryReferenceNumber } from "@app/admin/shipment-entry/components/edit-shipment/reference-number";
import { EditShipmentEntryContactInfo } from "@app/admin/shipment-entry/components/edit-shipment/contact-info";
import { BookAppointment, ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { UIHelper } from "@services/UIHelper";
import { RoleManager } from "@services/role-manager";
import { EditShipmentEntryLocation } from '../../../shipment-entry/components/edit-shipment/location-address';
import { FormDataEditLocationAddress } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';



@Component({
    selector: '[shipment-delivery-info]',
    templateUrl: './delivery_info.html',
    styleUrls: ['../styles.scss']
})
export class ShipmentDeliveryInfo extends BaseComponent {
    @Input() shipment: any = null
    @Input() info: any = null
    @Input() editMode: boolean = false
    @Input() isShowActualTime: boolean = false
    name: string = null
    windows: string[] = []
    showExtra: boolean = false
    primaryContact: any = null
    secondaryContact: any = null
    serviceOptions: any[] = []
    scheduledAppointment: any = null
    actualTime: any = {}

    @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>();

    constructor(private modalHelper: ModalHelper) {
      super();
    }

    onReload() {
        this.onUpdated.emit()
    }

    isShowSettingTask(shipment, warehouseId) {
        const { warehouseTasks = [] } = shipment
        return warehouseTasks.filter(it => it.warehouseId === warehouseId).length
    }

    ngOnChanges(): void {
        const { type, contacts, serviceOptions, warehouseId } = this.info || {}
        this.name = type ? (type.substring(0, 1) + type.substring(1).toLowerCase()) : null
        this.windows = this.getTimePeriods(this.info)
        this.primaryContact = (contacts || [])?.filter(it => it && it?.type == Const.ContactType.primary)?.[0]
        this.secondaryContact = (contacts || [])?.filter(it => it && it?.type == Const.ContactType.secondary)?.[0]
        this.serviceOptions = (serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt)).filter(it => it)
        this.scheduledAppointment = this.getAppointmentTime()
        this.loadSortLabel()
        this.loadActualTime();
    }

    onToggleExtra() {
        this.showExtra = !this.showExtra
    }

    getAppointmentTime() {
        const { appointmentInfo } = this.info || {}
        if (!appointmentInfo) return null
        return DateUtil.displayTimeWindow(appointmentInfo, {
            timezone: this.info?.addr?.metadata?.timeZoneStandard,
            formatDateOnly: 'MMM DD',
            format: "MMM DD h:mm A",
        })
    }

    getTimePeriods(info) {
        let windows = info?.windows
            ?.map((window) =>
                DateUtil.displayTimeWindow(window, {
                    timezone: info?.addr?.metadata?.timeZoneStandard,
                    formatDateOnly: 'MMM DD',
                    format: "MMM DD h:mm A",
                })
            );
        return windows?.filter(it => it)
    }

    getDisplayActualTime(time) {
        return DateUtil.displayLocalTime(time,
            {
              timezone: this.info?.addr?.metadata?.timeZoneStandard,
              format: 'MM/DD/YY h:mm A'
            }
        )
    }

    onBtnEditServiceOptions() {
        DialogService.openFormDialog1(EditShipmentEntryServiceOption, {
            nzComponentParams: {
                headerText: `${this.name} Service Options`,
                model: { serviceOptions: this.info.serviceOptions },
                type: this.info.type,
                closeOnSuccess: true,
                onSave: data => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    private update(data) {
        const url = Const.APIV2(`shipments/${this.shipment.id}/delivery-info/${this.info.id}`)
        return this.api.PUT(url, data)
    }

    onBtnEditWindowsTime() {
        UpdateTimeWindows.openModal(this.modalHelper, {
          onSubmitError: err => UIHelper.showErr(err),
          onSubmitSucceeded: data => this.onUpdated?.emit(),
          nzTitle: `${this.name} Time Windows`,
          nzComponentParams: {
            timezone: this.info.addr?.metadata?.timeZoneStandard,
            model: {
              windows: this.info.windows,
              reasonCodeId: this.info.reasonCodeId,
            },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.update(data),
          }
        });
    }

    onBtnEditBookAppointment() {
        const appointmentInfo = this.info?.appointmentInfo || {};
        BookAppointment.openModal(this.modalHelper, {
          onSubmitError: err => UIHelper.showErr(err),
          onSubmitSucceeded: data => this.onUpdated?.emit(),
          nzTitle: `${this.name} Appointment`,
          nzComponentParams: {
            timezone: this.info.addr?.metadata?.timeZoneStandard,
            model: {
              appointmentInfo,
              reasonCodeId: this.info.reasonCodeId,
            },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.update(data),
          }
        });
    }

    onBtnEditContactInfo(contactType: ContactType) {
        const contacts = (this.info?.contacts || []).filter(it => it);
        let indexExist = -1;
        let contactInfo;
        for (let i = 0; i < contacts.length; i++) {
            if (contacts[i] && contacts[i].type === contactType) {
                contactInfo = contacts[i];
                indexExist = i;
                break;
            }
        }
        DialogService.openFormDialog1(EditShipmentEntryContactInfo, {
            nzComponentParams: {
                headerText: `${this.name} ${Utils.capitalizeFirstLetter(contactType)} Contact`,
                model: contactInfo,
                contactType: contactType,
                closeOnSuccess: true,
                onSave: data => {
                    if (indexExist === -1) {
                        contacts.push({ ...data, type: contactType })
                    } else {
                        contacts[indexExist] = { ...data, type: contactType };
                    }
                    return this.update({ contacts })
                },
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    onBtnEditReferenceNumber() {
        let refNums = this.info.refNums;
        DialogService.openFormDialog1(EditShipmentEntryReferenceNumber, {
            nzComponentParams: {
                headerText: `${this.name} Reference Numbers`,
                type: this.info.type,
                model: { refNums },
                closeOnSuccess: true,
                onSave: data => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    private locationType(type: TaskType) {
        return type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    }

    onBtnEditAddressInfo() {
        const deliveryInfo = this.info;
        const formDataModel: FormDataEditLocationAddress = {
            locationName: deliveryInfo.locationName,
            addr: deliveryInfo.addr,
            warehouseId: String(deliveryInfo.warehouseId),
        }
        DialogService.openFormDialog1(EditShipmentEntryLocation, {
            nzAutofocus: null,
            nzComponentParams: {
                headerText: `${this.locationType(this.info.type)} Location`,
                type: this.info.type,
                model: formDataModel,
                closeOnSuccess: true,
                onSave: data => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    onBtnEditAccessCode() {
        this.editTextField(this.name, 'accessCode');
    }

    onBtnEditInstruction() {
        this.editTextField(this.name, 'instructions');
    }

    onBtnEditNote() {
        this.editTextField(this.name, 'note');
    }

    private editTextField(name, key: EditShipmentEntryTextFieldKeys) {
        DialogService.openFormDialog1(EditShipmentEntryTextField, {
            nzComponentParams: {
                keys: [key],
                model: { [key]: this.info[key] },
                isFieldMultiLine: (key: EditShipmentEntryTextFieldKeys) => {
                    switch (key) {
                        case 'accessCode':
                            return false;
                        case 'instructions':
                        case 'note':
                            return true;
                    }
                },
                getFieldLabel(key) {
                    switch (key) {
                        case 'accessCode': return `${name} Access Code`;
                        case 'instructions': return `${name} Instructions (External)`;
                        case 'note': return `${name} Note (Internal)`;
                    }
                },
                onSave: (data) => this.update(data),
                onRefreshDetailOrder: () => this.onUpdated?.emit()
            },
            nzClassName: "modal",
        });
    }

    copyValue(value) {
        Utils.copyTextToClipboard(value, (e) => {
            if (e) { } else {
                this.showSuccess(
                    `Copied to the clipboard: ${value}`
                );
            }
        });    
    }

    appointmentRequireChanged(event) {
        this.update({requiresAppointment: event || false}).subscribe((res) => {
            if (res.message === 'Success') {
                this.showInfo(`${this.name} Appointment requirement updated`)
                this.onUpdated?.emit();
            }
        })
    }

    sortLabel: any = null
    loadSortLabel() {
        this.sortLabel = null
        const { warehouseId } = this.info || {}
        const canViewSortLabel = warehouseId && this.authUser.roles.filter(it => it.code === RoleManager.warehouseOperator).length > 0
        if (!canViewSortLabel) return
        const url = Const.APIV2(`labels/search`)

        this.api.POST(url, {warehouseId, shipmentId: this.shipment.id}).subscribe((res) => {
            if (res && res.length)
                this.sortLabel = res
        })
    }

    loadActualTime() {
        if (!this.info?.type) return;
        let arrivedTime: any = '';
        let departedTime: any = '';
        switch (this.info.type) {
            case Const.TaskType.PICKUP:
                arrivedTime = this.getDisplayActualTime(this.getStatusTime('arrivedAtPickup'));
                departedTime = this.getDisplayActualTime(this.getStatusTime('pickupSuccessful'));
                break;
            case Const.TaskType.DROPOFF:
                arrivedTime =  this.getDisplayActualTime(this.getStatusTime('arrivedAtDropoff'));
                departedTime = this.getDisplayActualTime(this.getStatusTime('complete'));
                break;
            default:
                break;
        }
        this.actualTime = {
            "arrived": arrivedTime ?? departedTime,
            "departed": departedTime
        }
    }

    getStatusTime(status) {
        const log = this.shipment?.statusChangeLog?.[status];
        let changeWhen = log?.changeWhen ?? log?.when;
        if (!changeWhen) return '';
        return changeWhen;
    }
}