import { Component, Input, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { BaseDialog } from "@dialogs/base-dlg";
import { environment } from "@env/environment";

@Component({
  selector: 'upload-shipment-items-manifest',
  templateUrl: './upload-manifest.html',
  styleUrls: ['./index.scss']
})
export class UploadItemsManifest extends BaseDialog {
    @Input() shipmentWarpId;
    @Input() shipmentId
    @Input() onSaved: (data) => void;
    uploading: boolean = false

    file: File | null = null
    onFileSelected(inputElement: HTMLInputElement) {
        this.file = inputElement.files[0];
    }

    onBtnSave() {
        if (!this.file) return
        const url = `${environment.supportUrl}/saks/upload-items`
        let formData = new FormData();
        formData.append("file", this.file, "file");
        formData.append("shipmentId", this.shipmentId);
        this.uploading = true
        this.api.postFormData(url, formData).subscribe({
            next: (resp) => {
                this.uploading = false
                this.onSaved && this.onSaved(resp)
                this.getModalRef().close()
                this.showSuccess('Item Manifest have been uploaded successfully')
            },
            error: (res) => {
                this.uploading = false
                this.showErr(`Error while uploading file. ${res.message}`)
            }
        })
    }
}
