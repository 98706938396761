import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Line } from '@antv/g2plot';
import { BaseComponent } from "@abstract/BaseComponent";
import { DateUtil } from "@services/date-utils";
import DataSample from "./dataSample.json"

const ViewMode = {
  number: 'number',
  percentage: 'percentage',
}
const SendType = {
  email: 'email',
  sms: 'sms',
}

const ResponseType = {
  delivered: 'delivered',
  opened: 'opened',
  responsed: 'responsed',
  placedBid: 'placedBid',
}

@Component({
  selector: '[detail-bid-outreach-performance]',
  templateUrl: 'index.html',
  styleUrls: [
    './style.scss',
  ]
})
export class DetailBidOutreachPerformance extends BaseComponent {

  public isLoading = false;
  public displayInfo: any = {};
  public dataChart: any[] = [];
  protected chart = null;
  private _carrierBidId;
  viewModeValue: 'number' | 'percentage' = 'number';

  arrSendTypes = [
    { key: SendType.email, value: 'Email'},
    { key: SendType.sms, value: 'SMS'},
  ]

  arrResponseTypes = Object.keys(ResponseType);

  @Input() set carrierBidId(value) {
    if (!value) return;
    this._carrierBidId = value;
    this.getData();
  }
  get carrierBidId() { return this._carrierBidId }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getLabelResponseType(key) {
    switch (key) {
      case ResponseType.delivered: return 'Delivered';
      case ResponseType.opened: return 'Opened';
      case ResponseType.responsed: return 'Responded';
      case ResponseType.placedBid: return 'Placed bid';
    }
  }

  private getData() {
    this.isLoading = true;
    const step = 15; // step tính bằng phút ( mặc định là 30, min = 5 )
    const url = `${Const.APIURI_CARRIER_BIDS}/${this.carrierBidId}/response-rate?step=${step}`;
    this.api.GET(url).subscribe(
      resp => {
        this.buildDisplayInfo(resp.data);
        this.isLoading = false;
      }, err => {
        // this.buildDisplayInfo(DataSample.data); // testing
        this.isLoading = false;
      }
    );
  }

  private buildDisplayInfo(data) {
    if (!data) return;
    const totalDelivered = (data.totalEmail?.delivered ?? 0) + (data.totalSms?.delivered ?? 0);
    const smsPercent = !totalDelivered ? null : (((data.totalSms?.delivered ?? 0) / totalDelivered) * 100).toFixed(2);
    const emailPercent = !totalDelivered ? null : (((data.totalEmail?.delivered ?? 0) / totalDelivered) * 100).toFixed(2);
    this.displayInfo = {
      percentage: {
        [SendType.sms] : smsPercent,
        [SendType.email] : emailPercent,
      },
      viewMode: {
        [ViewMode.number]: {
          [SendType.sms]: {
            [ResponseType.delivered] : data.totalSms.delivered,
            [ResponseType.opened] : data.totalSms.opened,
            [ResponseType.responsed] : data.totalSms.responsed,
            [ResponseType.placedBid] : data.totalSms.placed,
          },
          [SendType.email]: {
            [ResponseType.delivered] : data.totalEmail.delivered,
            [ResponseType.opened] : data.totalEmail.opened,
            [ResponseType.responsed] : data.totalEmail.responsed,
            [ResponseType.placedBid] : data.totalEmail.placed,
          }
        },
        [ViewMode.percentage]: {
          [SendType.sms]: {
            [ResponseType.delivered]: data.totalSms.delivered,
            [ResponseType.opened]: data.totalSms.delivered ? `${(data.totalSms.opened * 100 / data.totalSms.delivered).toFixed(2)}%` : '',
            [ResponseType.responsed]: data.totalSms.delivered ? `${(data.totalSms.responsed * 100 / data.totalSms.delivered).toFixed(2)}%` : '',
            [ResponseType.placedBid]: data.totalSms.delivered ? `${(data.totalSms.placed * 100 / data.totalSms.delivered).toFixed(2)}%` : '',
          },
          [SendType.email]: {
            [ResponseType.delivered]: data.totalEmail.delivered,
            [ResponseType.opened]: data.totalEmail.delivered ? `${(data.totalEmail.opened * 100 / data.totalEmail.delivered).toFixed(2)}%` : '',
            [ResponseType.responsed]: data.totalEmail.delivered ? `${(data.totalEmail.responsed * 100 / data.totalEmail.delivered).toFixed(2)}%` : '',
            [ResponseType.placedBid]: data.totalEmail.delivered ? `${(data.totalEmail.placed * 100 / data.totalEmail.delivered).toFixed(2)}%` : '',
          },
        }
      }
    }
    this.setupChart(data);
  }

  private setupChart(data) {
    const count = data?.seriesLength;
    const firstTime = data?.series?.times?.[0];
    if (!count || !firstTime) return;
    let dataChart: any[] = [];
    for (let i=0; i<count; i++) {
      let time = data.series?.times?.[i];
      if (!time) continue;
      let displayTime = DateUtil.displayLocalTime(time, { format: 'MMM DD HH:mm'});
      dataChart.push({ time: displayTime, value: data.series?.sentSms?.[i] ?? 0, category: 'Bids Sent SMS' });
      dataChart.push({ time: displayTime, value: data.series?.openSms?.[i] ?? 0, category: 'Opened Bid SMS' });
      dataChart.push({ time: displayTime, value: data.series?.responseSms?.[i] ?? 0, category: 'Response Bid SMS' });
      dataChart.push({ time: displayTime, value: data.series?.sentMail?.[i] ?? 0, category: 'Bids Sent Email' });
      dataChart.push({ time: displayTime, value: data.series?.openMail?.[i] ?? 0, category: 'Opened Bid Email' });
      dataChart.push({ time: displayTime, value: data.series?.responseMail?.[i] ?? 0, category: 'Response Bid Email' });
    }
    this.dataChart = dataChart;
    if (!this.dataChart.length) return;
    this.chart = new Line('container', {
      data: this.dataChart,
      xField: 'time',
      yField: 'value',
      seriesField: 'category',
      legend: {
        position: 'right-top',
      },
      xAxis: {
        // title: {
        //   text: "Time Expired"
        // },
        //tickCount: Math.round(window.innerWidth / 100), //100px per tick
        label: {
          // rotate: Math.PI / 6,
          //offsetX: 25,
          offsetY: 10,
          // autoEllipsis: true,
          autoHide: {
            type: 'equidistance',
            cfg: {
              minGap: 30,
            }
          },
        }
      },
      yAxis: {
        type: 'log'
      },
      color: ({ category }) => {
        switch (category) {
          case 'Bids Sent SMS': return '#6F5EF9'; // 6F5EF9 tim
          case 'Opened Bid SMS': return '#6DC8EC'; // 6DC8EC xanh duong
          case 'Response Bid SMS': return '#5D7092'; // 5D7092 xam
          case 'Bids Sent Email': return '#EF4864'; // EF4864 do
          case 'Opened Bid Email': return '#F6BD16'; // F6BD16 vang
          case 'Response Bid Email': return '#5AD8A6'; // 5AD8A6 xanh la
        }
        return '#FFFFFF';
      },
      lineStyle: ({ category }) => {
        switch (category) {
          case 'Bids Sent Email':
          case 'Bids Sent SMS':
            return { lineWidth: 3, lineDash: [8, 8], opacity: 1 };
          case 'Opened Bid Email':
          case 'Opened Bid SMS':
          case 'Response Bid Email':
          case 'Response Bid SMS':
            return { lineWidth: 3, opacity: 1 };
        }
        return { opacity: 0.5 };
      }
    });
    this.chart.render();
  }

}
