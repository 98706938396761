<div *ngIf="rate" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Rate</div>
    <div nz-col>${{rate}}</div>
</div>
<ng-container *ngIf="identifiers">
    <div nz-row *ngFor="let identifier of identifiers" [nzGutter]="{ md: 16 }" class="bottom10">
        <div nz-col nzXs="10" class="text-right">{{identifier.type}} #</div>
        <div nz-col>{{identifier.value}}</div>
    </div>    
</ng-container>
<div nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">Documents</div>
    <div nz-col>
        <div *ngIf="bolLink" class="bottom5">
            <span class="right10">Bill of Lading</span>
            <span *ngIf="!bolLink.downloading" nz-icon nzType="download" class="clickable" (click)="onDownloadFile(bolLink)" nzTheme="outline"></span>
            <i *ngIf="bolLink.downloading" nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
        <div *ngIf="palletLabelLink" class="bottom5">
            <span class="right10">Pallet Label</span>
            <span *ngIf="!palletLabelLink.downloading" nz-icon nzType="download" class="clickable" (click)="onDownloadFile(palletLabelLink)" nzTheme="outline"></span>
            <i *ngIf="palletLabelLink.downloading" nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </div>
</div>
<div *ngIf="status" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">WWEX status</div>
    <div nz-col>{{status}}</div>
</div>
<div *ngIf="carrier" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">WWEX carrier</div>
    <div nz-col>{{carrier}}</div>
</div>
<div *ngIf="externalTrackingLink" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right">External Tracking</div>
    <div nz-col>
        <a [href]="externalTrackingLink" target="_blank">Open tracking link</a>
    </div>    
</div>
<div *ngIf="canCancel" nz-row [nzGutter]="{ md: 16 }" class="bottom10">
    <div nz-col nzXs="10" class="text-right"></div>
    <div nz-col>
        <div *ngIf="confirmingCancel">
            <div style="text-align: center;">Are you sure you want to cancel with WWEX shipment?</div>
            <div style="text-align: center;">
                <button class="right10" nz-button (click)="onRejectCancelBtn()">NO</button>
                <button [disabled]="cancelling" [nzLoading]="cancelling" nz-button (click)="onConfirmCancelBtn()">YES</button>
            </div>
        </div>
        <div *ngIf="!confirmingCancel">
            <button nz-button (click)="onCancelBtn()">Cancel with WWEX shipment</button>
        </div>
    </div>
</div>
