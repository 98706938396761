import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { ThirdPartyPost } from "@wearewarp/types/data-model/types/third-party";


@Component({
  selector: "third-party-post-result",
  templateUrl: "./third-party-post-result.html",
  styleUrls: ["./third-party-post-result.scss"],
})
export class ThirdPartyPostResultComponent extends BaseComponent {
  @Input() data: ThirdPartyPost[] = [];
  @Input() error: string = "";

  isCreateError(post: ThirdPartyPost) {
    return !!post?.error;
  }

  isExpectedError() {
    return !!this.data.find(post => this.isCreateError(post));
  }

  getTitle() {
    if (this.isExpectedError()) {
      return "There some errors occurred while creating posts.";
    }
    return "Successfully Created Post!";
  }

  getSubTitle() {
    return "The following posts have been successfully created.";
  }

  getPartyLogo(post) {
    switch (post?.party) {
      case "dat":
        return "assets/img/logo_dat.png";
      case "truckstop":
        return "assets/img/truckstop_logo.png";
      case "truckerpath":
        return "assets/img/truckerpath_logo.png";
      default:
        return "";
    }
  }

  getPostStatus(post: ThirdPartyPost) {
    if (this.isCreateError(post)) {
      return 'Error';
    }
    return 'Live';
  }

  getPostStatusColor(post: ThirdPartyPost) {
    if (this.isCreateError(post)) {
      return 'red';
    }
    return 'green';
  }
}
