import { Component } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";

@Component({
    selector: '[third-party-info-dialog]',
    templateUrl: './dialog.html',
    styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class ThirdPartyInfoDialog extends BaseDialog {
    order: any;
    inProgress = false

    source: any = null
    onUpdate: any

    ngOnInit(): void {
        const { externalId } = this.order
        this.source = externalId ? externalId.split('_')[0] : null
        console.log(externalId, this.source)
    }
}