<div class="dashboard-child-container no-padding list">
  <div>
    <nz-row >
      <nz-col [nzMd]="24" [nzLg]="{span:18, offset:3}">
        <div class="list-container">
          <div class="list-header">
            <div class="flex">
              <button nz-button nzType="default" (click)="onBack()" class="right20" style="height:25px"
                nz-tooltip nzTooltipTitle="Back to bid detail"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button>
              <div class="title">Post List - Route <a *ngIf="job?.id" [routerLink]="[routeAdminDispatchList, job.id]" target="_blank">{{ routeCode }}</a></div>
            </div>
            <button nz-button nzType="primary" (click)="onBtnCreateNewPost()">Create Post</button>
          </div>

          <!-- <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div> -->
          <div class="list-body top20">
            <div nz-row *ngFor="let post of listPost; let i = index" class="post-item bottom20">
              <div nz-col [nzMd]="18" [nzXs]="24" class="post-item-info">
                <div *ngFor="let item of displayKeys">
                  <ng-container [ngSwitch]="item.key">
                    <ng-container *ngSwitchCase="'postTitle'">
                      <nz-row [nzGutter]="8" class="bottom30">
                        <nz-col nzFlex="150px" class="post-item-label">
                          <span class="post-item-title">Post {{ i+1 }}</span>
                          <div class="logo" style="margin-top: 4px;">
                            <img class="logo-dat" [src]="getPartyLogo(post)">
                          </div>
                        </nz-col>
                        <nz-col nzFlex="auto" class="flex" style="height: 21px;">
                          <div>
                            <div class="address">{{ displayInfos[i]?.pickupAddr }}</div>
                            <div class="time-info">{{ displayInfos[i]?.pickupTime }}</div>
                          </div>
                          <div class="dashed-line"></div>
                          <div>
                            <div class="address">{{ displayInfos[i]?.dropoffAddr }}</div>
                            <div class="time-info">{{ displayInfos[i]?.dropoffTime }}</div>
                          </div>
                        </nz-col>
                      </nz-row>
                    </ng-container>

                    <ng-container *ngSwitchCase="'comments'">
                      <nz-row [nzGutter]="8" class="top10">
                        <nz-col nzFlex="150px" class="post-item-label">{{ item.label }}</nz-col>
                        <nz-col nzFlex="auto">
                          <div *ngIf="displayInfos[i]?.comments">{{ displayInfos[i]?.comments }}</div>
                          <div *ngIf="getPostAdditionalComments(post)" class="top10">{{ getPostAdditionalComments(post) }}</div>
                        </nz-col>
                      </nz-row>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <nz-row [nzGutter]="8" class="top10">
                        <nz-col nzFlex="150px" class="post-item-label">{{ item.label }}</nz-col>
                        <nz-col nzFlex="auto">{{ displayInfos[i]?.[item.key] }}</nz-col>
                      </nz-row>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div nz-col [nzMd]="6" [nzXs]="24" class="actions-box">
                <div class="post-group-action">
                  <button nz-button nzDanger (click)="onBtnDeletePost(post)" nz-tooltip nzTooltipTitle="Delete post"><i nz-icon nzType="delete" nzTheme="outline"></i></button>
                  <button *ngIf="!isCreateError(post)" nz-button (click)="onBtnEditPost(post)" nz-tooltip nzTooltipTitle="Edit post"><i nz-icon nzType="edit" nzTheme="outline"></i></button>
                  <button *ngIf="!isCreateError(post)" nz-button (click)="onBtnRefreshPost(post, i)" [nzLoading]="isRefreshingPostId(post?.id)" nz-tooltip nzTooltipTitle="Refresh this post" style="padding: 0 16px;"><i nz-icon nzType="reload" nzTheme="outline"></i>Refresh</button>
                </div>
                <div class="update-info-box">
                  <div class="time-update-box">
                    <div>Refreshed</div>
                    <div>{{ getPostLatestRefreshTime(post) }}</div>
                  </div>
                  <div *ngIf="!isCreateError(post)" class="time-update-box">
                    <div>Created</div>
                    <div>{{ getPostCreatedTime(post) }}</div>
                  </div>
                  <div *ngIf="isCreateError(post)" class="time-update-box time-update-box-error">
                    <div nz-tooltip [nzTooltipTitle]="getErrorMessage(post)">Error <i nz-icon nzType="exclamation-circle" nzTheme="outline"></i></div>
                    <div>{{ getPostCreatedTime(post) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </div>
</div>
