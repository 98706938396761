import { ExcelUtil } from "@services/excel-util";
import * as XLSX from "xlsx";

export interface GopuffExcelLocationRow {
  row: number,
  clientLocId: string,
  clientLocName: string,
  street: string,
  city: string,
  state: string,
  zipcode: string,
  country: string,
}

export interface GopuffExcelLocationParsingResult {
  listWithId: GopuffExcelLocationRow[],
  listWithoutId: GopuffExcelLocationRow[],
  listDuplicatedId: GopuffExcelLocationRow[],
  listOutsideUs: GopuffExcelLocationRow[],
}


// Template [GoPuff _ WARP - Master Linehaul schedule .xlsx]
export function parseGopuff(sheet: XLSX.WorkSheet): GopuffExcelLocationParsingResult {
  const Cols = [
    { cell: 'A', key: 'clientLocName' },
    { cell: 'B', key: 'clientLocId' },
    { cell: 'C', key: 'street' },
    { cell: 'E', key: 'city' },
    { cell: 'F', key: 'state' },
    { cell: 'G', key: 'zipcode' },
    { cell: 'H', key: 'country' },
  ];
  const rowStart = 2;
  const rowEnd = ExcelUtil.getLastRow(sheet);
  const listWithoutId: GopuffExcelLocationRow[] = [];
  const listWithId: GopuffExcelLocationRow[] = [];
  const listDuplicatedId: GopuffExcelLocationRow[] = [];
  const listOutsideUs: GopuffExcelLocationRow[] = [];
  const existingClientLocId = {};
  for (let row = rowStart; row <= rowEnd; row++) {
    let item: GopuffExcelLocationRow = {
      row,
      clientLocId: '',
      clientLocName: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
    };
    for (let col of Cols) {
      let cellAddr = `${col.cell}${row}`;
      item[col.key] = ExcelUtil.getStringValueOfCell(sheet, cellAddr)?.trim();
    }
    if (!item.clientLocId && !item.clientLocName && !item.street) {
      // empty row => stop parsing
      break;
    }
    if (item.country != 'USA') {
      listOutsideUs.push(item);
      continue;
    }
    if (!item.clientLocId) {
      listWithoutId.push(item)
      continue;
    }
    if (existingClientLocId[item.clientLocId]) {
      listDuplicatedId.push(item);
      continue;
    }
    existingClientLocId[item.clientLocId] = 1;
    listWithId.push(item);
  }
  return { listWithId, listWithoutId, listDuplicatedId, listOutsideUs }
}