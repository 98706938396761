<form nz-form [formGroup]="formInput" class="form-shipment-location">
  <div style="display: flex;">
    <div *ngIf="'locationName' as key" class="flex1 right20" style="max-width: 300px;">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key" [nzAutocomplete]="autoComplete"
            (ngModelChange)="onFilterTextChange($event)">
          <nz-autocomplete #autoComplete [nzDefaultActiveFirstOption]="false">
            <cdk-virtual-scroll-viewport itemSize="32" minBufferPx="256" maxBufferPx="256" style="height: 256px;">
              <nz-auto-option *cdkVirtualFor="let item of locationsFiltered; let i = index" [nzValue]="item.name"
                (selectionChange)="onLocationSelected($event, item)">
                {{item.name}}
              </nz-auto-option>
            </cdk-virtual-scroll-viewport>
          </nz-autocomplete>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div *ngIf="'addr' as key" class="flex2 right20">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
      <nz-form-item>
        <nz-form-control>
          <form-address [formControlName]="key" [disableInput]="isDisableAddressInput" (ngModelChange)="addressChange($event)"></form-address>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div *ngIf="'requiresAppointment' as key" class="{{key}}">
      <div class="form-label-v2 single-line fix-height"></div>
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox [formControlName]="key" (ngModelChange)="requiresAppointmentChange($event)">{{getLabel(key)}}</label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div *ngIf="'appointmentInfo' as key" class="{{key}}" [ngClass]="{'active': isAppointment}">    
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <form nz-form [formGroupName]="key">
      <div class="flex">
        <nz-form-item>
          <nz-form-control>
            <nz-range-picker style="margin-right: 20px;" *ngIf="'range' as childKey"
              [formControlName]="childKey"
              [nzPlaceHolder]="getPlaceHolder(join(key, childKey))"
              [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15}"
              nzFormat="MM-dd-yyyy HH:mm">
            </nz-range-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </div>

  <div *ngIf="'windows' as key" class="{{key}}">
    <div *ngIf="shouldShowTrafficSuggestion"><span class="trafficSuggestion">Miles: <b>{{ (trafficInfo.distance / 1609.34).toFixed(2) || 'N/A'}}</b> mi - <b>{{ (trafficInfo.time / 3600).toFixed(2) || 'N/A'}}</b> hr - Suggest delivery window: <b>{{ displayDropWindowSuggestion }}</b></span></div>
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <ng-container [formArrayName]="key">
      <form *ngFor="let item of getFormArrayControls(key); let arrIndex = index" nz-form [formGroupName]="arrIndex" class="window" style="display: flex;">
        <nz-form-item>
          <nz-form-control>
            <nz-range-picker style="margin-right: 20px;" *ngIf="'range' as childKey"
              [formControlName]="childKey"
              [nzPlaceHolder]="getPlaceHolder(join(key, childKey))"
              [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
              nzFormat="MM-dd-yyyy HH:mm"
              (ngModelChange)="timeWindowChange(arrIndex)">
            </nz-range-picker>
            <a *ngIf="shouldShowButtonRemoveFormArray(key, arrIndex)" (click)="onBtnRemoveFormArray(key, arrIndex)" class="danger right10">
              <i nz-icon nzType="close-square" nzTheme="outline"></i> Remove window
            </a>
            <a *ngIf="shouldShowButtonAddFormArray(key, arrIndex)" (click)="onBtnAddFormArray(key)">Add more window</a>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-container>
  </div>

  <div *ngIf="'serviceOptions' as key" class="bottom20">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <div>
      <nz-select nzBackdrop="true" nzMode="multiple"  class="mw500" style="width: 100%;" nzShowSearch nzAllowClear
        nzPlaceHolder="Select service options" [nzMaxTagCount]="0" [nzMaxTagPlaceholder]="tagPlaceHolder"
        [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceOptions.selectedItems"
        (ngModelChange)="serviceOptionsSelectionChange()">
        <nz-option *ngFor="let item of serviceOptions.allItems" [nzLabel]="item.name" [nzValue]="item._id"></nz-option>
      </nz-select>
    </div>
    <ng-template #tagPlaceHolder>{{serviceOptions.sum()}}</ng-template>
    <nz-tag *ngFor="let item of serviceOptions.selectedItems; let i = index" style="margin-top: 5px;"
      [nzMode]="'closeable'" nzColor="processing" (nzOnClose)="serviceOptions.remove(i)">
      {{serviceOptions.name(item)}}
    </nz-tag>
  </div>

  <div *ngFor="let key of ['instructions', 'note']" class="bottom20">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <nz-form-item>
      <nz-form-control>
        <textarea nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" [nzAutosize]="{minRows: 2, maxRows: 4}"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div *ngIf="'refNums' as key" class="{{key}}">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <ng-container [formArrayName]="key">
      <div *ngFor="let f of getFormArrayControls(key); let arrIndex = index" class="refNum" style="display: flex;">
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="arrIndex" style="margin-right: 20px">
            <a *ngIf="shouldShowButtonRemoveFormArray(key, arrIndex)" (click)="onBtnRemoveFormArray(key, arrIndex)" class="danger right10">
              <i nz-icon nzType="close-square" nzTheme="outline"></i> Remove this reference number
            </a>
            <a *ngIf="shouldShowButtonAddFormArray(key, arrIndex)" (click)="onBtnAddFormArray(key)">Add more reference number</a>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>

  <div *ngIf="'accessCode' as key" class="mw500">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <nz-form-item>
      <nz-form-control>
        <input nz-input [formControlName]="key">
      </nz-form-control>
    </nz-form-item>
  </div>

  <nz-collapse *ngFor="let key of ['primaryContact', 'secondaryContact']" class="{{key}}" nzGhost nzExpandIconPosition="right">
    <nz-collapse-panel
      [nzHeader]="getLabel(key)"
      [nzShowArrow]="true"
      [nzActive]="true">
      <ng-template #extraTpl>
        <span nz-icon nzType="setting" (click)="$event.stopPropagation()"></span>
      </ng-template>
      <form nz-form [formGroupName]="key">
        <div class="flex">
          <div *ngFor="let childKey of ['fullName', 'phone', 'email']; let i = index;" class="flex1" [ngClass]="{'left20': i > 0}">
            <div class="form-label-v2">{{getLabel(join(key, childKey))}}<span *ngIf="isRequired(join(key, childKey))" mark-required></span></div>
            <ng-container [ngSwitch]="childKey">
              <div *ngSwitchCase="'phone'" [ngClass]="childKey">
                <nz-form-item>
                  <nz-form-control>
                    <nz-input-group nzCompact>
                      <input nz-input [formControlName]="childKey" style="width: 70%;">
                      <input nz-input [formControlName]="'phoneExtension'" [placeholder]="getPlaceHolder(join(key, 'phoneExtension'))" style="width: 30%;">
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngSwitchDefault [ngClass]="childKey">
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input [formControlName]="childKey">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </nz-collapse-panel>
  </nz-collapse>

</form>
