import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: '[short-contact-info]',
    templateUrl: './short_contact.html',
    styleUrls: ['../styles.scss']
})
export class ShortContactInfo extends Component {
    @Input() contact: any = null

    getPhoneExt(contact) {
        let phoneExtension = contact?.phoneExtension;
        return phoneExtension ? `Ext ${phoneExtension}` : ''
    }
}