import { Component } from "@angular/core";
import { BaseDetail } from '@base/detail';
import { ResponseAdminOpsRole } from '@wearewarp/types/rest-api/admin/user';
import { Const } from "@const/Const";
import { FormControl, Validators } from "@angular/forms";
import { RoleManager } from "@services/role-manager";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { ActivatedRoute } from "@angular/router";
import { Log } from "@app/services/log";
import { PaginationData } from "@app/model/PaginationData";
import { KeyLang } from '@app/locale';
import { MasterData } from "@services/master.data";
import { environment } from "@env/environment";

interface FetchList<T = any> {
  apiUrl: string,
  isLoading: boolean,
  list: T[]
};

function initFetchList<T = any>(apiUrl: string): FetchList<T> {
  return {isLoading: false, list: [], apiUrl};
}

@Component({
  selector: "[user-detail]",
  templateUrl: "./detail.html",
  styleUrls: ["../detail.scss", "../../app.scss", "./style.scss"],
})
export class UserDetailComponent extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    email: {
      label: "Email",
      required: true,
      validators: this.validateEmail.bind(this) /*Validators.email*/,
      inputType: "email",
    },
    pw: { label: "Password", type: "string", required: true },
    fullName: { label: "Full name", required: true },
    phone: {
      label: "Phone",
      inputType: "tel",
      getValue: InputHelper.getValuePhone,
      formatValue: InputHelper.formatPhone,
    },
    roleIds: { label: "Roles", type: "array", required: true },
    orgId: { label: 'Organization', placeHolder: "Select Organization" }
  };

  get routerUrlBack() {
    return this.routeAdminUserList;
  }

  hidePw = true;
  txtHintPw = "";
  passwordVisible = false;
  clientDeveloperKeyVisible = false;
  clientDeveloperApiKey: string = undefined;
  isClientUser: boolean = false;
  isApiKeyLoading: boolean = false;
  clientStagingKey: string = undefined;
  clientStagingKeyVisible: boolean = false;
  isStagingKeyLoading: boolean = false;
  activities: PaginationData = new PaginationData();
  listClients = [];
  listCarriers = [];
  // showClientHint = true;
  // isLoadClient = false;

  get avatarUrl(): string {
    return "assets/img/avatar2.png";
  }
  get isAvaDefault(): boolean {
    return this.avatarUrl == "assets/img/avatar2.png";
  }
  get shouldShowBtnEdit() {
    return super.shouldShowBtnEdit;
  }
  get shouldShowBtnAdd() {
    return super.shouldShowBtnAdd;
  }
  get shouldShowBtnSave() {
    return super.shouldShowBtnSave;
  }

  txtFullName = "";
  txtRole = "";
  txtPassword = "";
  txtNewAccount = "";
  get isLoadingWarehouse(): boolean { return this.dataFetch.warehouses.isLoading }
  get isLoadingOrganization(): boolean { return this.dataFetch.organizations.isLoading }
  get isLoadingDriver(): boolean { return this.dataFetch.drivers.isLoading }
  get allWarehouses() { return this.dataFetch.warehouses.list ?? [] }
  get organizations() { return this.dataFetch.organizations.list ?? [] }
  get allDrivers() { return this.dataFetch.drivers.list ?? [] }
  get allRoles(): ResponseAdminOpsRole[] { return this.dataFetch.roles.list ?? [] }

  private dataFetch: {[key: string]: FetchList} = {
    warehouses: initFetchList(`${Const.APIURI_WAREHOUSES}?filter=${encodeURIComponent(JSON.stringify({warehouseType: Const.WarehouseTypes.crossdock}))}&limit=-1`),
    organizations: initFetchList(Const.APIV2(`${Const.APIURI_ORGANIZATIONS}?limit=-1`)),
    drivers: initFetchList(Const.APIURI_DRIVERS_FOR_FILTER),
    roles: initFetchList(`${Const.APIURI_USERS}/get/ops_roles`)
  }

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  protected get crudEntity(): string {
    return "users";
  }

  ngOnInit() {
    super.ngOnInit();
    this.fetchData();
  }

  private fetchData() {
    let fetchByKey = (key: string) => {
      this.dataFetch[key].isLoading = true;
      this.api.GET(this.dataFetch[key].apiUrl).subscribe(
        resp => {
          this.dataFetch[key].list = resp.data.list_data;
          this.dataFetch[key].isLoading = false;
        }, err => {
          this.showErr(err);
          this.dataFetch[key].isLoading = false;
        }
      );
    }
    Object.keys(this.dataFetch).map(key => fetchByKey(key));
  }

  setupLanguage() {
    this.txtHintPw = this.text(KeyLang.Txt_PwHint, [Const.password_len_min, Const.password_len_max]);
    this.txtFullName = this.text(KeyLang.Txt_FullName);
    this.txtRole = this.text(KeyLang.Txt_Role);
    this.txtPassword = this.text(KeyLang.Txt_Password);
    this.txtNewAccount = this.text(KeyLang.Txt_NewAccount);
  }

  get isMyAccount() {
    return this.model && this.authUser && this.model._id == this.authUser._id;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected createFormInput(model = undefined) {
    if (this.isCreateNew) {
      this.formGroupDeclaration["pw"].required = true;
      this.formGroupDeclaration["pw"].validators = null;
      this.formGroupDeclaration["pw"].readOnly = false;
    } else {
      this.formGroupDeclaration["pw"].required = false;
      this.formGroupDeclaration["pw"].validators = null;
      this.formGroupDeclaration["pw"].readOnly = true;
    }
    super.createFormInput(model);
    if (model) {
      this.onRoleChange();
    }
  }

  protected beforeBindModel(model): any {
    if (!model.fullName) {
      model.fullName = this.getFullName(model);
    }
    model.roleIds = model.roles?.map((it) => it._id)?.sort((a, b) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
    if (model.client) {
      model.clientId = model.client.id;
      this.listClients = [model.client];
    }
    if (model.carrier) {
      model.carrierId = model.carrier.id;
      if (!Utils.isArrayNotEmpty(this.listCarriers)) {
        this.listCarriers = [model.carrier];
      }
    }
    if (model.driver && !this.dataFetch.drivers.list.length) {
      this.dataFetch.drivers.list = [model.driver]
    }
    if (model.warehouses && !this.dataFetch.warehouses.list.length) {
      this.dataFetch.warehouses.list = model.warehouses;
    }
    if (model.clientStagingKey) this.clientStagingKey = model.clientStagingKey;
    else this.clientStagingKey = '';
    return model;
  }

  protected getApiUrl(): string {
    return Const.APIURI_USERS;
  }

  protected canDelete(model: any): boolean {
    return true;
  }

  protected bindDataModel(model) {
    super.bindDataModel(model);
    this.onRoleChange();
  }

  protected setEnableFormGroup(enable: boolean) {
    super.setEnableFormGroup(enable);
    if (enable && !RoleManager.canAccessUsers(this.authUser)) {
      this.formInput.get("roleIds").disable();
    }
  }

  protected getPageTitle(model): string {
    return model.fullName || model.email;
  }

  protected getMsgConfirmDelete(): string {
    return "Delete account " + (this.model.fullName || this.model.email) + "?";
  }

  protected onCreateSuccess(resp) {
    super.onCreateSuccess(resp);
  }

  protected onUpdateSuccess(resp) {
    Log.d("onUpdateSuccess resp: ", resp);
    super.onUpdateSuccess(resp);
    if (this.isMe(this.model)) {
      this.appComponent.myProfileChanged();
    }
  }

  private _updateData() {
    super.updateData();
  }

  protected updateData() {
    const driverId = this.formInput?.get("driverId")?.value;
    if (driverId) {
      const driver = this.allDrivers.filter(it => it.id == driverId)[0];
      const phone = this.getItemValue('phone');
      if (phone && phone != driver.phone) {
        this.confirmYesNo(`Changing user's phone number will unlink driver <b>${this.getDriverDesc(driver)}</b>.<br/> Are you sure you want to change?`, () => this._updateData());
        return;
      }
    }
    this._updateData();
  }

  private hasRole(roleIds: string[], id: string) {
    return Utils.isArrayNotEmpty(roleIds) && roleIds.includes(id);
  }

  validateEmail(input: FormControl): any {
    if (input.value === null || input.value === undefined) {
      return { required: true };
    }
    let str = (input.value || '').trim();
    if (str == "sysadmin" || str == "root") {
      // 1 số trường account đặc biệt ko cần validate email
      if (this.model && this.model.email == str) {
        return null;
      }
    }
    // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    return Validators.email(input);
  }

  private handleRoleChangeForKey(key: string, hasRole: boolean, declaration: FormControlDeclaration) {
    if (hasRole) {
      if (!this.formGroupDeclaration[key]) {
        let bindData;
        if (this.model) {
          bindData = this.model[key];
        }
        this.addItemToFormGroup(key, declaration, bindData);
      }
    } else {
      if (this.formGroupDeclaration[key]) {
        this.removeFormGroupItem(key);
      }
    }
  }

  onRoleChange() {
    let roleIds = this.formInput.get("roleIds").value;
    this.handleRoleChangeForKey(
      'clientId',
      this.hasRole(roleIds, RoleManager.clientId) || this.hasRole(roleIds, RoleManager.customerReadOnlyId),
      { label: "Customer", placeHolder: "Select customer", required: true, type: "string" }
    );
    if(this.isCreateNew){
      this.handleRoleChangeForKey(
        'isSendClientEmail',
        this.hasRole(roleIds, RoleManager.clientId) || this.hasRole(roleIds, RoleManager.customerReadOnlyId),
        { label: "Send new customer email", placeHolder: "", type: "boolean" }
      );
    }
    this.handleRoleChangeForKey(
      'carrierId',
      this.hasRole(roleIds, RoleManager.carrierId),
      { label: "Carrier", placeHolder: "Select carrier", required: true, type: "string" },
    );
    this.handleRoleChangeForKey(
      'driverId',
      this.hasRole(roleIds, RoleManager.driverId),
      { label: "Driver", placeHolder: "Select driver", required: true, type: "string" },
    );
    this.handleRoleChangeForKey(
      'warehouseIds',
      this.hasRole(roleIds, RoleManager.warehouseAdminId) || this.hasRole(roleIds, RoleManager.warehouseOperatorId),
      { label: "Cross Dock Warehouse", placeHolder: "Select Warehouse", required: true, type: "string" },
    );
  }

  getWarehouseName(warehouse) {
    return warehouse?.name;
  }

  getDriverDesc(driver) {
    return this.getDriverNameWithPhone(driver);
  }

  generateDeveloperApiKeyForClient() {
    this.startProgress();
    this.isApiKeyLoading = true;
    this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.client.id}/create_apikey`,{userEmail:this.model.email}).subscribe(
      (resp) => {
        this.clientDeveloperKeyVisible = false;
        this.model.developerApiKey = resp.data.token;
        this.clientDeveloperApiKey = this.getShowApiKeyText(this.model.developerApiKey, this.clientDeveloperKeyVisible);
        this.stopProgress();
        this.isApiKeyLoading = false;
        this.showSuccess("New API key generated.");
      },
      (err) => {
        this.showErr(err);
        this.isApiKeyLoading = false;
        this.stopProgress();
      }
    );
  }

  protected onGetDetailSuccess(data) {
    this.isClientUser = this.hasRole(data.roles?.map((it) => it._id) || [], RoleManager.clientId);
    this.clientDeveloperApiKey = this.getShowApiKeyText(data.developerApiKey, this.clientDeveloperKeyVisible);
    return data;
  }

  private getShowApiKeyText(apiKey: string, visible: boolean) {
    if (visible) return apiKey;
    else if (apiKey) return "********************************" + apiKey.slice(-8);
  }

  deleteDeveloperApiKey() {
    this.confirmDeletion({
      message: "Deleting the key will prevent the client from calling our API Gateway. Are you sure you want to delete?",
      fnOk: () => {
        this.startProgress();
        this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.client._id}/delete_apikey`).subscribe(
          (resp) => {
            this.clientDeveloperApiKey = undefined;
            this.model.developerApiKey = undefined;
            this.clientDeveloperKeyVisible = false;
            this.stopProgress();
            this.showSuccess("API key deleted.");
          },
          (err) => {
            this.showErr(err);
            this.stopProgress();
          }
        );
      },
    });
  }

  regenerateDeveloperApiKey() {
    this.confirmYesNo("Generating a new key will make the existing API key no longer available. Do you want to generate a new key?", () => {
      this.startProgress();
        this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.client.id}/regenerate_apikey`,{userEmail:this.model.email}).subscribe(
          (resp) => {
            this.clientDeveloperKeyVisible = false;
            this.model.developerApiKey = resp.data.token;
            this.clientDeveloperApiKey = this.getShowApiKeyText(this.model.developerApiKey, this.clientDeveloperKeyVisible);
            this.stopProgress();
            this.showSuccess("New API key generated.");
          },
          (err) => {
            this.showErr(err);
            this.stopProgress();
          }
        );
    });
  }

  copyDeveloperApiKey() {
    Utils.copyTextToClipboard(this.model.developerApiKey, (e) => {
      if (e) {
        this.showErr("Cannot copy API Key to clipboard");
      } else {
        this.showSuccess("API Key has already been copied to the clipboard");
      }
    });
  }
  get pwPolicy() { return MasterData.pwPolicyText }

  async generateClientStagingKey() {
    this.startProgress();
    this.isStagingKeyLoading = true;
    let url = `${environment.backendUrl}/${Const.APIV2(`${Const.APIURI_USERS}/${this.model.id}/generate-client-staging-key`)}`;

    this.api.POST(url).subscribe(
      (resp) => {
        this.stopProgress();
        this.clientStagingKey = resp?.data?.token;
        this.isStagingKeyLoading = false;
        this.showSuccess('Create staging key is successful');
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isStagingKeyLoading = false;
      }
    )
  }

  async copyClientStagingKey() {
    Utils.copyTextToClipboard(this.clientStagingKey, (e) => {
      if (e) {
        this.showErr("Cannot copy Client API Key to clipboard");
      } else {
        this.showSuccess("Client API Key has already been copied to the clipboard");
      }
    });
  }

  async regenerateClientStagingKey() {
    this.startProgress();
    this.isStagingKeyLoading = true;
    let url = `${environment.backendUrl}/${Const.APIV2(`${Const.APIURI_USERS}/${this.model.id}/regenerate-client-staging-key`)}`;
    this.api.POST(url).subscribe(
      (resp) => {
        this.stopProgress();
        this.clientStagingKey = resp?.data?.token;
        this.isStagingKeyLoading = false;
        this.showSuccess('Refresh staging key is successful');
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isStagingKeyLoading = false;
      }
    )
  }

  async deleteClientStagingKey() {
    this.startProgress();
    this.isStagingKeyLoading = true;
    let url = `${environment.backendUrl}/${Const.APIV2(`${Const.APIURI_USERS}/${this.model.id}/delete-client-staging-key`)}`;
    this.api.POST(url).subscribe(
      (resp) => {
        this.stopProgress();
        this.clientStagingKey = resp?.data?.token || '';
        this.isStagingKeyLoading = false;
        this.showSuccess('Delete staging key is successful');
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isStagingKeyLoading = false;
      }
    )
  }

  displayClientStagingKey() {
    return this.getShowApiKeyText(this.clientStagingKey, this.clientStagingKeyVisible);
  }

  onChangeClientStagingKeyVisiable() {
    this.clientStagingKeyVisible = !this.clientStagingKeyVisible;
  }

  getVisiableClientStagingKeyToolTip() {
    return this.clientStagingKeyVisible ? "Hide key" : "Show key";
  }

  isSendClientEmailCheckBox(key: string){
    return key == 'isSendClientEmail'
  }
}
