<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form nz-form *ngIf="!isLoading && formInput" [formGroup]="formInput" class="form-detail" nzLayout="vertical">
  <!--  Shipment Detail-->
  <nz-row>
    <nz-col [nzMd]="24">
      <div class="form-container">
        <div class="flex-space-between form-title">
          <div class="flex">
            <button *ngIf="!isEditing || !isInputsChange()" nz-button (click)="onBtnComeBack()" nz-tooltip nzTooltipTitle="Back to list page" class="right20">
              <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </button>
            <button *ngIf="isEditing && isInputsChange()" nz-button (nzOnConfirm)="onBtnComeBack()"
              nz-popconfirm nzPopconfirmPlacement="top" nzOkText="Yes" nzCancelText="No"
              nzPopconfirmTitle="Come back without saving?"
              nz-tooltip nzTooltipTitle="Back to list page" class="right20">
              <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </button>
            <h3 *ngIf="!editId" nz-typography>Create Post</h3>
            <div *ngIf="editId">
              <img class="third-party-header" [src]="getPartyLogo()" alt="pt-logo"/>
            </div>
          </div>
          <div *ngIf="!!editId"  style="text-align: right;">
            <button nz-button nzType="primary" [nzLoading]="onProgress" [disabled]="(isEditing && !isShowDialog) || isClosed" (click)="onBtnRefresh()"
              nz-tooltip nzTooltipTitle="Bring the post to the top of Dashboard">
              <i nz-icon nzType="retweet" nzTheme="outline"></i>
              Refresh
            </button>
            <div class="text-note" *ngIf="model?.refresh">Last refresh: {{displayDateTime(model.refresh.when)}}</div>
          </div>
        </div>

        <div class="box-with-title">
          <div class="box-content">
            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="12">
                <!-- Origin -->
                <div class="group-items">
                  <div class="label-title">Origin</div>
                  <div *ngFor="let key of ['origin']" class="form-item">
                    <div class="single-line">{{ getLabel(key) }} <span *ngIf="isRequired('origin')"
                        class="label-mark-required"></span></div>
                    <input #powerLaneInputFrom (input)="onInputChanged($event, key)" [nzAutocomplete]="autoComplete1"
                      [placeholder]="'Enter to search'" [formControlName]="key" id="{{key}}-origin" nz-input>
                    <nz-autocomplete #autoComplete1>
                      <nz-auto-option *ngFor="let item of listAddressAutoComplete[key + '-origin']; let i = index"
                        [nzValue]="item">
                        {{ item }}
                      </nz-auto-option>
                    </nz-autocomplete>
                  </div>
                  <div class="tz-data">
                    *Timezone: {{ getTextTimezone(data?.pickDt?.timezone) }}
                  </div>
                  <ng-container *ngTemplateOutlet="tplFormDatetimeItem; context: {keys: ['pickupFromDate', 'pickupFromTime']}"></ng-container>
                  <ng-container *ngTemplateOutlet="tplFormDatetimeItem; context: {keys: ['pickupToDate', 'pickupToTime']}"></ng-container>
                </div>

                <!-- Stop on Truckstop -->
                <div *ngIf="data.stops?.length > 2" class="group-items">
                  <div>
                    <label nz-checkbox [formControlName]="'showStop'">
                      <span class="f13">Show stop in between on</span>
                      <img class="logo-truckstop-small" src="assets/img/truckstop_logo.png">
                    </label>
                  </div>
                  <ul>
                    <li *ngFor="let stop of data.stops | slice:1:-1; let i = index" class="stop-item">
                      <div class="stop-address">{{ getStopAddress(stop) }}</div>
                      <div class="stop-time">{{ getStopTime(stop) }}</div>
                    </li>
                  </ul>
                </div>

                <!-- Destination -->
                <div class="group-items">
                  <div  *ngFor="let key of ['destination']" class="form-item">
                    <div class="label-title">Destination</div>
                    <div class="single-line">{{ getLabel(key) }} <span *ngIf="isRequired('origin')"
                        class="label-mark-required"></span></div>
                    <input #powerLaneInputTo (input)="onInputChanged($event, key)" [nzAutocomplete]="autoComplete2"
                      [placeholder]="'Enter to search'" [formControlName]="key" id="{{key}}-destination" nz-input>
                    <nz-autocomplete #autoComplete2>
                      <nz-auto-option *ngFor="let item of listAddressAutoComplete[key + '-destination']; let i = index"
                        [nzValue]="item">
                        {{ item }}
                      </nz-auto-option>
                    </nz-autocomplete>
                  </div>
                  <div class="tz-data">
                    *Timezone: {{ getTextTimezone(data?.dropDt?.timezone) }}
                  </div>

                  <ng-container *ngTemplateOutlet="tplFormDatetimeItem; context: {keys: ['dropFromDate', 'dropFromTime']}"></ng-container>
                  <ng-container *ngTemplateOutlet="tplFormDatetimeItem; context: {keys: ['dropToDate', 'dropToTime']}"></ng-container>
                </div>
              </nz-col>

              <nz-col [nzSpan]="12">
                <!-- Equipments -->
                <div class="group-items">
                  <div class="label-title">Equipment & Load Details</div>
                  <!-- load type -->
                  <div *ngFor="let key of ['loadType']" class="form-item">
                    <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                    </div>
                    <nz-radio-group [formControlName]="key">
                      <label nz-radio nzValue="FTL">FTL</label>
                      <label nz-radio nzValue="LTL">LTL</label>
                      <label nz-radio nzValue="Parcel">Parcel</label>
                    </nz-radio-group>
                  </div>

                  <!-- vehicle type -->
                  <div *ngFor="let key of ['equipmentType']" class="form-item">
                    <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                    </div>
                    <nz-select [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" nzAllowClear
                      nzBackdrop="true" style="width: 100%;" [nzOptions]="equipmentType" (ngModelChange)="onVehicleChange($event)">
                    </nz-select>
                  </div>

                  <!-- vehicle options -->
                  <div *ngFor="let key of ['equipmentOptions']" class="form-item">
                    <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                        class="label-mark-required"></span>
                      </div>
                    <nz-checkbox-group [formControlName]="key"></nz-checkbox-group>
                  </div>

                  <!-- length, width, height -->
                  <nz-row [nzGutter]="8">
                    <nz-col [nzSpan]="8" *ngFor="let key of ['lengthFeet', 'widthFeet', 'heightFeet']">
                      <div class="form-item" style="min-width: 120px;">
                        <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                          class="label-mark-required"></span>
                        </div>
                        <nz-input-group nzSize="default">
                          <input nz-input type="number" [formControlName]="key" [placeholder]="getPlaceHolder(key)" />
                        </nz-input-group>
                      </div>
                    </nz-col>
                  </nz-row>

                  <!-- weight -->
                  <div *ngFor="let key of ['weightPounds']" class="form-item" style="min-width: 120px;">
                    <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                        class="label-mark-required"></span>
                    </div>
                    <nz-input-group nzSize="default">
                      <input nz-input type="number" [formControlName]="key" placeholder="Weight Ibs" nzSize="default" />
                    </nz-input-group>
                  </div>
                  <!-- commodity -->
                  <div *ngFor="let key of ['commodity']" class="form-item" style="min-width: 200px;">
                    <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                        class="label-mark-required"></span>
                    </div>
                    <textarea nz-input [rows]="2" [formControlName]="key" placeholder="Commodity"></textarea>
                  </div>
                </div>

                <!-- Contacts -->
                <div class="group-items">
                  <div class="label-title">Contacts</div>
                  <div *ngFor="let key of ['defaultComment', 'additionalComment']" class="form-item">
                    <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                        class="label-mark-required"></span>
                    </div>
                    <textarea nz-input [rows]="3" [formControlName]="key" placeholder="Comments are limited to 70 characters"></textarea>
                  </div>
                </div>

                <!-- Required tracking -->
                <div>
                  <div class="label-title">Required Tracking</div>
                  <div *ngFor="let key of ['isTrackingRequired']" class="form-item">
                    <label nz-checkbox [formControlName]="key"><span class="f13">Let carriers know tracking is required for this shipment</span></label>
                  </div>
                </div>
              </nz-col>
            </nz-row>
          </div>
        </div>

        <!--  Footer buttons -->
        <div class="form-footer bottom10">
          <div *ngIf="!isAdminReadOnlyRole" detail-footer-buttons labelButtonCreate="Post"
            [isCreateNew]="isCreateNew" [isEditing]="isEditing" [onProgress]="onProgress" [needUpdate]="needUpdate"
            (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()" (onBack)="onBtnComeBack()"
            [shouldDisableBtnEdit]="isClosed" [shouldShowBtnComeBack]="isCreateNew" labelButtonComeBack = 'Cancel'
            >
          </div>

          <div nz-row *ngIf="!isAdminReadOnlyRole && isCreateNew" class="box-platforms">
            <div nz-col nzFlex="80px">Post to:</div>
            <div nz-col nzFlex="auto">
              <nz-checkbox-wrapper (nzOnChange)="onPartyChange($event)" class="flex">
                <div *ngFor="let item of availableParties" class="right10">
                  <label nz-checkbox [nzValue]="item.value" [nzChecked]="getPartyValue(item.value)">
                    <span>
                      <img class="third-party-logo" [src]="item.logo" title="logo" />
                    </span>
                  </label>
                </div>
              </nz-checkbox-wrapper>
            </div>

            <!-- <div class="flex" style="margin-left: 20px;">
              <div *ngFor="let key of ['shouldPostToDat']">
                <label nz-checkbox [formControlName]="key">
                  <span><img class="logo-dat" src="assets/img/logo_dat.png"></span>
                </label>
              </div>
              <div *ngFor="let key of ['shouldPostToTruckstop']" class="left10">
                <label nz-checkbox [formControlName]="key">
                  <span><img class="logo-truckstop" src="assets/img/logo_truckstop_v1.png"></span>
                </label>
              </div>
            </div> -->
          </div>
        </div>

        <div style="text-align: right;">
          <div class="text-note" *ngIf="model?.insert">{{isAutoPost ? 'Auto created' : 'Created'}} at: {{displayDateTime(model.insert.when)}}</div>
          <div class="text-note" *ngIf="model?.update?.when !== model?.insert?.when">Updated at: {{displayDateTime(model.update.when)}}</div>
          <div class="text-note" *ngIf="model?.close">Closed at: {{displayDateTime(model.close.when)}}</div>
          <div class="text-note" *ngIf="model?.close">**Closed posts must be deleted before being re-created!**</div>
          <div class="text-error" *ngIf="model?.error">{{model.error.event || 'Error'}} at: {{displayDateTime(model.error.when)}}</div>
        </div>

      </div>
    </nz-col>
  </nz-row>

  <ng-template #tplFormDatetimeItem let-keys="keys">
    <!-- keys: [dateKey, timeKey] -->
    <div>
      <div class="form-item">
        <div class="form-label-v2">{{ getLabel(keys[0]) }} <span *ngIf="isRequired(keys[0])"
          class="label-mark-required"></span></div>
        <nz-date-picker [nzPlaceHolder]="getPlaceHolder(keys[0])" [formControlName]="keys[0]"
          nzFormat="M/dd/yyyy - H:mm" [nzShowTime]="{ nzFormat: 'HH:mm' }" class="full-w" [nzShowNow]="false"></nz-date-picker>
      </div>
      <!-- <nz-row>
        <nz-col [nzSpan]="12">
          <div class="form-item">
            <div class="form-label-v2">{{ getLabel(keys[0]) }} <span *ngIf="isRequired(keys[0])"
              class="label-mark-required"></span></div>
            <nz-date-picker [nzPlaceHolder]="getPlaceHolder(keys[0])" [formControlName]="keys[0]"
              [nzFormat]="'M/dd/yyyy'" class="full-w"></nz-date-picker>
          </div>
        </nz-col>
        <nz-col [nzSpan]="12">
          <div class="form-item">
            <div class="form-label-v2">{{ getLabel(keys[1]) }} <span *ngIf="isRequired(keys[0])"
              class="label-mark-required"></span></div>
            <nz-time-picker [nzPlaceHolder]="getPlaceHolder(keys[1])" [formControlName]="keys[0]"
              nzFormat="HH:mm" class="full-w"></nz-time-picker>
          </div>
        </nz-col>
      </nz-row> -->
    </div>
  </ng-template>
</form>

