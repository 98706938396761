import {
  FormDataShipmentItem,
  FormDataShipmentEntryBasicInfo,
  FormDataShipmentLocation,
  FormDataShipmentEntryDraftData,
  FormDataShipmentEntryDraftSingle,
  FormDataShipmentEntryDraftBatch,
  FormDataBatchShipmentRevenueArray,
  FormDataSingleShipmentRevenue,
} from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { ShipmentEntryFormId } from "../enum";
import {
  FormDataBatchLocations,
  FormDataBatchLocationsMultiDrop,
  FormDataBatchLocationsMultiPick,
  FormDataBatchLocationsMultiPickDrop,
  FormDataOrderItems,
} from "../interface";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { MasterData } from "@services/master.data";

export class ShipmentEntryBuilder {
  private draftData: FormDataShipmentEntryDraftData;

  constructor(draftData: FormDataShipmentEntryDraftData) {
    this.draftData = draftData;
  }

  setDraftData(data: FormDataShipmentEntryDraftData) {
    this.draftData = data;
  }

  private generateIdIfNeeded(item) {
    if (!item.id) {
      item.id = Utils.generateULID();
    }
  }

  private buildDraftSingle(formData: any, formId: ShipmentEntryFormId): FormDataShipmentEntryDraftSingle {
    let draft: FormDataShipmentEntryDraftSingle = Object.assign({}, this.draftData ?? {});
    switch (formId) {
      case ShipmentEntryFormId.singleBasicInfo:
        draft.basicInfo = <FormDataShipmentEntryBasicInfo>formData;
        break;
      case ShipmentEntryFormId.singlePickup:
        draft.pickInfo = <FormDataShipmentLocation>formData;
        this.generateIdIfNeeded(draft.pickInfo);
        break;
      case ShipmentEntryFormId.singleDropoff:
        draft.dropInfo = <FormDataShipmentLocation>formData;
        this.generateIdIfNeeded(draft.dropInfo);
        break;
      case ShipmentEntryFormId.singleItems:
        draft.items = (<FormDataOrderItems>formData).items;
        this.generateIdForOrderItem(draft.items);
        break;
      case ShipmentEntryFormId.singleRevenue:
        let _formData = <FormDataSingleShipmentRevenue>formData;
        draft.cost = _formData.cost;
        if (Utils.isBoolean(_formData.settings?.isDoNotInvoice)) {
          if (!Utils.isObject(draft.settings)) draft.settings = {};
          draft.settings.isDoNotInvoice = _formData.settings.isDoNotInvoice;
          draft.settings.reasonDoNotInvoice = _formData.settings.reasonDoNotInvoice;
        }
        draft = this.updateServiceOption_FromSingleRevenue(draft);
        break;
    }
    draft.shipmentEntryMode = Const.ShipmentEntryMode.single;
    return draft;
  }

  private buildDraftBatch(formData: any, formId: ShipmentEntryFormId): FormDataShipmentEntryDraftBatch {
    let batch: FormDataShipmentEntryDraftBatch = Object.assign({}, this.draftData ?? {});
    switch (formId) {
      case ShipmentEntryFormId.batchBasicInfo:
        batch.basicInfo = <FormDataShipmentEntryBasicInfo>formData;
        break
      case ShipmentEntryFormId.batchLocations:
        let _formData = <FormDataBatchLocations>formData;
        batch.shipmentEntryMode = _formData.batchType;
        this.buildDraftBatchLocations(batch, _formData.data);
        break;
      case ShipmentEntryFormId.batchRevenue: {
        let _formData = <FormDataBatchShipmentRevenueArray>formData;
        if (Utils.isBoolean(_formData.settings?.isDoNotInvoice)) {
          if (!Utils.isObject(batch.settings)) batch.settings = {};
          batch.settings.isDoNotInvoice = _formData.settings.isDoNotInvoice;
          batch.settings.reasonDoNotInvoice = _formData.settings.reasonDoNotInvoice;
        }
        batch.revenueInfos = _formData.revenueInfos;
        break;
      }
    }
    return batch;
  }

  private buildDraftBatchLocations(draft: FormDataShipmentEntryDraftBatch , formData: FormDataBatchLocationsMultiPick | FormDataBatchLocationsMultiDrop | FormDataBatchLocationsMultiPickDrop) {
    switch (draft.shipmentEntryMode) {
      case Const.ShipmentEntryMode.multiPick:
        draft.dropInfo = (<FormDataBatchLocationsMultiPick>formData).dropInfo;
        draft.pickInfos = (<FormDataBatchLocationsMultiPick>formData).pickInfos;
        break;
      case Const.ShipmentEntryMode.multiDrop:
        draft.pickInfo = (<FormDataBatchLocationsMultiDrop>formData).pickInfo;
        draft.dropInfos = (<FormDataBatchLocationsMultiDrop>formData).dropInfos;
        break;
      case Const.ShipmentEntryMode.multiPickDrop:
        draft.shipments = (<FormDataBatchLocationsMultiPickDrop>formData).shipments;
        break;
    }
  }

  public buildDraftData(formData: any, formId: string): FormDataShipmentEntryDraftData {
    let _formId = <ShipmentEntryFormId>formId;
    switch (_formId) {
      case ShipmentEntryFormId.singleBasicInfo:
      case ShipmentEntryFormId.singlePickup:
      case ShipmentEntryFormId.singleDropoff:
      case ShipmentEntryFormId.singleItems:
      case ShipmentEntryFormId.singleRevenue:
        return this.buildDraftSingle(formData, _formId);
      case ShipmentEntryFormId.batchBasicInfo:
      case ShipmentEntryFormId.batchLocations:
      case ShipmentEntryFormId.batchRevenue:
        return this.buildDraftBatch(formData, _formId);
      default:
        throw Error(`Unknown formId ${formId}`);
    }
  }

  private generateIdForOrderItem(items: Array<FormDataShipmentItem>) {
    if (!items?.length) {
      return;
    }
    for (let index = 0; index < items.length; index++) {
      const item: FormDataShipmentItem = items[index];
      this.generateIdIfNeeded(item);
      if (item?.children?.length) {
        this.generateIdForOrderItem(item.children);
      }
    }
  }

  // Update serviceOption ở delivery khi thay đổi serviceOption ở cost
  private updateServiceOption_FromSingleRevenue(draftData: FormDataShipmentEntryDraftSingle): FormDataShipmentEntryDraftSingle {
    const serviceOptionsCost = draftData.cost?.serviceOptions || [];
    let pickInfo = draftData.pickInfo;
    let dropInfo = draftData.dropInfo;
    if (!pickInfo || !dropInfo) return draftData;
    let pickArr = [];
    let dropArr = [];
    for (let item of serviceOptionsCost) {
      let option = MasterData.getServiceOptionById(item._id);
      if (!option) continue;
      if (option.type == 'pickup') {
        pickArr.push(option._id);
      } else if (option.type == 'delivery') {
        dropArr.push(option._id);
      }
    }
    pickInfo.serviceOptions = pickArr;
    dropInfo.serviceOptions = dropArr;
    return draftData;
  }

}