<div class="outer-container">
    <div class="header">Shipment {{shipment.code}} ({{shipment.warpId}}) Quote</div>
    <ng-container *ngIf="selectingProviders">
        <div class="selecting-provider-form flex">
            <div class="flex1">
                Providers:
                <label *ngFor="let opt of providers" nz-checkbox [(ngModel)]="opt.selected" >{{ opt.name }}</label>
                
            </div>
            <div>
                <button nz-button (click)="confirmProviders()">Quote</button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!selectingProviders">
        <div class="flex providers-info">
            <div class="flex1">
                Providers:
                <ng-container *ngIf="isAll">
                    <code *ngFor="let opt of providers">{{ opt.name }}</code>
                </ng-container>
                <ng-container *ngIf="!isAll">
                    <ng-container *ngFor="let opt of providers">
                        <code *ngIf="opt.selected">{{ opt.name }}</code>
                    </ng-container>
                </ng-container>
            </div>
            <div>
                <button [disabled]="quoting" nz-button (click)="selectProviders()">Change</button>
            </div>
        </div>
    </ng-container>
    <div class="result-container">
        <div *ngIf="quoting" class="loading-container">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
        <div *ngIf="quotingResponse" [onBookComplete]="onBookComplete" [response]="quotingResponse" [shipment]="shipment" freight-quote-result></div>
    </div>
</div>