import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Log } from "@services/log";
import to from "await-to-js";
import { NzOptionSelectionChange } from "ng-zorro-antd/auto-complete";

// Dùng cho việc filter location trên form nhập pickup/delivery info.
// Chỉ áp dụng với nz-input và nz-autocomplete.
export class LocationFilterHeper {
  private cbLocationSelect: (data, err) => void
  private _isLoading = false;
  private _locations: any[] = [];
  private _locationsFiltered: any[] = [];

  public get locationsFiltered() { return this._locationsFiltered }
  public get isLoading() { return this._isLoading }

  constructor(cbLocationSelect: (data, err) => void) {
    this.cbLocationSelect = cbLocationSelect;
    this.getLocations();
  }

  onFilterTextChange(text: string) {
    this._locationsFiltered = this._locations.filter(location => location.name.toLowerCase().startsWith(text.toString().toLowerCase()))
  }

  async onLocationSelect(event: NzOptionSelectionChange, data) {
    if (!event.isUserInput) {
      return;
    }
    const [err, res] = await to(this.getLocationDetail(data.id));
    this.cbLocationSelect(res?.data, err);
  }

  private getLocations() {
    this._isLoading = true;
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter`;
    ApiService.instance.GET(url).subscribe(
      resp => {
        this._locations = resp.data.list_data;
        this._locationsFiltered = this._locations.slice(0);
        this._isLoading = false;
      }, err => {
        Log.e(err);
        this._isLoading = false;
      }
    );
  }
  private async getLocationDetail(id: string) {
    let url = `${Const.APIURI_WAREHOUSES}/${id}`;
    return await ApiService.instance.GET(url).toPromise();
  }
}
