<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="!isLoading" style="margin-bottom: 50px;">
    <div class="flex" style="justify-content: flex-end;">
        <nz-radio-group  nzButtonStyle="solid" [(ngModel)]="viewModeValue">
            <label nz-radio-button nzValue="number">#</label>
            <label nz-radio-button nzValue="percentage">%</label>
        </nz-radio-group>
    </div>
    <div nz-row [nzGutter]="16">
        <div *ngFor="let item of arrSendTypes" nz-col nzXs="24" nzLg="12" >
            <div class="box" >
                <div class="header">
                    <div>
                        <span *ngIf="item.key =='email'" nz-icon nzType="mail" nzTheme="outline" style="font-size: 19px;"></span>
                        <img *ngIf="item.key =='sms'" src="/assets/img/sms.svg"/>
                    </div>
                    <div>
                        {{ item.value }}
                    </div>
                    <div class="percentage" *ngIf="displayInfo.percentage?.[item.key]">{{ displayInfo.percentage[item.key] }}%</div>
                </div>
                <div class="body">
                    <div *ngFor="let key of arrResponseTypes">
                        <div class="bottom5">{{ getLabelResponseType(key) }}</div>
                        <div><b>{{ displayInfo.viewMode?.[viewModeValue]?.[item.key]?.[key] }}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="container" class="bottom20"></div>
<div *ngIf="!isLoading && !dataChart?.length" class="f16 top15 bottom10">
    No carriers have been added or bids have not been sent yet.
</div>

