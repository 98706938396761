<span class="clickable" (click)="showDialog()">
    <span *ngIf="source === 'PRIORITY1'" style="display: inline-block; font-weight: 500;">
        priority<img src="assets/img/priority1.png" style="max-width: 24px; max-height: 16px" />
    </span>
    <span *ngIf="source === 'EXFREIGHT'">
        <img src="assets/img/exfreight.png" style="max-width: 24px; max-height: 16px" />
    </span>
    <span *ngIf="source === 'ORDERFUL'">
        <img src="assets/img/orderful-logo.png" style="max-width: 24px; max-height: 16px" />
    </span>
    <span *ngIf="source === 'WWEX'" style="display: inline-block; font-weight: 500;">
        <img src="assets/svg/logo-wwex.svg" style="max-width: 80px; height: 22px" />
    </span>
</span>
