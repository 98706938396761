import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: '[short-location-info]',
    templateUrl: './short_location.html',
    styleUrls: ['../styles.scss']
})
export class ShortLocationInfo extends BaseComponent {
    @Input() info: any = null

    saveLocation(addr, name) {
        if (addr) {
            let saveData = {locationName: name, filter: {
                street: addr.street, 
                city: addr.city, 
                state: addr.state, 
                zipcode: addr.zipcode}
            }
            localStorage.setItem('openDetail', JSON.stringify(saveData));
            event?.stopPropagation();
        }
    }
}