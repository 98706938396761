import { Component, Input, QueryList, ViewChildren } from "@angular/core";
import { FormDataBatchShipmentRevenueArray } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { FormArray } from '@angular/forms';
import { BaseForm } from "../../../../base/form-base";
import { FormInputCostV3 } from "../input-cost-v3";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { ShipmentEntryMode as ShipmentEntryModeEnum } from "@wearewarp/types";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Log } from "@services/log";
import { BizUtil } from "@services/biz";

@Component({
  selector: '[form-batch-shipment-revenue]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormBatchShipmentRevenue extends BaseForm<FormDataBatchShipmentRevenueArray> {
  @ViewChildren('costForms') costForms: QueryList<FormInputCostV3>;

  @Input() batchShipmentType: ShipmentEntryModeEnum;
  revenueTypeArr = [];
  selectLocations = [];
  listDataClient = [];
  totalRevenueMultiShipments = 0;

  // trường hợp MULTI_DROPOFF, MULTI_PICKUP: mảng revenueInfos luôn luôn có 1 phần tử, 
  // type=batchRevenue,dựa vào deliveryInfos để tính số lượng pickup, dropff  
  // cost nằm trong revenues[0].cost

  // trường hợp MULTIPLE, mảng revenueInfos có số lượng phần tử bằng số lượng client
  // type=batchRevenue hoặc perShipmentRevenue
  // nếu type=batchRevenue thì cost là revenues[0].cost
  // nếu type=perShipmentRevenue thì cost tương ứng với shipmentId

  constructor(protected api: ApiService) {
    super();
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    settings: {label: '', type: 'formGroup', notAcceptEmpty: true, childItem: {
      isDoNotInvoice: {label: 'Do not invoice', type: 'boolean', notAcceptEmpty: true},
      reasonDoNotInvoice: {label: 'Reason do not invoice', type: 'string', notAcceptEmpty: true}
    }},
    revenueInfos: {label: '', type: 'formArray', required: true, initialValue: [{}], childItem: {
      clientId: {label: ''},
      type: {label: '', initialValue: 'single'},
      shipments: {label: '', type: 'array', initialValue: []},
      deliveryInfos: {label: '', type: 'array', initialValue: []},
      revenues: {label: '', type: 'formArray', initialValue: [{}], childItem: {
        shipmentId: {label: ''},
        cost: {label: '', type: 'formGroup', childItem: FormInputCostV3.declarationForCreate}
      }}
    }}
  }

  ngOnInit(): void {
    Log.d('BatchShipmentRevenue - Model:', this.model);
    this.prepareData();
    super.ngOnInit();
    this.setEnable(true);
    this.fetchListClient();
  }

  ngAfterViewInit() {
    setTimeout(() => this.updateServiceOption_FromDeliveryInfo(), 1);
  }

  // tính toán 1 số dữ liệu phục vụ cho select phía UI và FormInputCost
  protected prepareData() {
    switch (this.batchShipmentType) {
      case Const.ShipmentEntryMode.multiPick:
      case Const.ShipmentEntryMode.multiDrop:
        this.selectLocations = [];
        this.revenueTypeArr = [
          { value: Const.CostLevel.order, label: 'Batch Revenue'}
        ]
        let deliveryInfos = this.model?.revenueInfos?.[0]?.deliveryInfos || [];
        let pickInfos = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP);
        let dropInfos = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF);
        for (let i=0;i <pickInfos.length; i++) {
          this.selectLocations.push({deliveryId: pickInfos[i].id, type: pickInfos[i].type, label: `Pickup Location ${i+1}`});
        }
        for (let i=0;i <dropInfos.length; i++) {
          this.selectLocations.push({deliveryId: dropInfos[i].id, type: dropInfos[i].type, label: `Delivery Location ${i+1}`});
        }
        break;
      case Const.ShipmentEntryMode.multiPickDrop:
        this.revenueTypeArr = [
          { value: Const.CostLevel.shipment, label: 'Per Shipment Revenue'},
          { value: Const.CostLevel.order, label: 'Batch Revenue'}
        ]
        for (let revenueInfo of this.model?.revenueInfos || []) {
          for (let shipment of revenueInfo?.shipments || []) {
            let pickupTask = shipment.pickInfo;
            let dropoffTask = shipment.dropInfo;
            if (pickupTask.addr) shipment['pickupAddress'] = this.getAddressText(pickupTask.addr);
            if (dropoffTask.addr) shipment['dropoffAddress'] = this.getAddressText(dropoffTask.addr);
          }
        }
        break;
    }
  }

  public getFormData(): FormDataBatchShipmentRevenueArray {
    // trường hợp BatchShipment MULTIPLE có  RevenueType = perShipmentRevenue, 
    // vì sử dụng formInputCost dạng SINGLE nên cần update lại deliveryId cho serviceOption
    let data = super.getFormData();
    if (this.batchShipmentType == Const.ShipmentEntryMode.multiPickDrop) {
      for (let revenueInfo of data?.revenueInfos || []) {
        if (revenueInfo?.type != Const.CostLevel.shipment) continue;
        let shipments = revenueInfo.shipments || [];
        for (let revenue of revenueInfo?.revenues || []) {
          let shipmentId = revenue?.shipmentId;
          let shipment = shipments.filter(item => item['id'] == shipmentId)?.[0];
          if (!shipment) continue;
          let pickInfo = shipment.pickInfo;
          let dropInfo = shipment.dropInfo;
          for (let serviceOption of revenue?.cost?.serviceOptions || []) {
            let currentService = MasterData.getServiceOptionById(serviceOption._id);
            if (currentService.type == 'pickup') {
              serviceOption.deliveryId = pickInfo.id;
            } else if (currentService.type == 'delivery') {
              serviceOption.deliveryId = dropInfo.id;
            }
          }
        }
      }
    }
    for (let revenueInfo of data?.revenueInfos || []) {
      for (let revenue of revenueInfo.revenues || []) {
        if (revenue.cost && revenue.cost.currency?.type &&  revenue.cost.currency?.type != Const.CurrencyConfig.USD.value) {
          // chuyển tất cả rate về chuẩn USD
          revenue.cost = BizUtil.convertCostToUSD(revenue.cost);
        }
      }
    }
    return data;
  }

  serviceOptionsForCostSection = [
    {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
    {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
    {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
  ];

  get shouldShowCustomerRow() {
    if (this.batchShipmentType == Const.ShipmentEntryMode.multiPickDrop) return true;
    return false; 
  }

  getCustomerName(groupKey, revenueInfoIndex) {
    let clientId = this.getItemValue(`${groupKey}[${revenueInfoIndex}].clientId`);
    let client = this.listDataClient.filter(it => it.id == clientId)[0];
    if (client?.name) return client.name;
    return clientId;
  }

  get createShipmentModeForCost(): ShipmentEntryModeEnum {
    return this.batchShipmentType;
  }

  get isCreateShipmentModeForCost_MULTIPLE() {
    return this.createShipmentModeForCost == Const.ShipmentEntryMode.multiPickDrop;
  }

  isPerShipmentRevenueType(groupKey, revenueInfoIndex) {
    let revenueType = this.getItemValue(`${groupKey}[${revenueInfoIndex}].type`);
    if (revenueType == Const.CostLevel.shipment) return true;
    return false;
  }

  onRevenueTypeChange(groupKey, revenueInfoIndex, value) {
    if (value == Const.CostLevel.shipment) {
      this.setItemValue(`${groupKey}[${revenueInfoIndex}].revenues`,[]);
      let newData = [];
      let shipments = this.getItemValue(`${groupKey}[${revenueInfoIndex}].shipments`);
      if (Utils.isArrayNotEmpty(shipments)) {
        for (let shipment of shipments) newData.push({shipmentId: shipment.id});
      }
      this.setItemValue(`${groupKey}[${revenueInfoIndex}].revenues`,newData);
      this.selectLocations = [
        { deliveryId: "PICKUP_1", type: Const.TaskType.PICKUP, label: 'Pickup Location 1'},
        { deliveryId: "DROPOFF_1", type: Const.TaskType.DROPOFF, label: 'Delivery Location 1'},
      ]
    } else if (value == Const.CostLevel.order) {
      this.setItemValue(`${groupKey}[${revenueInfoIndex}].revenues`,[]);
      this.setItemValue(`${groupKey}[${revenueInfoIndex}].revenues`,[{}]);
    }
    setTimeout(() => this.updateServiceOption_FromDeliveryInfo(), 10);
  }

  getShipmentIndex(groupKey, revenueInfoIndex, key, revenueIndex) {
    let shipments = this.model[groupKey]?.[revenueInfoIndex]?.shipments || [];
    let shipmentId = this.getItemValue(`${groupKey}[${revenueInfoIndex}].${key}[${revenueIndex}].shipmentId`);
    let shipment = shipments.filter(item => item.id == shipmentId)?.[0];
    return (shipment?.index || 0) + 1;
  }

  getFormCost(groupKey, revenueInfoIndex, key, revenueIndex) {
    let fg = (<FormArray>this.formInput.get(groupKey))?.at(revenueInfoIndex);
    fg = (<FormArray>fg?.get(key))?.at(revenueIndex);
    return fg?.get('cost');
  }

  getShipmentsForCost(groupKey, revenueInfoIndex) {
    return this.model[groupKey]?.[revenueInfoIndex]?.shipments;
  }

  // dùng để update TotalRenveue trong trường hợp BatchShipmentType.MULTIPLE
  updateTotalRevenueMultiShipments() {
    if (this.batchShipmentType != Const.ShipmentEntryMode.multiPickDrop) return;
    let data = this.getFormData();
    let totalRevenue = 0;
    for (let revenueInfo of data?.revenueInfos || []) {
      for (let revenue of revenueInfo?.revenues || []) {
        let grandTotal = revenue?.cost?.currency?.type == 'USD' ? revenue?.cost?.grandTotal : revenue?.cost?.usdConversion;
        if (Utils.isNumber(grandTotal)) totalRevenue += grandTotal;
      }
    }
    this.totalRevenueMultiShipments = totalRevenue;
  }

  get totalRevenueAllShipments() {
    return InputHelper.formatMoney2(`${this.totalRevenueMultiShipments}`);
  }

  getPickupAddress(groupKey, revenueInfoIndex, key, revenueIndex) {
    let shipments = this.model[groupKey]?.[revenueInfoIndex]?.shipments || [];
    let shipmentId = this.getItemValue(`${groupKey}[${revenueInfoIndex}].${key}[${revenueIndex}].shipmentId`);
    let shipment = shipments.filter(item => item.id == shipmentId)?.[0];
    return shipment?.pickupAddress || 'N/A';
  }

  getDropoffAddress(groupKey, revenueInfoIndex, key, revenueIndex) {
    let shipments = this.model[groupKey]?.[revenueInfoIndex]?.shipments || [];
    let shipmentId = this.getItemValue(`${groupKey}[${revenueInfoIndex}].${key}[${revenueIndex}].shipmentId`);
    let shipment = shipments.filter(item => item.id == shipmentId)?.[0];
    return shipment?.dropoffAddress || 'N/A';
  }

  public getAddressText(addr): string {
    if (!addr) return '';
    return `${addr.city}, ${addr.state}`;
  }

  private fetchListClient() {
    let url = `${Const.APIURI_CLIENTS}?limit=-1`;
    this.api.GET(url).subscribe(
      resp => {
        // Log.d('fetchListData done ', resp);
        this.listDataClient = resp.data.list_data;
      }, err => {
      }
    );
  }

  protected onModelDataChanged(): void {
    if (!this.batchShipmentType) return;
    Log.d('onModelDataChanged - Batch Revenue Tab',this.model);
    this.prepareData();
    Log.d('SelectLocations - ',this.selectLocations)
    this.updateServiceOption_FromDeliveryInfo();
  }

  protected updateServiceOption_FromDeliveryInfo() {
    switch (this.batchShipmentType) {
      case Const.ShipmentEntryMode.multiPick:
        return this.updateServiceOption_FromDeliveryInfo_MultiPick();
      case Const.ShipmentEntryMode.multiDrop:
        return this.updateServiceOption_FromDeliveryInfo_MultiDrop();
      case Const.ShipmentEntryMode.multiPickDrop:
        return this.updateServiceOption_FromDeliveryInfo_MultiPickDrop();
    }
  }

  updateServiceOptionAfterGetItemValue(serviceOptions) {
    if (!Utils.isArrayNotEmpty(serviceOptions)) return;
    for (let item of serviceOptions) {
      item.rate = InputHelper.getValueMoney(item.rate);
      if (Utils.isNumber(item?.qty) && Utils.isNumber(item?.rate) && item.qty > 0 && item.rate > 0) {
        item.total = item.qty * item.rate;
      }
    }
  }

  protected updateServiceOption_FromDeliveryInfo_MultiPick() {
    let deliveryInfos = this.model?.revenueInfos?.[0]?.deliveryInfos || [];
    let pickInfos = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP);
    let dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
    let serviceOptionsDropoff = dropInfo?.serviceOptions || [];
    let serviceOptionsPickups: {deliveryId: string, serviceOptionId: string}[] = [];
    for (let pickInfo of pickInfos || []) {
      for (let service of pickInfo.serviceOptions || []) {
        serviceOptionsPickups.push({deliveryId: pickInfo.id, serviceOptionId: service});
      }
    } 
    let costForm = this.costForms?.get(0);
    let currentOptions = costForm?.getValueServiceOptions() || [];
    this.updateServiceOptionAfterGetItemValue(currentOptions);
    let arr = [];
    // giữ lại những thằng giống nhau
    for (let item of currentOptions) {
      let option = MasterData.getServiceOptionById(item._id);
      if (!option) continue;
      if (option.type == 'addition') {
        arr.push(item);
      } else if (option.type == 'delivery') {
        for (let i=0; i < serviceOptionsDropoff.length; i++) {
          if (serviceOptionsDropoff[i] == item._id) arr.push(item);
        }
      } else if (option.type == 'pickup') {
        for (let i=0; i < serviceOptionsPickups.length; i++) {
          if (serviceOptionsPickups[i].serviceOptionId == item._id) arr.push(item);
        }
      }
    }
    // thêm những thằng mới
    for (let itemId of serviceOptionsDropoff) {
      if (arr.filter(it => it._id == itemId).length == 0) {
        arr.push({_id: itemId, deliveryId: dropInfo.id});
      }
    }
    for (let item of serviceOptionsPickups) {
      if (arr.filter(it => it._id == item.serviceOptionId && it.deliveryId == item.deliveryId).length == 0) {
        arr.push({_id: item.serviceOptionId, deliveryId: item.deliveryId});
      }
    }
    // format money value to number VD: $10 -> 10
    for (let item of arr) {
      item.rate = InputHelper.getValueMoney(item.rate);
      item.total = InputHelper.getValueMoney(item.total);
    }
    Log.d('updateServiceOption_FromDeliveryInfo: ',arr);
    costForm?.setValueServiceOptions(arr);
  }

  protected updateServiceOption_FromDeliveryInfo_MultiDrop() {
    let deliveryInfos = this.model?.revenueInfos?.[0]?.deliveryInfos || [];
    let pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
    let dropInfos = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF);
    let serviceOptionsPickup = pickInfo?.serviceOptions || [];
    let serviceOptionsDropoffs: {deliveryId: string, serviceOptionId: string}[] = [];
    for (let dropInfo of dropInfos || []) {
      for (let service of dropInfo.serviceOptions || []) {
        serviceOptionsDropoffs.push({deliveryId: dropInfo.id, serviceOptionId: service});
      }
    } 
    let costForm = this.costForms?.get(0);
    let currentOptions = costForm?.getValueServiceOptions() || [];
    this.updateServiceOptionAfterGetItemValue(currentOptions);
    let arr = [];
    // giữ lại những thằng giống nhau
    for (let item of currentOptions) {
      let option = MasterData.getServiceOptionById(item._id);
      if (!option) continue;
      if (option.type == 'addition') {
        arr.push(item);
      } else if (option.type == 'pickup') {
        for (let i=0; i < serviceOptionsPickup.length; i++) {
          if (serviceOptionsPickup[i] == item._id) arr.push(item);
        }
      } else if (option.type == 'delivery') {
        for (let i=0; i < serviceOptionsDropoffs.length; i++) {
          if (serviceOptionsDropoffs[i].serviceOptionId == item._id) arr.push(item);
        }
      }
    }
    // thêm những thằng mới
    for (let itemId of serviceOptionsPickup) {
      if (arr.filter(it => it._id == itemId).length == 0) {
        arr.push({_id: itemId, deliveryId: pickInfo.id});
      }
    }
    for (let item of serviceOptionsDropoffs) {
      if (arr.filter(it => it._id == item.serviceOptionId && it.deliveryId == item.deliveryId).length == 0) {
        arr.push({_id: item.serviceOptionId, deliveryId: item.deliveryId});
      }
    }
    // format money value to number VD: $10 -> 10
    for (let item of arr) {
      item.rate = InputHelper.getValueMoney(item.rate);
      item.total = InputHelper.getValueMoney(item.total);
    }
    Log.d('updateServiceOption_FromDeliveryInfo: ',arr);
    costForm?.setValueServiceOptions(arr);
    
  }

  protected updateServiceOption_FromDeliveryInfo_MultiPickDrop() {
    const revenueInfos = this.getItemValue(`revenueInfos`) || [];
    for (let i=0; i<revenueInfos.length; i++) {
      let revenueInfo = revenueInfos[i];
      if (revenueInfo?.type == Const.CostLevel.order) {
        this.updateServiceOption_FromDeliveryInfo_MultiPickDrop_batchRevenue(i);
      } else if (revenueInfo?.type == Const.CostLevel.shipment) {
        this.updateServiceOption_FromDeliveryInfo_MultiPickDrop_perShipmentRevenue(i);
      }
    }
  }

  protected updateServiceOption_FromDeliveryInfo_MultiPickDrop_batchRevenue(revenueInfoIndex) {
    let shipments = this.model?.revenueInfos?.[0]?.shipments || [];
    let serviceOptionsPickups: {deliveryId: string, serviceOptionId: string}[] = [];
    let serviceOptionsDropoffs: {deliveryId: string, serviceOptionId: string}[] = [];
    for (let shipment of shipments) {
      let pickInfo = shipment.pickInfo;
      let dropInfo = shipment.dropInfo;
      for (let service of pickInfo.serviceOptions || []) {
        serviceOptionsPickups.push({deliveryId: pickInfo.id, serviceOptionId: service});
      }
      for (let service of dropInfo.serviceOptions || []) {
        serviceOptionsDropoffs.push({deliveryId: dropInfo.id, serviceOptionId: service});
      }
    }
    let costFormIndex = this.getCostFormIndex(revenueInfoIndex);
    let costForm = this.costForms?.get(costFormIndex);
    let currentOptions = costForm?.getValueServiceOptions() || [];
    this.updateServiceOptionAfterGetItemValue(currentOptions);
    let arr = [];
    // giữ lại những thằng giống nhau
    for (let item of currentOptions) {
      let option = MasterData.getServiceOptionById(item._id);
      if (!option) continue;
      if (option.type == 'addition') {
        arr.push(item);
      } else if (option.type == 'pickup') {
        for (let i=0; i < serviceOptionsPickups.length; i++) {
          if (serviceOptionsPickups[i].serviceOptionId == item._id && serviceOptionsPickups[i].deliveryId == item.deliveryId) arr.push(item);
        }
      } else if (option.type == 'delivery') {
        for (let i=0; i < serviceOptionsDropoffs.length; i++) {
          if (serviceOptionsDropoffs[i].serviceOptionId == item._id && serviceOptionsDropoffs[i].deliveryId == item.deliveryId) arr.push(item);
        }
      }
    }
    // thêm những thằng mới
    for (let item of serviceOptionsPickups) {
      if (arr.filter(it => it._id == item.serviceOptionId && it.deliveryId == item.deliveryId).length == 0) {
        arr.push({_id: item.serviceOptionId, deliveryId: item.deliveryId});
      }
    }
    for (let item of serviceOptionsDropoffs) {
      if (arr.filter(it => it._id == item.serviceOptionId && it.deliveryId == item.deliveryId).length == 0) {
        arr.push({_id: item.serviceOptionId, deliveryId: item.deliveryId});
      }
    }
    // format money value to number VD: $10 -> 10
    for (let item of arr) {
      item.rate = InputHelper.getValueMoney(item.rate);
      item.total = InputHelper.getValueMoney(item.total);
    }
    Log.d('updateServiceOption_FromDeliveryInfo: ',arr);
    costForm?.setValueServiceOptions(arr);
  }

  protected updateServiceOption_FromDeliveryInfo_MultiPickDrop_perShipmentRevenue(revenueInfoIndex) {
    let shipments = this.model?.revenueInfos?.[0]?.shipments || [];
    let costFormIndex = this.getCostFormIndex(revenueInfoIndex);
    for (let i=0; i<shipments.length; i++) {
      const shipment = shipments[i];
      let pickInfo = shipment.pickInfo;
      let dropInfo = shipment.dropInfo;
      let serviceOptionsPickups: {deliveryId: string, serviceOptionId: string}[] = [];
      let serviceOptionsDropoffs: {deliveryId: string, serviceOptionId: string}[] = [];
      for (let service of pickInfo.serviceOptions || []) {
        serviceOptionsPickups.push({deliveryId: pickInfo.id, serviceOptionId: service});
      }
      for (let service of dropInfo.serviceOptions || []) {
        serviceOptionsDropoffs.push({deliveryId: dropInfo.id, serviceOptionId: service});
      }
      let costForm = this.costForms?.get(costFormIndex + i);
      let currentOptions = costForm?.getValueServiceOptions() || [];
      this.updateServiceOptionAfterGetItemValue(currentOptions);
      let arr = [];
      // giữ lại những thằng giống nhau
      for (let item of currentOptions) {
        let option = MasterData.getServiceOptionById(item._id);
        if (!option) continue;
        if (option.type == 'addition') {
          arr.push(item);
        } else if (option.type == 'pickup') {
          for (let i=0; i < serviceOptionsPickups.length; i++) {
            if (serviceOptionsPickups[i].serviceOptionId == item._id) arr.push(item);
          }
        } else if (option.type == 'delivery') {
          for (let i=0; i < serviceOptionsDropoffs.length; i++) {
            if (serviceOptionsDropoffs[i].serviceOptionId == item._id) arr.push(item);
          }
        }
      }
      // thêm những thằng mới
      for (let item of serviceOptionsPickups) {
        if (arr.filter(it => it._id == item.serviceOptionId && it.deliveryId == item.deliveryId).length == 0) {
          arr.push({_id: item.serviceOptionId, deliveryId: item.deliveryId});
        }
      }
      for (let item of serviceOptionsDropoffs) {
        if (arr.filter(it => it._id == item.serviceOptionId && it.deliveryId == item.deliveryId).length == 0) {
          arr.push({_id: item.serviceOptionId, deliveryId: item.deliveryId});
        }
      }
      // format money value to number VD: $10 -> 10
      for (let item of arr) {
        item.rate = InputHelper.getValueMoney(item.rate);
        item.total = InputHelper.getValueMoney(item.total);
      }
      Log.d('updateServiceOption_FromDeliveryInfo: ',arr);
      costForm?.setValueServiceOptions(arr);
    }
  }

  private getCostFormIndex(revenueInfoIndex) {
    const revenueInfos = this.getItemValue(`revenueInfos`);
    let count = 0;
    for (let i=0; i<revenueInfos.length; i++) {
      if (i == revenueInfoIndex ) return count;
      let revenueInfo = revenueInfos[i];
      if (revenueInfo?.type == Const.CostLevel.order) {
        count += 1;
      } else if (revenueInfo?.type == Const.CostLevel.shipment) {
        let numberShipments = Utils.isArrayNotEmpty(revenueInfo?.shipments) ? revenueInfo.shipments.length : 0;
        count += numberShipments;
      }
    }
    return count;
  }

  join(...args): string {
    return args.join(".");
  }

  onChangeDoNotInvoice() {
    if (!this.getItemValue("settings.isDoNotInvoice")) {
      this.setItemValue("settings.reasonDoNotInvoice", "")
    }
  }
  
}