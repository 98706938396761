import { StringDateTimeIso, StringULID, StringUUID } from "@wearewarp/types";
import { TimeWindow } from "@wearewarp/types/data-model";

// For UI
export declare interface LtlRoutingFilter {
  warpIds?: number[],
  clientIds?: StringULID[],
  pickupWarehouseId?: string,
  dropoffWarehouseId?: string,
  pickDateRange?: any[],    // sẽ được tách ra thành pickupFromDate & pickupToDate
  pickupFromDate?: string,
  pickupToDate?: string,
  timezone?: string,        // nếu chọn pickupCrossDockId thì sẽ lấy timezone của pickup CrossDock, nếu chọn dropoffCrossDockId thì lấy timezone của dropoff CrossDock
}

export declare interface LtlRoutingDeliveryInfoViewData {
  address?: string,
  window?: string,
  tz?: string,
  tzShort?: string,
  isAppointment?: boolean,
  isWindowError?: boolean,    // không có ngày giờ PICKUP/DROPOFF
  errMsg?: string,
  locationName?: string,
}

export declare interface LtlRoutingShipmentViewData {
  palletDesc?: string,
  pick?: LtlRoutingDeliveryInfoViewData,
  drop?: LtlRoutingDeliveryInfoViewData,
}

export enum ProblemRoutingState {
  notRunning = 'notRunning',
  inProgress = 'inProgress',        // đang chạy routing cho 1 hoặc nhiều sub problem
  confirming = 'confirming',        // sau khi đã chạy xong routing rồi thì cần confirm rồi mới tạo job/task
  didRouteAll = 'didRouteAll',      // đã được route xong cho tất cả các shipment
  done = 'done'                     // đã confirm và tạo xong job/task, kết thúc quá trình
}

export enum RoutingRegionSelectMode {
  byPickUp = 'PICKUP',
  byDropOff = 'DROPOFF',
}

// Models from database
export declare interface ModelRoutingShipment {
  deliveryInfos: any;
  //routingShipment: any;
  id: string,
  eid: number,    // externalId
  attributes: any,    // deprecated
  pickup_location: { latlng: number[] },      // array [latitude, longitude]
  dropoff_location: { latlng: number[] },     // array [latitude, longitude]
  dropoff_buffer: number,
  workload: number,
  dropoff_window: number[],                 // array [start, end] timestamp in milliseconds
  metadata?: any,
}

export declare interface ModelRoutingProblem {
  routingShipment?: {
    shipments: ModelRoutingShipment[]
  };
  _id?: any,
  id?: string,
  num_shipments?: number,
  configuration?: {
    max_iteration: number,
    concurrency: number,
    constraints: Array<{ type: string, enabled: boolean, params: { [key: string]: string } }>,
  },
  workload?: number,
  totalWeight?: number,
  num_trials?: number,
  metadata?: { [key: string]: string },
  attributes?: any,                     // deprecated
  pickup_location?: any,
  children?: string[] | any[],
  vehicles?: any[],
  start_date?: number,
  end_date?: number,
  type?: string,
  dropoff_earliest_ts?: StringDateTimeIso,
  dropoff_latest_ts?: StringDateTimeIso,
  parent?: StringUUID,                  // id of parent problem
  reset_dropoff_time?: boolean,
  shipments?: any[],                    // trường này chỉ là trung gian, ko lưu vào DB,
  selectRegionMode?: RoutingRegionSelectMode,
  progress?: any,
  status?: string,
  selected_solution?: {
    cost: ModelRoutingCost,
    latest_start: number,
    start_location: ModelLocation
    capacity: number,
    id: StringUUID,
    solution_id: StringUUID,
    stops: ModelRoutingStop,
    viewData?: ModelViewDataRoute,
    workload: number,
    routes: ModelRoutingRoute[],
    unassigned_shipments: any[]
  },
}

export declare interface ModelRoutingRoute {
  _id?: any,
  id?: StringUUID,
  solution_id?: StringUUID,
  vehicle_category_id?: number,
  stops?: ModelRoutingStop[],
  cost?: ModelRoutingCost,
  start_location?: any,
  start?: number,
  end?: number,
  latest_start?: number,
  status?: string,
  capacity?: number,
  workload?: number,
  original?: string,
}

export declare interface ModelRoutingStop {
  shipment_id?: string,   // "1234"
  type?: string,          // 'P' means pickup, 'D' means dropoff
  workload?: number,
  location?: ModelLocation,
  arrival_ts?: number,
  departure_ts?: number,
  latest_arrival_ts?: number,
  travel_distance_from_previous_stop?: number,
  travel_time_from_previous_stop?: number,
  time_window?: TimeWindow,
  service_time?: number,
  accumualive_cost?: number,
  routeId?: number | string;
}

export declare interface ModelRoutingCost {
  travel_time?: number,               // in second
  service_time?: number,              // in second
  idle_time?: number,                 // in second
  travel_distance?: number,           // in metre
  travel_distance_cost?: number,
  travel_time_cost?: number,
  service_cost?: number,
  idle_cost?: number,
  deploy_cost?: number,
  total_cost?: number,
  penalty?: number,
  extra_cost?: any,
}

export declare interface ModelViewDataRoute {
  colorIndex: number,
  firstStop: ModelRoutingStop,
  lastStop: ModelRoutingStop,
  miles: string,
  routeIndex: number,
  shipmentIds: number[],
  shipmentIdsText: string,
  stopCount: number,
  tasks: ModelTaskRoute[],
  time: string,
}

export declare interface ModelTaskRoute {
  type: 'PICKUP' | 'DROPOFF',
  location?: ModelLocation
  info?: any;
}

export declare interface ModelLocation {
  id?: string,
  idx?: number,
  latlng: number[]
}

export declare interface ModelFilterData {
  condition?: any,
  startDate?: Date
  endDate?: Date,
  selectRegionMode?: string
}

export interface ModelMapPoint{
    
}