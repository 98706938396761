import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import { DateUtil } from "@services/date-utils";
import {
  // ResponseCustomerWarehouseDetail,
  ResponseCustomerWarehouseLinkedWarpWarehouse,
} from '@wearewarp/types/rest-api/admin/warehouse/customer-warehouse';
import { CustomerWarehouseDetailFormData, ResponseCustomerWarehouseLinkedWarpWarehouseUI } from './interface';
import { FormArray, FormControl, Validators } from '@angular/forms';



@Component({
  selector: 'customer-warehouse-detail',
  templateUrl: './view.html',
  styleUrls: ['../../../detail.scss', './style.scss']
})
export class CustomerWarehouseDetail extends BaseDetail<CustomerWarehouseDetailFormData> {
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: {label: 'Customer', required: true},
    clientLocId: {label: 'Customer Site ID', required: true},
    clientLocName: {label: 'Customer Site Name', required: true},
    addressText: {label: 'Address Text', required: true},
    warehouseId: {label: 'Linked to WARP Location'},
    pickupTimes: { label: 'Pickup Time', type: 'formArray', childItem: {
      isOpen: { label: '', type: 'boolean' },
      fromTime: { label: '', getValue: DateUtil.getHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'From time' },
      toTime: { label: '', getValue: DateUtil.getHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'To time' },
    }, initialValue: [
      { isOpen: false }, // monday
      { isOpen: false },
      { isOpen: false },
      { isOpen: false },
      { isOpen: false },
      { isOpen: false },
      { isOpen: false }, // sunday
    ]},
    deliveryTimes: { label: 'Delivery Time', type: 'formArray', childItem: {
      isOpen: { label: '', type: 'boolean' },
      fromTime: { label: '', getValue: DateUtil.getHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'From time' },
      toTime: { label: '', getValue: DateUtil.getHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'To time' },
    }, initialValue: [
      { isOpen: false }, // monday
      { isOpen: false },
      { isOpen: false },
      { isOpen: false },
      { isOpen: false },
      { isOpen: false },
      { isOpen: false }, // sunday
    ]},
  }

  listClients = [];
  listWarehouses = [];
  public dayLables = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  protected getApiUrl(): string {
    return Const.APIV2('customer-warehouses');
  }

  getApiListDataForFilter(key: string) {
    switch (key) {
      case 'clientId': return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
      case 'warehouseId': return Const.APIURI_WAREHOUSES_FOR_FILTER;
    }
  }

  showWarpLocation(item?: ResponseCustomerWarehouseLinkedWarpWarehouse): string {
    const warehouse = item ?? this.model.warehouse;
    if (!warehouse) return 'N/A';
    // return `[${warehouse.warpId}] ${warehouse.fullAddr}`;
    return `${warehouse.fullAddr} (${warehouse.warpId} - ${warehouse.name})`;
  }

  onBtnLinkWarpLocation(item: ResponseCustomerWarehouseLinkedWarpWarehouse) {
    const message = `Link customer's location <b>${this.model.clientLocName}</b> to the Warp's location <b>${this.showWarpLocation(item)}</b>?`
    this.confirmYesNo(message, () => this.linkWarpLocation(item));
  }
  
  isLinking(item: ResponseCustomerWarehouseLinkedWarpWarehouseUI): boolean {
    return item.isLoading
  }

  private linkWarpLocation(item: ResponseCustomerWarehouseLinkedWarpWarehouseUI) {
    item.isLoading = true;
    const url = `${this.getApiUrl()}/${this.id}/link`;
    this.api.POST(url, { warpLocationId: item.id }).subscribe(
      resp => {
        this.getData();       // reload
        item.isLoading = false;
      }, err => {
        this.showErr(err);
        item.isLoading = false;
      }
    );
  }

  protected onGetDetailSuccess(data: CustomerWarehouseDetailFormData): CustomerWarehouseDetailFormData {
    if (this.listClients.length == 0) {
      this.listClients.push({id: data.client.id, name: data.client.name});
    }
    if (this.listWarehouses.length == 0 && data.warehouse) {
      this.listWarehouses.push({id: data.warehouse.id, name: data.warehouse.name});
    }
    if (!data.clientId) {
      data.clientId = data.client.id;
    }
    if (!data.warehouseId && data.warehouse) {
      data.warehouseId = data.warehouse.id;
    }
    return data;
  }

  getTextDayOpen(key: string, index: number): string {
    let isOpen = this.getItemValue(`${key}[${index}].isOpen`);
    return isOpen ? 'Open' : 'Closed';
  }

  onChangeToggleWindow(key: string, index: number, value: any) {
    let isRequired = value;
    let fa = <FormArray>this.formInput.get(key);
    let fg = fa.at(index);
    let childKeys = ['fromTime', 'toTime']
    for (let childKey of childKeys) {
      let childControl = <FormControl>fg.get(childKey);
      if (childControl) {
        if (!isRequired) {
          childControl.removeValidators(Validators.required);
        } else {
          childControl.addValidators(Validators.required);
        }
        childControl.updateValueAndValidity();
      }
    }
  }

  isWindowRequired(key: string, index: number) {
    let fa = <FormArray>this.formInput.get(key);
    let fg = fa.at(index);
    return fg.get('isOpen').value;
  }
}