<div class="shipment-entry-header">
  <div class="btn-close center-children clickable" [ngClass]="{'disabled': !canClose}">
    <i nz-icon nzType="close" nzTheme="outline" (click)="onBtnClose()"></i>
  </div>
  <div class="page-title">{{topbarTitle}}</div>
  <i *ngIf="hasDraft" class="draft-status">Draft</i>
</div>

<div class="shipment-entry-content">
  <div style="flex: 1; margin-right: 20px; background: #FFFFFF;;">
    <div shipment-tabs #shipmentTabs [tabs]="tabs" 
      [validateCanChangeTab]="validateCanChangeTab" (tabChanged)="onTabChanged($event)">
    </div>
  </div>
  <div shipment-entry-info-summary [isSingle]="isSingleMode" [modelDraft]="modelDraft" (onSortedTaskIdsChanged)="updateSortedTaskIds($event)"></div>
</div>

<div class="shipment-entry-footer">
  <button style="margin-left: 20px; margin-right: 20px;" 
    nz-button nzSize="large" nzType="primary" 
    (click)="onBtnSave()" [nzLoading]="isSavingData" [disabled]="isPatchingData || isSavingData || isNeedReasonDoNotInvoice">
    Save & Continue
  </button>
  <button nz-button nzSize="large" (click)="onBtnCancel()" [disabled]="!canClose">Cancel</button>
</div>


