<nz-result
  [nzStatus]="isExpectedError() ? 'warning' : 'success'"
  [nzTitle]="getTitle()"
  [nzSubTitle]="getSubTitle()"
>
  <div nz-result-extra>
      <div *ngFor="let item of data;" class="result-item">
        <div>
          <img [src]="getPartyLogo(item)" alt="party-logo" class="result-item-logo" />
        </div>
        <div>
          <nz-tag [nzColor]="getPostStatusColor(item)">{{getPostStatus(item)}}</nz-tag>
        </div>
      </div>
  </div>
</nz-result>
