import { Component, ViewChild } from '@angular/core';
import { FormDataShipmentEntryDraftSingle, FormDataShipmentLocation } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import { FormShipmentLocation } from '../../forms/shipment-location';
import { ComponentForm } from '@app/admin/shipment-entry/interface';
import { BaseForm } from '@app/admin/base/form-base';

@Component({
  selector: '[shipment-entry-single-delivery]',
  templateUrl: './index.html'
})
export class ShipmentEntrySingleDelivery extends ShipmentEntryTabContent implements ComponentForm<FormDataShipmentLocation> {

  @ViewChild('formComp') formComp: FormShipmentLocation;
  
  getForm(): BaseForm<FormDataShipmentLocation> {
    return this.formComp;
  }

  get modelDraft(): FormDataShipmentEntryDraftSingle {
    return super.modelDraft;
  }

  get formModel() {
    return this.modelDraft?.dropInfo;
  }

  get otherLocations() {
    return [this.modelDraft?.pickInfo];
  }

  get shipmentEntryMode() {
    return this.modelDraft?.shipmentEntryMode;
  }
}