import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LtlRoutingDeliveryInfoViewData, LtlRoutingShipmentViewData } from "../../interface";
import { Shipment } from "../../services/objects/shipment";
import { ShipmentManagement } from "../../services/shipmentManagement";

@Component({
  selector: '[routing-shipment-item]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class RoutingShipmentItem extends BaseComponent {
  private _model: Shipment;
  @Input() get model() { return this._model }
  set model(value) {
    this._model = value;
    this.onModelChanged();
  }
  @Input() canRemove = false;
  @Output() removeMe = new EventEmitter<any>();

  private viewData: any;

  get palletDesc() { return this.viewData?.palletDesc }

  constructor(private shipmentManagement: ShipmentManagement) {
    super();
  }


  getClient() {
    return this.model.getData().client
  }

  private getModelData() {
    return this.model?.getData() || {}
  }
  
  getRouteId() {
    return this.getModelData()?.lastJobId;
  }

  getRouteCode() {
    return this.getModelData()?.jobInfo?.code || this.getRouteId();
  }

  getPalletDesc() {
    let viewData: LtlRoutingShipmentViewData = this.viewData
    return viewData.palletDesc;
  }

  getAddress(type: 'pick' | 'drop'): string {
    let viewData: LtlRoutingDeliveryInfoViewData = this.viewData?.[type];
    return viewData?.address;
  }

  getLocationName(type: 'pick' | 'drop'): string {
    let viewData: LtlRoutingDeliveryInfoViewData = this.viewData?.[type];
    return viewData?.locationName || viewData?.address; // Nếu ko có locationName thì hiển thị địa chỉ
  }

  getWindow(type: 'pick' | 'drop'): string {
    let viewData: LtlRoutingDeliveryInfoViewData = this.viewData?.[type];
    let str = 'N/A';
    if (viewData) {
      str = viewData.window;
      if (viewData.tzShort) {
        str += ` (${viewData.tzShort})`;
      }
    }
    return str;
  }

  isWindowError(type: 'pick' | 'drop'): boolean {
    let viewData: LtlRoutingDeliveryInfoViewData = this.viewData?.[type];
    return viewData?.isWindowError ?? false;
  }

  onBtnRemove() {
    const shipmentId = this.model.getId()
    const warpId = this.showShipmentWarpId(this.model.getWarpId());
    this.confirmDeletion({
      message: `Exclude shipment <b>${warpId}</b> from routing?`,
      fnOk: () => {
        this.shipmentManagement.removeShipment(shipmentId);
      }
    })
  }

  private onModelChanged() {
    if (this.viewData) {
      return;
    }
    this.viewData = this.model.getData().viewData
  }

  onHold() {
    return this.model.getTags() && this.model.getTags().indexOf('HOLD') >= 0;
  }
}