import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ConfirmPod } from '.';
import { NgxViewerModule } from 'ngx-viewer';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { ModuleNotes } from '@app/admin/components/notes/module';
import { CreateByUserModule } from '@app/admin/components/create-by-user/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // FormsModule,
    // ReactiveFormsModule,
    NzTabsModule,
    NzIconModule,
    NzButtonModule,
    NgxViewerModule,
    PdfViewerModule,
    ModuleNotes,
    CreateByUserModule,
  ],
  declarations: [ConfirmPod],
  exports: [ConfirmPod]
})
export class ModuleConfirmPod {
}