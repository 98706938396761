import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalHelper } from "@wearewarp/ng-antd";
import { ThirdPartyInfoDialog } from "./dialog";
import { ThirdPartyQuoteScreen } from "./quoting_screen";
import { NzModalRef } from "ng-zorro-antd/modal";
import { DateUtil } from "@services/date-utils";
import { BizUtil } from "@services/biz";

@Component({
    selector: '[third-party-quote-icon]',
    template: '<i nz-icon nzType="container" nz-tooltip="Shipment Quote" (click)="showDialog()" nzTheme="outline" class="right10 clickable"></i>',
    styleUrls: ['./index.scss']
})
export class ThirdPartyQuoteIcon extends BaseComponent {
    @Input() shipment: any;
    @Output() onBooked: EventEmitter<string> = new EventEmitter<string>();

    modal: NzModalRef = null

    ngOnInit(): void {
        this.onBookComplete = this.onBookComplete.bind(this)
    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        private modalHelper: ModalHelper,

    ){
        super(activatedRoute);
    }

    onBookComplete(id) {
        this.onBooked?.emit(id)
        this.modal?.close()
    }

    validate() {
        if (!this.shipment) {
            this.showErr('No Shipment found!')
            return false
        }

        if (this.shipment.lastJobId) {
            // TODO: Check job status
            this.showErr('Shipment was already routed!')
            return false
        }

        const pickup = BizUtil.getPickInfo(this.shipment)
        const dropoff = BizUtil.getDropInfo(this.shipment)
        const { appointmentInfo, windows, requiresAppointment } = pickup || {}
        const pickupFrom = (requiresAppointment ? appointmentInfo?.from : null) || ((windows || [])[0]?.from) // TODO: handle multiple windows

        if (!pickupFrom) {
            this.showErr('Pickup time needs to be set!')
            return false
        }

        if (Date.parse(pickupFrom) < Date.now()) {
            this.showErr('Please update pickup time. Intended pickup time must be in the future!')
            return false
        }

        if (!BizUtil.getPrimaryContact(pickup)?.phone) {
            this.showErr('Please contact phone for pickup location.')
            return false
        }
        if (!BizUtil.getPrimaryContact(dropoff)?.phone) {
            this.showErr('Please set contact phone for dropoff location.')
            return false
        }

        // location name
        if (!pickup.locationName) {
            this.showErr('Please set location name for pickup location')
            return false
        }

        if (!dropoff.locationName) {
            this.showErr('Please set location name for dropoff location')
            return false
        }

        // check pickup date
        // const pickup =

        return true
    }

    showDialog() {
        if (!this.validate()) return

        this.modal = this.modalHelper.open(ThirdPartyQuoteScreen, {
          nzClassName: 'modal-no-padding',
          nzCentered: true,
          nzCancelText: null,
          nzWidth: 800,
          nzComponentParams: {
            shipment: this.shipment,
            onBookComplete: this.onBookComplete
          }
        })
    }
}
