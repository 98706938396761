import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { environment } from "@env/environment";

@Component({
    selector: '[third-party-quote-screen]',
    templateUrl: 'quoting_screen.html',
    styleUrls: ['./quoting_screen.scss']
})
export class ThirdPartyQuoteScreen extends BaseComponent {
    @Input() shipment: any;
    @Input() onBookComplete: any;

    quoting: boolean = false
    quotingResponse: any = null
    providers: any[] = []

    selectingProviders: boolean = true

    ngOnInit(): void {
        // this.initiateQuoting()
        // TODO: setting / config
        this.providers = [{
            name: 'WARP',
            value: 'WARP',
            selected: false,
        }, {
            name: 'Priority1',
            value: 'Priority1',
            selected: true,
        }, {
            name: 'WordWide Express',
            value: 'WWEX',
            selected: true,
        }]
    }

    initiateQuoting() {
        const { id } = this.shipment
        const providers = this.providers.filter(it => it.selected).map(it => it.value).join(",")
        const url = environment.backendUrl + `/v2/pricing/quote/shipments/${id}` + (this.isAll ? '' : `?providers=${providers}`)
        this.quoting = true
        this.api.POST(url, {}, {
            timeout: 10 * 60 * 1000 // 10 minutes
        }).subscribe((res) => {
            this.quoting = false
            this.quotingResponse = res
        }, (res) => {
            this.quoting = false
            this.showErr(res.text)
        })
    }

    get isAll() {
        if (this.providers.filter(it => it.selected == true).length < 1) return true
        if (this.providers.filter(it => it.selected !== true).length < 1) return true
        return false
    }

    selectProviders() {
        this.selectingProviders = true
    }
    confirmProviders() {
        this.selectingProviders = false
        this.initiateQuoting()
    }
}