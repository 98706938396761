import { Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { BizUtil } from "@services/biz";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { AddressUS, LatLng } from "@wearewarp/types/data-model";
import { BehaviorSubject, Subject } from "rxjs";
import { ModelFilterData } from "../interface";
import { ShipmentManagement } from "./shipmentManagement";
import { Websocket } from "./websocket.service";
import { Utils } from "@services/utils";

@Injectable()
export class RoutingService {
  public errorSubject: Subject<any> = new Subject();
  public isLoading: Subject<boolean> = new Subject();
  public uiControlSubject: Subject<{ event: string, data?: any }> = new Subject();
  private addresses: Map<string, any> = new Map()
  public listVehicles: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public filterData: BehaviorSubject<ModelFilterData> = new BehaviorSubject({});
  public isEditing = false;

  constructor(
    private api: ApiService,
    private websocketService: Websocket,
    private shipmentManagement: ShipmentManagement
  ) {
    this.getListVehicles()
  }

  public makeAddressDic(shipment) {
    let pickInfo = BizUtil.getPickInfo(shipment);
    let dropInfo = BizUtil.getDropInfo(shipment);
    let pickLocation = BizUtil.getDeliveryInfoLocation(pickInfo);
    let dropLocation = BizUtil.getDeliveryInfoLocation(dropInfo);
    this.addresses.set(this.textLatLng(pickLocation), { ...pickInfo, addressText: this.getAddressText(pickInfo.addr) });
    this.addresses.set(this.textLatLng(dropLocation), { ...dropInfo, addressText: this.getAddressText(dropInfo.addr) });
  }

  public textLatLng(location: LatLng): string {
    if (!location) return '';
    return `${location.latitude}, ${location.longitude}`;
  }

  public getAddressByLngLatKey(key) {
    return this.addresses.get(key);
  }

  public getAddressText(addr: AddressUS): string {
    return MasterData.getAddressText(addr);
  }

  public async getProblem(problemId) {
    try {
      const url = `${Const.APIURI_LTL_ROUTING('problems')}/${problemId}`;
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data;
      return data;;
    }
    catch (e) {
      this.errorSubject.next(e.message)
      return;
    }
  }

  public async createProblem(data) {
    let apiUrl = Const.APIURI_LTL_ROUTING('problems');
    const resp = await this.api.POST(apiUrl, data).toPromise();
    return resp.data;
  }

  public async getSolution(solutionId) {
    const resp = await this.api.GET(Const.APIURI_LTL_ROUTING(`solutions/${solutionId}`)).toPromise();
    return resp.data;
  }

  public async updateRegion({ problemId, regionMap }) {
    let url = Const.APIURI_LTL_ROUTING(`problems/${problemId}/cluster`);
    const resp = await this.api.POST(url, { regionMap }).toPromise();
    return resp.data;
  }

  public startRouting(subProblem) {
    let currentStatus = subProblem.status;
    subProblem.status = 'ROUTING_REQUEST_SENDING';
    // update vehicle cho shipment
    const shipments = subProblem.routingShipment?.shipments || [];
    if (Utils.isArrayNotEmpty(shipments)) {
      this.updateEquipmentForShipments({
        subProblemId: subProblem.id,
        shipmentIds: shipments.map(item => item.id)
      })
    }
    this.api.POST(Const.APIURI_LTL_ROUTING(`problems/${subProblem.id}/reroute`)).subscribe(
      resp => {
        Log.d('reroute done ', resp);
        // Chỗ này mới chỉ gửi request xong, quá trình routing sẽ lâu, progress sẽ nhận qua socket
      }, err => {
        //this.showErr(err);
        subProblem.status = currentStatus;
        this.errorSubject.next(err)
      }
    );
  }

  stopRouting(subProblem) {
    this.websocketService.sendWsData({ target: 'TOPIC@SYSTEM_SIGNAL', content: 'STOP_PROBLEM::' + subProblem.id });
  }

  async confirmRoutes(problemId) {
    let url = Const.APIURI_LTL_ROUTING(`problems/${problemId}/export`);
    const resp = await this.api.POST(url).toPromise();
    return resp.data;
  }

  async getDataForFilter() {
    let urls = [
      `${Const.APIURI_CLIENTS}/list/all_for_filter`,
      `${Const.APIURI_WAREHOUSES}/list/all_for_filter?exposeAddr=true`,
    ];
    const resp = await this.api.concurrentGET(urls).toPromise();
    const clients = resp?.[0]?.data?.list_data || [];
    const warehouses = resp?.[1]?.data?.list_data || [];
    return {
      clients,
      warehouses
    }
  }

  async getListShipmentFilter({ condition, selectRegionMode }) {
    const lists = await this.getListShipmentForAddMore({condition});
    let newFilterData = {
      startDate: condition.pickupFromDate,
      endDate: condition.pickupToDate,
      condition: condition,
      selectRegionMode,
    };
    this.shipmentManagement.reset().addShipments(lists);
    this.filterData.next(newFilterData);
  }

  async getListShipmentForAddMore({ condition }) {
    let apiUrl = Const.APIURI_GET_SHIPMENTS_FOR_ROUTING;
    let params = { filter: JSON.stringify(condition) };
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += `${qs}&limit=-1`;
    const resp = await this.api.GET(apiUrl).toPromise();

    return resp.data.list_data || []
  }

  async updateFilterData(newData) {
    let oldData = this.filterData.getValue();
    this.filterData.next({
      ...oldData,
      ...newData
    })
  }

  async updateListShipments() {
    const filterData = this.filterData.getValue();
    return this.getListShipmentFilter({
      condition: filterData.condition,
      selectRegionMode: filterData.selectRegionMode
    });
  }

  getFilterData() {
    return this.filterData.getValue()
  }
  resetFilterData() {
    this.updateFilterData({
      condition: undefined,
      startDate: undefined,
      endDate: undefined,
      selectRegionMode: undefined
    });
    this.shipmentManagement.reset()
  }

  public async getListVehicles() {
    const resp = await this.api.GET(Const.APIURI_LTL_ROUTING('vehicles')).toPromise();
    const data = resp.data.list_data;
    this.listVehicles.next(data);
    return data;
  }

  async addVehicle(listVehicles) {
    const resp = await this.api.POST(Const.APIURI_LTL_ROUTING('vehicles'), { vehicles: listVehicles }).toPromise();
    return this.getListVehicles();
  }

  async routeReSort({ routeId, tasks }) {
    const resp = await this.api.POST(Const.APIURI_LTL_ROUTING(`routes/${routeId}/reorder`), {
      tasks: tasks
    }).toPromise();
    return resp.data
  }

  async createManualRoute({ subProblemId, shipmentIds, vehicleId, vehicleOptions }) {
    this.updateEquipmentForShipments({ subProblemId, shipmentIds });
    const resp = await this.api.POST(Const.APIURI_LTL_ROUTING(`problems/${subProblemId}/create_manual_route`), {
      shipmentIds,
      vehicleId,
      vehicleOptions
    }).toPromise();
    return resp.data
  }

  async adjustShipment({ routeId, newShipmentIds, removeShipmentIds }) {
    const resp = await this.api.POST(Const.APIURI_LTL_ROUTING(`routes/${routeId}/adjustShipment`), {
      newShipmentIds,
      removeShipmentIds
    }).toPromise();
    return resp.data
  }

  async getTraffic({listFrom, listTo }) {
    const resp = await this.api.POST(Const.APIURI_LTL_ROUTING(`routes/getTraffic`), {
      listFrom,
      listTo
    }).toPromise();
    return resp.data
  }

  updateEquipmentForShipments({ subProblemId, shipmentIds }) {
    let params = {
      shipmentIds: shipmentIds,
      problemId: subProblemId,
    };
    this.api.POST(Const.APIURI_LTL_ROUTING('shipments/equipment'), params).subscribe(
      resp => { },
      err => { }
    );
  }


  private editFeature;
  setEditFeature(feature) {
    this.editFeature = feature;
  }
  getEditFeature() {
    return this.editFeature;
  }
}