<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="group">
      <ng-container *ngFor="let key of formInputKeys">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item style="max-width: 800px;">
          <nz-form-control>
            <ng-container [ngSwitch]="key">
              <select-by-searching *ngSwitchCase="'clientId'"
                [version]="2" type="client" [showSubClient]="true"
                [formControlName]="key" [apiUrl]="getApiListDataForFilter(key)"
                [placeholder]="getPlaceHolder(key)"
                [(listData)]="listClients">
              </select-by-searching>

              <select-by-searching *ngSwitchCase="'warehouseId'"
                [version]="2" type="warehouse" [showSubClient]="true"
                [formControlName]="key" [apiUrl]="getApiListDataForFilter(key)"
                [placeholder]="getPlaceHolder(key)"
                [(listData)]="listWarehouses">
              </select-by-searching>

              <ng-container *ngSwitchCase="'pickupTimes'" [formArrayName]="key">
                <form *ngFor="let control of getArrayControls(key); let i = index" nz-form [formGroupName]="i" class="time-window">
                  <div class="week-day-name">{{dayLables[i]}}<span *ngIf="isWindowRequired(key, i)" class="label-mark-required"></span></div>
                  <ng-container *ngFor="let childKey of ['isOpen', 'fromTime', 'toTime']">
                    <ng-container [ngSwitch]="childKey">
                      <div *ngSwitchCase="'isOpen'" [ngClass]="childKey">
                        <nz-switch [formControlName]="childKey" (ngModelChange)="onChangeToggleWindow(key, i, $event)"></nz-switch>
                        <div class="text-open">{{getTextDayOpen(key, i)}}</div>
                      </div>
                      <nz-time-picker [nzMinuteStep]="15" *ngSwitchDefault nzSize="small"
                        [ngClass]="childKey" [formControlName]="childKey" 
                        nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))">
                      </nz-time-picker>
                    </ng-container>
                  </ng-container>
                </form>
              </ng-container>

              <ng-container *ngSwitchCase="'deliveryTimes'" [formArrayName]="key">
                <form *ngFor="let control of getArrayControls(key); let i = index" nz-form [formGroupName]="i" class="time-window">
                  <div class="week-day-name">{{dayLables[i]}}<span *ngIf="isWindowRequired(key, i)" class="label-mark-required"></span></div>
                  <ng-container *ngFor="let childKey of ['isOpen', 'fromTime', 'toTime']">
                    <ng-container [ngSwitch]="childKey">
                      <div *ngSwitchCase="'isOpen'" [ngClass]="childKey">
                        <nz-switch [formControlName]="childKey" (ngModelChange)="onChangeToggleWindow(key, i, $event)"></nz-switch>
                        <div class="text-open">{{getTextDayOpen(key, i)}}</div>
                      </div>
                      <nz-time-picker [nzMinuteStep]="15" *ngSwitchDefault nzSize="small"
                        [ngClass]="childKey" [formControlName]="childKey" 
                        nzFormat="HH:mm" [nzPlaceHolder]="getPlaceHolder(fullKey(key, childKey))">
                      </nz-time-picker>
                    </ng-container>
                  </ng-container>
                </form>
              </ng-container>
              
              <input *ngSwitchDefault nz-input
                [formControlName]="key"
                [type]="getInputType(key)"
                (input)="onInputChanged($event, key)"
                (keypress)="onInputKeyPress($event, key)">
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
    <div class = "footer-btn">
      <div detail-footer-buttons labelButtonCreate="Create New"
        [isCreateNew]="isCreateNew" [isEditing]="isEditing"
        [onProgress]="onProgress" [needUpdate]="needUpdate"
        (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
      </div>
    </div>
  </form>

  <div class="group" *ngIf="model?.suggestedWarehouses?.length">
    <div class="f18m bottom10">Suggest link to Warp's location below</div>
    <div class="notice-linking bottom20 warning">Before linking, please review very carefully to see if the addresses are identical</div>

    <div *ngFor="let item of model.suggestedWarehouses" class="flex bottom20">
      <div class="right20" style="line-height: 32px;">
        <a class="link" target="_blank" [routerLink]="[routeAdminLocationList]" [queryParams]="{search: item.warpId}">{{showWarpLocation(item)}}</a>
      </div>
      <button nz-button nzType="link" (click)="onBtnLinkWarpLocation(item)" [nzLoading]="isLinking(item)">
        <i nz-icon nzType="link" nzTheme="outline"></i>
      </button>
    </div>
  </div>
</div>