<form nz-form [formGroup]="formInput">
  <div class="bottom20">
    <form nz-form *ngFor="let key of ['settings']" [formGroupName]="key">
      <ng-container *ngFor="let subKey of ['isDoNotInvoice']">
        <div nz-checkbox [formControlName]="subKey" (ngModelChange)="onChangeDoNotInvoice()">
          {{getLabel(join(key,subKey))}}
        </div>
      </ng-container>
      <ng-container *ngFor="let subKey of ['reasonDoNotInvoice']">
        <div *ngIf="getItemValue(join(key,'isDoNotInvoice'))">
          <div class="top10 bottom5 medium">{{getLabel(join(key,subKey))}}<span class="label-mark-required"></span></div>
          <input nz-input placeholder="Reason" [formControlName]="subKey" type="text"/>
        </div>
      </ng-container>
    </form>
  </div>
  <div form-input-cost-v3 #costForm [form]="formInput.get('cost')"
    [createShipmentMode]="'single'"
    [selectLocations]="selectLocations"
    [serviceOptions]="serviceOptionsForCostSection">
  </div>
  
</form>
