import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { WarehouseMap } from '../warehouse-map';
import { NzModalRef } from "ng-zorro-antd/modal";
import { Const } from "@const/Const";
import { Organization } from "@wearewarp/types/data-model";
import { MasterData } from "@services/master.data";

@Component({
  selector: '[warehouse-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../app.scss', '../../../dialogs/dialogs.scss']
})
export class WarehouseFilter extends BaseFormItem {
  @Input() txtHintSearch: String;
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  public static get declaration(): FormGroupDeclaration {return {
    warehouseType: {label: 'Warehouse type', notAcceptEmpty: true, placeHolder: 'Warehouse Type', initialValue: ''},
    searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
    orgId: {label: 'Org', notAcceptEmpty: true, placeHolder: 'Organization', initialValue: 'ORG_0' },
    state: {label: 'State'},
    city: {label: 'City'},
    zipcode: {label: 'Zip code'},
    street: {label: 'Street address'},
  }};
  protected formGroupDeclaration: FormGroupDeclaration = WarehouseFilter.declaration;

  public condition: any = {}
  public searchKeyword: string = null
  public loaded: number = 0
  public page: number = 1
  public allStates = MasterData.getStatesUS();

  get shouldCreateFormImmediately() {
    return false;
  }

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        }
        catch {
          this.condition = {};
        }
      }
      this.searchKeyword = p.search
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    });
  }

  public warehouseTypes = Object.values(Const.WarehouseTypes).map(type => {
    return { value: type, label: Const.getWarehouseTypeText(type) }
  })

  doSearch(s) {
    this.searchKeyword = s
    this.page = 1
    this.loadData()
  }

  loadData() {
    let q = {page: this.page || 1, loaded: Date.now()}
    if (this.searchKeyword) {
      q['search'] = this.searchKeyword
    }
    const f = JSON.stringify(this.condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q)
  }

  onChange(value, key) {
    this.page = 1
    if (value) this.condition[key] = value;
    else delete this.condition[key];
    
    this.loadData()
  }

  ngOnInit(): void {
    this.getOrganizations();
    super.ngOnInit();
  }

  public isLoadingOrganization: boolean = true;
  organizations: Organization[] = [];
  private getOrganizations() {
    this.isLoadingOrganization = true;
    const url = Const.APIV2(`${Const.APIURI_ORGANIZATIONS}?limit=-1`);
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoadingOrganization = false;
        this.organizations = resp.data.list_data;
        this.model = { ...this.condition, search: this.searchKeyword };
        this.createFormInput(this.model);
        this.setEnableFormGroup(true); // make sure all readOnly fields will be disabled after created.
        if (this.isAdminReadOnlyRole) {
          this.setEnableFormGroup(false);
        }
      },
      (err) => {
        this.isLoadingOrganization = false;
        this.showErr(err);
      }
    );
  }

  private modalRef: NzModalRef;

  showMap(event) {
    this.modalRef = this.modalService.create({
      nzContent: WarehouseMap,
      nzWidth: "calc(100vw - 400px)",
      nzComponentParams: {
        warehouseType: this.condition?.warehouseType || '*',
        onCloseModal: () => {
          this.modalRef?.close()
        }
      },
    })
  }
}
