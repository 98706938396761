import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { environment } from "@env/environment";
import { EnvType } from "@env/type";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: '[shipment-item]',
  templateUrl: './shipment-item.html',
  styleUrls: ['./shipment-item.scss', '../../dialogs/dialogs.scss', '../../../styles/form-v2.scss']
})
export class FormShipmentItem extends BaseFormDialog1 {
  @Input() isExfreightQuote: boolean = false;
  @Input() isMarketplaceQuote: boolean = false;
  public shipmentType;
  public itemIndex: number = -1;
  public onSave: (data, index) => void = () => {}
  public qtyUnits = Const.ShipmentQuantityUnits;
  public weightUnits = Const.WeightUnits;
  public sizeUnits = Const.SizeUnits;

  get isCreateNew(): boolean {
    return !this.model;
  }

  public static get declaration(): FormGroupDeclaration {return {
    name: {label: 'Item Name', required: false, initialValue: ''},
    itemId: {label: 'Item Id', required: false, initialValue: ''},
    qty: {label: 'Number of Units', type: 'number', required: true},
    qtyUnit: {label: '', initialValue: Const.ShipmentQuantityUnits[0], required: true},
    weightPerUnit: {label: 'Weight Per Unit', type: 'number', required: true},
    weightUnit: {label: '', initialValue: Const.WeightUnits[0], required: true},
    length: {label: 'Length', type: 'number', required: true},
    width: {label: 'Width', type: 'number', required: true},
    height: {label: 'Height', type: 'number', required: true},
    sizeUnit: {label: '', initialValue: Const.SizeUnits[0], required: true},
    isStackable: {label: 'Stackable', type: 'boolean', initialValue: false},
    isTemperatureControlled: {label: 'Temperature Controlled', type: 'boolean', initialValue: false},
    temperatureControlled: {label: '', type: 'formGroup', childItem: {
      tempMin: {label: 'Minimum Temperature (F)'},
      tempMax: {label: 'Maximum Temperature (F)'}
    }},
    isHazardous: {label: 'Hazardous Material', type: 'boolean', initialValue: false},
    hazardous: {label: '', type: 'formGroup', childItem: {
      un: {label: 'UN #', type: 'number'},
      packingGroup: {label: 'Packing Group'},
      qty: {label: 'Number of Units'},
      name: {label: 'Hazmat Name'},
      contact: {label: 'Emergency Phone', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      cfr49: {label: '49CFR'},
      hazmatClass: {label: 'Hazmat Class'},
      hazmatDesc: {label: 'Hazmat Description'},
    }},
    sum: {label: '', type: 'formGroup', childItem: {
      volume: {label: '', type: 'number'},
      weight: {label: '', type: 'number'},
      desc: {label: ''}
    }}
  }}

  // Điều kiện require thay đổi tuỳ theo giá trị lựa chọn cụ thể của từng trường
  // Nó đã được validate ở FormShipmentItem rồi, nên khi đưa về ShipmentDetail thì ko validate nữa
  public static get declarationNoRequire(): FormGroupDeclaration {
    let declaration = FormShipmentItem.declaration;
    for (let key of Object.keys(declaration)) {
      declaration[key].required = false;
    }
    return declaration;
  }

  protected formGroupDeclaration: FormGroupDeclaration = FormShipmentItem.declaration;

  public row1: FormRow = {columns: [
    {key: 'qty', span: 7, offset: 0},
    {key: 'qtyUnit', span: 5, offset: 0},
    {key: 'weightPerUnit', span: 6, offset: 1},
    {key: 'weightUnit', span: 5, offset: 0},
  ]}

  public row2: FormRow = {columns: [
    {key: 'length', span: 3, offset: 0},
    {key: 'width', span: 3, offset: 1},
    {key: 'height', span: 3, offset: 1},
    {key: 'sizeUnit', span: 3, offset: 1},
  ]}

  public row3: FormRow = {columns: [
    {key: 'tempMin', span: 7, offset: 0},
    {key: 'tempMax', span: 7, offset: 1},
  ]}

  public rowsHazardous: Array<FormRow> = [
    {columns: [
      {key: 'un', span: 7, offset: 0},
      {key: 'packingGroup', span: 7, offset: 1},
      {key: 'qty', span: 7, offset: 1},
    ]},
    {columns: [
      {key: 'name', span: 7, offset: 0},
      {key: 'contact', span: 7, offset: 1},
      {key: 'cfr49', span: 7, offset: 1},
    ]},
    {columns: [
      {key: 'hazmatClass', span: 7, offset: 0},
      {key: 'hazmatDesc', span: 15, offset: 1},
    ]},
  ]

  get strTitle() {
    return this.model ? 'Update Item' : 'Add New Item';
  }
  get strButtonSubmit() {
    return this.model ? 'Update Item' : 'Add Item';
  }

  constructor() {
    super();

    // this.test();
  }

  ngOnInit(): void {
    if (this.model) {
      this.formGroupDeclaration.qtyUnit.initialValue = this.model.qtyUnit;
    }
    if (this.formGroupDeclaration.qtyUnit.initialValue == 'Pallet' && !this.isExfreightQuote && !this.isMarketplaceQuote) {
      this.formGroupDeclaration.length.initialValue = Const.DefaultLengthPallet;
      this.formGroupDeclaration.width.initialValue = Const.DefaultWidthPallet;
    }
    if (this.shipmentType != Const.ShipmentTypes.fullTruckLoad) {
      this.qtyUnits = this.qtyUnits.filter(it => it != 'TL')
    }
    super.ngOnInit();
    this.currentValueQtyUnit = this.getItemValue('qtyUnit');
    this.disibleInputForExfreightQuote();
    this.disibleInputForMarketplaceQuote();
  }

  private test() {
    if (environment.type != EnvType.dev) {
      return;
    }
    this.formGroupDeclaration.name.initialValue = 'Soft Toys';
    this.formGroupDeclaration.qty.initialValue = 4;
    this.formGroupDeclaration.weightPerUnit.initialValue = 15;
    this.formGroupDeclaration.length.initialValue = 12;
    this.formGroupDeclaration.width.initialValue = 34;
    this.formGroupDeclaration.height.initialValue = 56;
  }

  protected afterBindModel(): void {
    this.uomChange();
  }

  getCheckValueFor(key: string): boolean {
    switch (key) {
      case 'temperatureControlled': return this.formInput.get('isTemperatureControlled').value;
      case 'hazardous': return this.formInput.get('isHazardous').value;
      default: return false;
    }
  }

  private currentValueQtyUnit;

  get isTL(): boolean {
    let value = this.getItemValue('qtyUnit');
    return value == 'TL';
  }

  private disibleInputForExfreightQuote() {
    if (this.isExfreightQuote && this.formInput?.get('length')) {
      this.formInput.get('length').disable();
      this.formInput.get('width').disable();
      this.formInput.get('height').disable();
      this.formInput.get('qty').disable();
      this.formInput.get('weightPerUnit').disable();
      this.formInput.get('sizeUnit').disable();
      this.formInput.get('qtyUnit').disable();
      this.formInput.get('weightUnit').disable();
    }
  }

  private disibleInputForMarketplaceQuote() {
    if (this.isMarketplaceQuote && this.formInput?.get('length')) {
      this.formInput.get('length').disable();
      this.formInput.get('width').disable();
      this.formInput.get('height').disable();
      this.formInput.get('qty').disable();
      this.formInput.get('weightPerUnit').disable();
      this.formInput.get('sizeUnit').disable();
      this.formInput.get('qtyUnit').disable();
      this.formInput.get('weightUnit').disable();
      this.formInput.get('isTemperatureControlled').disable();
      this.formInput.get('temperatureControlled.tempMin').disable();
      this.formInput.get('temperatureControlled.tempMax').disable();
    }
  }

  private uomChange() {
    let value = this.getItemValue('qtyUnit');
    // Trường hợp tạo shipment từ exfreight không thay đổi length, width
    if (!this.isExfreightQuote && !this.isMarketplaceQuote) {
      if (value == 'Pallet') {
        this.setItemValue('length', Const.DefaultLengthPallet);
        this.setItemValue('width', Const.DefaultWidthPallet);
      } else {
        if (this.currentValueQtyUnit == 'Pallet') {
          this.setItemValue('length', null);
          this.setItemValue('width', null);
        }
      }
    }

    let isTL = this.isTL;
    for (let key of ['length', 'width', 'height']) {
      this.formGroupDeclaration[key].required = !isTL;
      let formControl = this.formInput.get(key);
      if (this.formGroupDeclaration[key].required) {
        formControl.addValidators(Validators.required);
      } else {
        formControl.removeValidators(Validators.required);
      }
      formControl.updateValueAndValidity();
    }
    this.currentValueQtyUnit = value;
  }

  shouldShowField(key: string): boolean {
    switch (key) {
      case 'isTemperatureControlled': 
        if (this.isExfreightQuote) {
          return false
        }
        return true;
      case 'length':
      case 'width':
      case 'height':
      case 'sizeUnit':
      case 'isStackable':
        return !this.isTL;
      default:
        return true;
    }
  }

  onModelChange(key: string) {
    switch (key) {
      case 'qtyUnit':
        this.uomChange();
        return;
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'qty':
      case 'weightPerUnit':
      case 'length':
      case 'width':
      case 'height':
      case 'un':
      case 'contact':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'tempMin':
      case 'tempMax':
        return InputHelper.handleInputKeyPressTemperature(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'qty':
      case 'weightPerUnit':
      case 'length':
      case 'width':
      case 'height':
      case 'un':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'tempMin':
      case 'tempMax':
        return InputHelper.handleInputChangeTemperature(event, <FormControl>this.formInput.get(key));
      case 'contact':
        return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onBtnSave() {
    let data: any = this.getFormData_JSON(true);
    data.sum = this.getItemSumary(data);
    this.onSave(data, this.itemIndex);
    this.closeDialog();
  }

  public onBtnCancel() {
    let msg = this.canQuit()
    if (msg) {
      this.confirmYesNo('Do you really want to close?', () => this.closeDialog());
    } else {
      this.closeDialog();
    }
  }
}
