<!-- <div *ngIf="shipment?.metadata?.client?.name" class="bottom20">
    <span class="label">Customer: </span>
    <span class="title">{{ shipment.metadata?.client?.name }}</span>
</div> -->
<div class="svg-container">
    <div [id]="refId"></div>
    <div *ngIf="hoverData" class="hover" [style.left]="hoverData.coords[0] + 'px'" [style.top]="hoverData.coords[1] + 'px'">
        <div *ngIf="hoverData?.location">
            <div warehouse-popup-content [location]="hoverData?.location"></div>
        </div>
        <div *ngIf="hoverData?.shipment">
            <div shipment-popup-content [shipment]="hoverData?.shipment"></div>
        </div>
    </div>

    <div *ngIf="showPopUp" class="pop-up" [style.left]="popUpLocation[0] + 'px'" [style.top]="popUpLocation[1] + 'px'">
        <div *ngIf="popupData?.location">
            <div warehouse-popup-content [shipments]="children" [items]="items" [location]="popupData?.location" [contextMenu]="true" [viewOnly]="popupData?.viewOnly"
            (onSelectMenu)="onSelectLocationMenu($event)"
            ></div>
        </div>
        <div *ngIf="popupData?.shipment">
            <div shipment-popup-content [shipment]="popupData?.shipment" [contextMenu]="true" [viewOnly]="popupData?.viewOnly" (onSelectMenu)="onSelectShipmentMenu($event)"></div>
        </div>
        <div class="close-button" (click)="onClosePopup()"><i nz-icon nzType="close" nzTheme="outline"></i></div>
    </div>
</div>
<div *ngIf="presentedShipment" class="shipment-detail-container">
    <div class="controls flex bottom10">
        <button [disabled]="duplicatingLeg" class="right10" nz-button (click)="onCloseShipment()">
            <i nz-icon nzType="close" nzTheme="outline"></i>Close
        </button>
        <button *ngIf="presentedShipment.parentId" [disabled]="duplicatingLeg" class="right10" nzType="primary" nz-button (click)="duplicateLeg(presentedShipment)">
            <span *ngIf="!duplicatingLeg" nz-icon nzType="copy" nzTheme="outline"></span>
            <i *ngIf="duplicatingLeg" nz-icon nzType="loading" nzTheme="outline"></i>
            Duplicate Leg
        </button>
        <button [disabled]="duplicatingLeg" class="right10" *ngIf="canDelete(presentedShipment)" nz-button nzDanger (click)="confirmRemovingLeg()">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
            Remove Leg
        </button>
        <!-- <button [disabled]="duplicatingLeg" class="right20" nz-button (click)="toggleEdit()">
            <i nz-icon nzType="close" nzTheme="outline"></i>
            <span *ngIf="!editMode">Edit</span>
            <span *ngIf="editMode">Done Editing</span>
        </button> -->
        <button *ngIf="canCreateSingleRoute(presentedShipment)" [disabled]="duplicatingLeg" class="right10" nz-button (click)="onBtnManualRouteShipment()">
            <span nz-icon nzType="node-index" nzTheme="outline"></span>
            Create Single Route
        </button>
        <!-- <a class="shipment-header"  target="_blank" class="right10" [routerLink]="[routeAdminDispatchList, presentedShipment.lastJobId]">
            <span nz-icon nzType="node-index" nzTheme="outline"></span> View Route
        </a> -->
        <div class="right10" *ngIf="presentedShipment.lastJobId">
            <a class="shipment-header" target="_blank" [routerLink]="[routeAdminDispatchList, presentedShipment.lastJobId]">
                <span nz-icon nzType="node-index" nzTheme="outline"></span> View Route
            </a>
        </div>
        <a class="shipment-header right10" *ngIf="popupData?.shipment?.id"  target="_blank" [routerLink]="[routeAdminShipmentList, 'history', popupData?.shipment?.id]" [queryParams]="{entity: 'shipments'}">
            <span nz-icon nzType="history" nzTheme="outline"></span> View History
        </a>
        <span class="shipment-header right10 clickable" [style.color]="presentedShipment.review?.needReview ? 'red' : '#444'" *ngIf="presentedShipment?.id" (click)="updateShipmentReviewStatus(presentedShipment)">
            <span nz-icon nzType="file-done" nzTheme="outline"></span> Review
        </span>
        <span *ngIf="showEvents" class="shipment-header right10 clickable" (click)="showShipmentHistory(presentedShipment)">
            <span nz-icon nzType="compass" nzTheme="outline"></span>
        </span>
    </div>
    <div shipment-structure-job-detail [id]="presentedShipment.lastJobId" *ngIf="presentedShipment.lastJobId"></div> 
    <div shipment-structure-detail (deliveryInfoUpdated)="onDeliveryUpdated()" [editMode]="editMode" [shipment]="presentedShipment" [clientMeatadata]="clientMeatadata" [compact]="compact" [isShowActualTime]="isShowActualTime" [items]="items"></div>

    <div *ngIf="confirmingRemoveLeg" class="confirm-modal">
        <div class="modal-controls">
            <div class="bottom20">Please confirm you want to remove this Leg. This action is irreversible!!</div>
            <button [disabled]="removingLeg" class="right10" *ngIf="canDelete(presentedShipment)" nz-button (click)="cancelRemovingLeg()">
                <i nz-icon nzType="close" nzTheme="outline"></i>
                Cancel
            </button>
            <button [disabled]="removingLeg" *ngIf="canDelete(presentedShipment)" nz-button nzDanger (click)="removeLeg(presentedShipment)">
                <i *ngIf="!removingLeg" nz-icon nzType="close" nzTheme="outline"></i>
                <i *ngIf="removingLeg" nz-icon nzType="loading" nzTheme="outline"></i>
                Delete
            </button>
        </div>
    </div>
</div>
<div *ngIf="newOutboundData" class="shipment-detail-container">
    <div shipment-new-leg-form [from]="newOutboundData.from" (onCancel)="onCancelCreateNewLeg()" [parent]="shipment" (onSave)="onNewLegCreated($event)" [items]="items" [siblings]="children"></div>
</div>
