import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RouterModule } from '@angular/router';
import { ShipmentStructure } from './index';
import { ShipmentPopupContent } from './popup_shipment';
import { WarehousePopupContent } from './popup_warehouse';
import { ShipmentStructureDetail } from './shipment_detail';
import { ShipmentNewLegForm } from './new_leg_form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShortItemInfo } from './short_item_info';
import { ShipmentRevenue } from './shipment_revenue';
import { EditShipmentEntryModule } from '@app/admin/shipment-entry/components/edit-shipment/module';
// import { DeliveryPlanningModule } from '@app/admin/planning/module';
import { UiCommonModule } from '@app/admin/components/common/module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ShipmentRevenueEdit } from './shipment_revenue_edit';
import { ShipmentRevenueEditOption } from './shipment_revenue_edit_option';
import { JobDetail } from './job_detail';
import { ThirdPartyInfoModule } from '../third-party-info/module';
import { WarehouseTaskSettingModule } from '../warehouse-task-setting/module';
import { InitReturnLeg } from './init-return-leg/init-return-leg';
import { DetailModule } from '@app/admin/base/detail.module';
import { ReturnLocation } from './init-return-leg/return-location';
import { FormDeliveryInfoModule } from '@app/admin/base/delivery-info/module';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ShipmentPhoto } from './shipment-photo';
import { ModuleShortLocationInfo } from './short_location/module';
import { ModuleShortContactInfo } from './short_contact/module';
import { ModuleShipmentDeliveryInfo } from './delivery_info/module';
import { EditShipmentItemsDialog } from './edit_shipment_items_dialog';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NzButtonModule,
        NzGridModule,
        NzIconModule,
        NzAutocompleteModule,
        NzInputModule,
        FormsModule,
        NzCheckboxModule,
        EditShipmentEntryModule,
        NzSwitchModule,
        NzRadioModule,
        // DeliveryPlanningModule,
        UiCommonModule, 
        NzModalModule,
        NzFormModule,
        NzSelectModule,
        NzDividerModule,
        NzPopoverModule,
        DetailModule,
        FormDeliveryInfoModule,
        ThirdPartyInfoModule,
        ModuleShipmentDeliveryInfo,
        ModuleShortContactInfo,
        ModuleShortLocationInfo,
        WarehouseTaskSettingModule,
    ],
    declarations: [
        ShipmentStructure,
        ShipmentPopupContent,
        WarehousePopupContent,
        ShipmentStructureDetail,
        ShipmentNewLegForm,
        ShortItemInfo,
        ShipmentRevenue,
        ShipmentRevenueEdit,
        ShipmentRevenueEditOption,
        JobDetail,
        InitReturnLeg,
        ReturnLocation,
        ShipmentPhoto,
        EditShipmentItemsDialog,
    ],
    exports: [
        ShipmentStructure,
        ShipmentRevenue
    ],
    providers: [
    ]
})

export class ShipmentStructureModule {}
