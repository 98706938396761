import { Component, Input, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { ValidateOptions } from "@app/admin/base/form-base";
import { ValidationErrors } from "@angular/forms/forms";
import { BaseDialog } from "@dialogs/base-dlg";
import { FormOrderItems } from "../../forms/order-items";
import { FormDataOrderItems } from "@app/admin/shipment-entry/interface";

@Component({
  selector: 'edit-shipment-entry-items',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryItems extends BaseDialog {

  @Input() shipmentWarpId;
  @Input() onSave: (data: FormDataOrderItems) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  private _modelItems;
  @Input() get modelItems() { return this._modelItems }
  set modelItems(value) {
    const arr = value ? [...value] : []
    this._modelItems = { items:  arr  };
  }
  public onProgress;

  @ViewChild('formItems') formItems: FormOrderItems;

  ngOnInit(): void {
    super.ngOnInit();
  }

  public get needUpdate() {
    return this.formItems?.canUpdate;
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    return this.formItems?.validate(options);
  }

  onBtnSave() {
    if (!this.needUpdate) return
    const itemData: FormDataOrderItems = this.formItems?.getFormData();
    this.onSave(itemData).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}
