<div *ngIf="isLoadingOrganization" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<form [formGroup]="formInput" *ngIf="!isLoadingOrganization" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap;">
    <div class="form-item">
      <div class="form-label-v2">Keyword</div>
      <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="txtHintSearch" [searchKeyword]="searchKeyword" style="width: 100%;"></search-box>
    </div>

    <ng-container *ngFor="let key of ['street', 'city', 'state', 'zipcode', 'warehouseType', 'orgId']">
      <div class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container [ngSwitch]="key">
          <nz-select 
            class="searchbox" *ngSwitchCase="'warehouseType'" style="width: 120px;"
            [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" 
            (ngModelChange)="onChange($event, key)" >
            <nz-option nzLabel="All" nzValue=""></nz-option>
            <nz-option *ngFor="let item of warehouseTypes" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
          </nz-select>

          <nz-select 
            class="searchbox" *ngSwitchCase="'orgId'" style="width: 120px;"
            [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" 
            (ngModelChange)="onChange($event, key)" [nzLoading]="isLoadingOrganization" >
            <nz-option *ngFor="let item of organizations" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
          </nz-select>

          <div *ngSwitchCase="'state'">
            <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear
              [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
              nzDropdownClassName="location-filter-state"
              (ngModelChange)="onChange($event, key)">
              <nz-option *ngFor="let state of allStates" [nzLabel]="getStateDesc(state)" [nzValue]="state.code"></nz-option>
            </nz-select>
          </div>

          <input *ngSwitchDefault nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" (ngModelChange)="onChange($event, key)"/>
        </ng-container>
      </div>
    </ng-container>

    <div class="form-item">
      <div class="form-label-v2">&nbsp;</div>
      <div style="display: flex; max-height: 32px; min-height: 32px; align-items: center;">
        <span nz-icon nzType="global" nzTheme="outline" (click)="showMap($event)"></span>
      </div>
    </div>
  </div>
</form>