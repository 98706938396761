import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormDataShipmentItem, FormDataShipmentItemBarcodes, FormDataShipmentItemServices } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { BaseForm, ValidateOptions } from '@app/admin/base/form-base';
import { Utils } from '@services/utils';
import { FormShipmentItemServices } from '../services';
import { Observable, of } from 'rxjs';
import { ApiService } from '@services/api.service';
import { FormItemBarcodes } from '@app/admin/shipment-entry/components/forms/order-items/barcode';
import { BizUtil } from '@services/biz';

export interface LayoutTitle {
  title: string, key?: string, isRequired?: boolean
}

export interface LayoutTitleGroup extends LayoutTitle {
  childs: Array<LayoutTitle>
}

@Component({
  selector: '[form-order-item]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../style.scss']
})
export class FormOrderItem extends BaseForm<FormDataShipmentItem> {
  @ViewChild('formHtml') formHtml: ElementRef<HTMLFormElement>;
  @ViewChildren('formChild') formChildren: QueryList<FormOrderItem>;

  @Input() level: number = 0;
  @Input() index: number = 0;
  @Output() deleteMe = new EventEmitter<void>();
  @Output() addMoreSibling = new EventEmitter<void>();

  get isRootItem() { return this.level == 0 }
  get shouldShowLabel() { return this.index == 0 }
  private deleteIds: Array<string> = [];

  public static get defaultData(): FormDataShipmentItem {
    return {
      id: Utils.generateULID(), // tạo sẵn id ngay từ đầu
      qtyUnit: 'Pallet',
      name: null,
      qty: 1,
      weightPerUnit: null,
      weightUnit: 'lbs',
      length: null,
      width: null,
      height: null,
      sizeUnit: 'IN',
      isStackable: false,
      isTemperatureControlled: false,
      isHazardous: false,
      hazardous: {
        un: null,
        packingGroup: null,
        qty: null,
        name: null,
        contact: null,
        cfr49: null,
        hazmatClass: null,
        hazmatDesc: null,
      },
      temperatureControlled: {
        tempMin: null,
        tempMax: null,
      },
      sum: {
        volume: null,
        weight: null,
        desc: null,
      },
      uid: null,
      barcodes: [],
      children: [],
      shipRecInfo: {
        deviceId: null,
      }
    };
  }

  public static get declaration(): FormGroupDeclaration {
    return {
      id: {label: '', submitReadOnly: true},
      warpId: {label: '', submitReadOnly: true, type: 'number', readOnly: true},
      name: {label: 'Commodity/Name', required: false, initialValue: '', placeHolder: 'Commodity'},
      itemId: {label: 'Item Id', required: false, initialValue: '', placeHolder: 'Item ID'},
      barcodes: {label: 'Barcode Number', type: 'array', initialValue: [], childItem: {label: '', notAcceptEmpty: true} , placeHolder: 'Barcode'},
      qty: {label: 'Qty', type: 'number', required: true, initialValue: 1, placeHolder: 'Qty'},
      qtyUnit: {label: 'Type', initialValue: Const.ShipmentQuantityUnits[0], required: true},
      length: {label: 'Length', type: 'number', required: true, placeHolder: 'L'},
      width: {label: 'Width', type: 'number', required: true, placeHolder: 'W'},
      height: {label: 'Height', type: 'number', required: true, placeHolder: 'H'},
      weightPerUnit: {label: 'Weight Per Unit', type: 'number', required: true, placeHolder: 'Weight'},
      weight: {label: 'Weight', type: 'number', required: true, placeHolder: 'Weight'},
      weightUnit: {label: '', initialValue: Const.WeightUnits[0], required: true, placeHolder: 'Unit'},
      sizeUnit: {label: '', initialValue: Const.SizeUnits[0], required: true, placeHolder: 'Unit'},
      isStackable: {label: 'Stackable', type: 'boolean', initialValue: false},
      isTemperatureControlled: {label: 'Temperature Controlled', type: 'boolean', initialValue: false},
      temperatureControlled: {label: '', type: 'formGroup', childItem: {
        tempMin: {label: 'Minimum Temperature (F)'},
        tempMax: {label: 'Maximum Temperature (F)'}
      }},
      isHazardous: {label: 'Hazardous Material', type: 'boolean', initialValue: false},
      hazardous: {label: '', type: 'formGroup', childItem: {
        un: {label: 'UN #', type: 'number'},
        packingGroup: {label: 'Packing Group'},
        qty: {label: 'Number of Units'},
        name: {label: 'Hazmat Name'},
        contact: {label: 'Emergency Phone', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
        cfr49: {label: '49CFR'},
        hazmatClass: {label: 'Hazmat Class'},
        hazmatDesc: {label: 'Hazmat Description'},
      }},
      shipRecInfo: { label: '', type: 'formGroup', childItem: {
        deviceId: {label: 'Tracking Device ID'},
      }},
      itemValue: {label: 'Value', placeHolder: 'Value', type: 'number', required: false, getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney2},
      sum: {label: '', type: 'formGroup', childItem: {
        volume: {label: '', type: 'number'},
        weight: {label: '', type: 'number'},
        desc: {label: ''}
      }}
    };
  }

  protected formGroupDeclaration: FormGroupDeclaration = FormOrderItem.declaration;

  titleGroups: LayoutTitleGroup[] = [
    {title: '', childs: [
      {title: 'Qty', key: 'qty', isRequired: true},
      {title: 'Type', key: 'qtyUnit', isRequired: true},
    ]},
    {title: 'Commodity/Name', childs: [
      {title: '', key: 'name'},
    ]},
    {title: 'Dimensions', key: 'size', childs: [
      {title: '', key: 'length'},
      {title: '', key: 'width'},
      {title: '', key: 'height'},
      {title: '', key: 'sizeUnit'}
    ], isRequired: true},
    {title: 'Weight', key: 'weight', childs: [
      {title: '', key: 'weightPerUnit'},
      {title: '', key: 'weightUnit'},
    ], isRequired: true},
    {title: 'Total weight', childs: [
      {title: 'Total weight', key: 'weight'}
    ]},
    {title: 'Item ID', childs: [
      {title: '', key: 'itemId'},
    ]},
    {title: 'Barcodes (multiple)', childs: [
      {title: '', key: 'barcodes'},
    ]},
    {title: 'Value', childs: [
      {title: '', key: 'itemValue'},
    ]},
    {title: 'Service', childs: [
      {title: '', key: 'button-service'},
    ]},
  ];

  get hasChildren() {
    return this.children.length > 0;
  }

  get shouldShowButtonDelete() {
    return true;
  }

  children: Array<FormDataShipmentItem> = [];

  public qtyUnits = Const.ShipmentQuantityUnits;
  public weightUnits = Const.WeightUnits;
  public sizeUnits = Const.SizeUnits;

  get countBarcode() {
    return this.barcodes?.length ?? 0;
  }

  get barcodesLabel() {
    let count = this.countBarcode;
    if (count == 0) {
      return 'N/A';
    } else if (count == 1) {
      return `1 barcode`;
    } else {
      return `${count} barcodes`;
    }
  }

  get formElement(): HTMLFormElement {
    return this.formHtml.nativeElement;
  }

  itemServiceDesc = '';

  constructor() {
    super();
  }

  public isFormDataChanged(): boolean {
    if (super.isFormDataChanged()) {
      return true;
    }
    for (let i = 0; i < this.formChildren.length; i++) {
      if (this.formChildren.get(i).isFormDataChanged()) {
        return true;
      }
    }
    return false;
  }

  public validate(options?: ValidateOptions): ValidationErrors | { error: ValidationErrors; message: string; } {
    let err = super.validate(options);
    if (err) {
      return err;
    }
    for (let i = 0; i < this.formChildren.length; i++) {
      err = this.formChildren.get(i).validate(options);
      if (err) {
        return err;
      }
    }
    return null;
  }

  ngOnInit(): void {
    if (!this.canEditQty()) {
      this.formGroupDeclaration.qty.readOnly = true;
      this.formGroupDeclaration.qty.submitReadOnly = true;
    }
    this.setInitialChildren();
    super.ngOnInit();
    this.onModelChange('qtyUnit');
    this.onModelChange('weightPerUnit');
  }

  private setInitialChildren() {
    this.children = this.model?.children ?? [];
  }

  protected beforeBindModel(model: FormDataShipmentItem): FormDataShipmentItem {
    if (model && !model.id) {
      // Chỗ này cần đảm bảo server trả về phải có sẵn id rồi, nếu ko thì id sẽ bị override khi save
      throw Error(`FormDataShipmentItem model must have id before`);
    }
    this.updateServiceDesc();
    return super.beforeBindModel(model);
  }

  public getFormData(): FormDataShipmentItem {
    let data = super.getFormData();
    if (!data.id) {
      data.id = Utils.generateULID();
    }
    let children = [];
    for (let i = 0; i < this.formChildren.length; i++) {
      children.push(this.formChildren.get(i).getFormData());
    }
    data.children = children;
    return data;
  }

  getDeleteItems(): Array<string> {
    let deleteIds = [];
    for (let i = 0; i < this.formChildren.length; i++) {
      deleteIds = [...deleteIds, ...this.formChildren.get(i).getDeleteItems()];
    }
    return [...this.deleteIds, ...deleteIds];
  }

  onBtnItemServices() {
    let qtyUnit = this.getItemValue('qtyUnit');
    let model: FormDataShipmentItemServices = {
      isStackable: this.getItemValue('isStackable'),
      isTemperatureControlled: this.getItemValue('isTemperatureControlled'),
      isHazardous: this.getItemValue('isHazardous'),
      hazardous: this.getItemValue('hazardous'),
      temperatureControlled: this.getItemValue('temperatureControlled'),
      shipRecInfo: this.getItemValue('shipRecInfo'),
    };
    FormShipmentItemServices.open({qtyUnit, model, fnSave: this.saveItemService.bind(this)});
  }

  onBtnBarcodes() {
    let model: FormDataShipmentItemBarcodes = {
      barcodes: this.getItemValue('barcodes')
    }
    FormItemBarcodes.open({model, fnSave: this.saveItemBarcodes.bind(this)})
  }

  private get shouldUpdateBydApi(): boolean {
    // Dữ liệu đã có trong dababase rồi => cần gọi API để update luôn
    return this.model?.warpId != null;
  }

  private saveItemService(data: FormDataShipmentItemServices): Observable<any> {
    this.setItemValue('isStackable', data.isStackable);
    this.setItemValue('isTemperatureControlled', data.isTemperatureControlled);
    this.setItemValue('isHazardous', data.isHazardous);
    this.setItemValue('hazardous', data.hazardous);
    this.setItemValue('temperatureControlled', data.temperatureControlled);
    this.setItemValue('shipRecInfo', data.shipRecInfo);
    if (this.shouldUpdateBydApi) {
      return this.update();
    } else {
      // Đây là trường hợp tạo mới => chỉ cần lưu vào form là đủ
      return of(null);
    }
  }

  private saveItemBarcodes(data: FormDataShipmentItemBarcodes): Observable<any> {
    this.setItemValue('barcodes', data.barcodes);
    if (this.shouldUpdateBydApi) {
      return this.update();
    } else {
      return of(null);
    }
  }

  private updateServiceDesc() {
    this.itemServiceDesc = this.model ? BizUtil.getItemServiceDesc(<any>this.model) : '';
  }

  private update() {
    const id = this.model.id;
    const data = this.getFormData();
    const url = Const.APIV2(`${Const.APIURI_SHIPMENT_ITEMS}/${id}`);
    return new Observable(observer => ApiService.instance.PUT(url, data).subscribe(
      resp => {
        // Chỉ lấy các trường dữ liệu của bản thân item, không liên quan parent/child child
        const {_id, id, warpId, parentId, childIds, ...updatedData} = resp.data;
        this.model = Object.assign(this.model, updatedData);
        this.updateServiceDesc();
        observer.next(resp);
      }, err => {
        observer.error(err)
      }
    ));
  }

  canEditQty(): boolean {
    return this.level == 0; // ko cho edit qty của bọn children
  }

  canAddChild(): boolean {
    return true;
  }

  addChild() {
    if (!this.canAddChild()) {
      return;
    }
    this.children.push(FormOrderItem.defaultData);
  }

  canDelChild(index: number): boolean {
    return true;
  }

  deleteChild(index: number) {
    if (!this.canDelChild(index)) {
      return;
    }
    let item = this.children[index];
    if (item.warpId) {
      // Nếu có warpId tức là đã được insert vào database rồi, cần gửi lên server để delete
      this.deleteIds.push(item.id);
    }
    this.children.splice(index, 1);
  }

  messageDeleteChild(index: number): string {
    if (this.children[index].children?.length > 0) {
      return `Remove this item and all its children?`;
    } else {
      return `Remove this item?`
    }
  }

  onBtnAdd() {
    this.addMoreSibling.emit();
  }

  onModelChange(key) {
    switch (key) {
      case 'qtyUnit':
        let value = this.getItemValue(key);
        let groupWeight = this.titleGroups.find(it => it.key == 'weight');
        groupWeight.title = `Weight Per ${value}`;
        if (value == Const.ShipmentItemUnit.tl) {
          // Nếu số lượng item tính bằng cả xe (truck load) thì không bắt buộc nhập kích thước nữa
          for (let key of ['length', 'width', 'height', 'sizeUnit']) {
            this.formGroupDeclaration[key].required = false;
            if (key != 'sizeUnit') {
              this.formGroupDeclaration[key].readOnly = true;
            }
            let formControl = this.formInput.get(key);
            formControl.disable();
            formControl.clearValidators();
            formControl.updateValueAndValidity();
          }
        } else {
          for (let key of ['length', 'width', 'height', 'sizeUnit']) {
            this.formGroupDeclaration[key].required = true;
            this.formGroupDeclaration[key].readOnly = false;
            let formControl = this.formInput.get(key);
            formControl.enable();
            formControl.setValidators(Validators.required);
            formControl.updateValueAndValidity();
          }
        }
        break;
      case 'weightPerUnit':
        this.updateWeight();
        break;
    }
  }

  get barcodes() {
    return this.getItemValue('barcodes')
  }

  private updateWeight() {
    console.log('updateWeight');
    const weightPerUnit = this.getItemValue('weightPerUnit');
    const qty = this.getItemValue('qty') ?? 1;
    const weight = weightPerUnit ? Utils.roundNumber(weightPerUnit * qty, 0) : null;
    let formControl = this.formInput.get('weight');
    formControl.enable();
    formControl.setValidators(Validators.required);
    formControl.setValue(weight);
    formControl.updateValueAndValidity();
  }
  private updateWeightPerUnit() {
    console.log('updateWeightPerUnit');
    const weight = this.getItemValue('weight');
    const qty = this.getItemValue('qty') ?? 1;
    const weightPerUnit = weight ? Utils.roundNumber(weight / qty, 3) : null;
    let formControl = this.formInput.get('weightPerUnit');
    formControl.enable();
    formControl.setValidators(Validators.required);
    formControl.setValue(weightPerUnit);
    formControl.updateValueAndValidity();
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'qty':
      case 'weightPerUnit':
      case 'length':
      case 'width':
      case 'height':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'itemValue':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'qty':
      case 'weightPerUnit':
        this.updateWeight();
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'weight':
        this.updateWeightPerUnit();
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'length':
      case 'width':
      case 'height':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      case 'itemValue':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      default:
        return super.onInputChanged(event, key);
    }
  }

}
