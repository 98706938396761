import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { Shipment, ShipmentItem } from "@wearewarp/types/data-model";
import { DataorchService } from "@services/dataorch.service";
import { map } from "rxjs/operators";

@Component({
  selector: 'edit-shipment-entry-items',
  templateUrl: './edit_shipment_items_dialog.html',
  styleUrls: ['./styles.scss']
})
export class EditShipmentItemsDialog extends BaseDialog {
    _shipment: Shipment
    _items: any[]
    dataorch: DataorchService

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    @Input() set items(v: ShipmentItem[]) {
        this._items = v.map(it => Object.assign({
            item: it,
            selected: (this._shipment.itemIds?? []).includes(it.id)
        }))
    }

    @Input() set shipment(v: Shipment) {
        this._shipment = v
        if (this._items) {
            for (let item of this._items) {
                item.selected = (this._shipment.itemIds?? []).includes(item.id)
            }
        }
    }

    @Input() onUpdated: () => void

    public inProgress;

    ngOnInit(): void {
        super.ngOnInit();
    }

    _dirty: boolean = false
    public onSelectionChange() {
        const itemIds = new Set(this._items.filter(it => it.selected).map(it => it.item.id))
        if (itemIds.size != this._shipment.itemIds?.length) {
            this._dirty = true
            return
        }
        if (this._shipment.itemIds.filter(it => itemIds.has(it)).length) {
            this._dirty = true
        } else {
            this._dirty = false
        }
    }

    onBtnSave() {
        if (!this._dirty) return
        const itemIds = this._items.filter(it => it.selected).map(it => it.item.id)
        
        this.save(itemIds).subscribe({
            next: (s) => {
                this._shipment.itemIds = s.itemIds
            },
            error: (e) => {
                this.inProgress = false
                this.showErr(e)
            }
        })
        this.onUpdated && this.onUpdated()
        this.closeDialog()
    }

    onBtnCancel() {
        this.closeDialog()
    }

    save(itemIds: string[]) {
        this.inProgress = true
        return this.dataorch.updateShipmentItemIds(this._shipment.id, itemIds).pipe(
            map((s) => {
                this.inProgress = false
                return s
            })
        )
    }

}
