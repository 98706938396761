<div class="bottom10">Select items for Delivery Leg {{ _shipment.code }}</div>
<div class="bottom20">
    <div *ngFor="let item of _items">
        <label class="right5" nz-checkbox [(ngModel)]="item.selected" (ngModelChange)="onSelectionChange()"></label> <span short-item-info [item]="item.item"></span>
    </div>
</div>
<div>
    <button [nzLoading]="inProgress" [disabled]="!_dirty" class="right10" nz-button nzType="primary" (click)="onBtnSave()">Save</button>
    <button nz-button (click)="onBtnCancel()">Cancel</button>
</div>
