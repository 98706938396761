import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";
import { ShipmentPhoto } from "./shipment-photo";


@Component({
    selector: '[warehouse-popup-content]',
    templateUrl: './popup_warehouse.html',
    styleUrls: ['./styles.scss']
})
export class WarehousePopupContent extends BaseComponent {
    dataorch: DataorchService

    _location: any = null
    @Input() set location(v) {
        this._location = v
        this.loadWarehouseTasks()
    }
    get location() {
        return this._location
    }

    @Input() contextMenu: boolean = false
    @Input() viewOnly: boolean = false
    @Output() onSelectMenu: EventEmitter<any> = new EventEmitter<any>();
    @Input() items: any[] = []

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    _shipments: any[] = []
    @Input() set shipments(v) {
        this._shipments = v
        this.loadWarehouseTasks()
    }

    loadWarehouseTasks() {
        if (!this._shipments?.length || !this.location?.warehouseId) {
            this.warehouseTasks = []
            return
        }
        const shipmentIds = this._shipments.map(it => it.id).filter(it => it)
        if (!shipmentIds.length) {
            this.warehouseTasks = []
        } else {
            this.dataorch.listWarehouseTasks(this.location.warehouseId, shipmentIds).subscribe((res) => {
                this.warehouseTasks = res
            })
        }
    }

    _warehouseTasks: any[] = []
    _uploadedPhotos: any[] = []
    set warehouseTasks(v) {
        this._warehouseTasks = v
        const uploadedPhotoTasks = v.filter(it => it.type == 'uploadProductPhoto')
        const uploadedPhotos = []
        const itemMap = {}
        if (this.items) {
            for (let item of this.items) {
                itemMap[item.id] = item
                if (item.quantityItems) {
                    for (let q of item.quantityItems) {
                        itemMap[q.id] = q
                    }
                }
            }
        }
        for (let u of uploadedPhotoTasks) {
            if (u.data?.images)
            for (let img of u.data.images) {
                uploadedPhotos.push(Object.assign({
                    itemId: u.itemId,
                    url: img.fileUrl,
                    id: img.uploadId,
                    insert: img.insert,
                    item: itemMap[u.itemId]
                }))
            }
        }
        this._uploadedPhotos = _.sortBy(uploadedPhotos, 'itemId')
    }

    onClick(action) {
        this.onSelectMenu?.emit(action)
    }

    async onDonwloadWarehouseDocuments() {

        const ids = this._shipments.map(it => it.id).filter(it => it)
        if (!ids.length) {
            return
        }
        const inboundJobs = await this.dataorch.listWarehouseJobs(this.location.warehouseId, ids).toPromise()
        if (!inboundJobs?.length) return
        const warehouseJobIds = _.uniq(inboundJobs.filter(it => it.level == 'shipment').map(it => it.id))
        this.api.POST(Const.APIURI_WAREHOUSE_DOWNLOAD_BOL, { warehouseJobIds }).subscribe(res => {
            this.downloadAttachedFile(res.data);
        }, err => {
            this.showErr(err)
        })
    }

    onViewPhoto() {
        this.modalService.create({
            nzContent: ShipmentPhoto,
            nzWidth: '800px',
            nzStyle: {
            },
            nzComponentParams: {
                photos: this._uploadedPhotos
            }
        })
    }

}