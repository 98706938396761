import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { CustomerWarehouseDetail } from '.';
import { DetailModule } from '@app/admin/base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    DetailModule,
    SelectBySearchingModule,
    NzSwitchModule,
    NzTimePickerModule,
  ],
  declarations: [CustomerWarehouseDetail],
  exports: [CustomerWarehouseDetail]
})
export class ModuleCustomerWarehouseDetail {
}