import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { environment } from "@env/environment";

@Component({
    selector: '[third-party-info]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})  
export class ThirdPartyInfo extends BaseComponent {
    @Input() id: string = null
    loading: boolean = false
    source: string = null

    @Output() onUpdate: EventEmitter<any>  = new EventEmitter()

    info: any = null

    ngOnInit(): void {
        // load external info
        this.source = this.id ? this.id.split('_')[0] : null
        this.loadExernalInfo()
    }

    setInfo(data) {
        this.info = data
    }

    loadExernalInfo() {
        const url = environment.backendUrl + `/v2/pricing/quote/booked/${this.id}`
        this.loading = true
        this.api.GET(url).subscribe((res) => {
            this.setInfo(res)
            this.loading = false
        })
    }
}