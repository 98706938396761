import { Component, OnInit, ViewChild } from '@angular/core';
import { FormDataShipmentEntryDraftSingle, FormDataSingleShipmentRevenue } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { ComponentForm } from '../../../interface';
import { BaseForm } from '@app/admin/base/form-base';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import { FormSingleShipmentRevenue } from '../../forms/single-shipment-revenue';

@Component({
  selector: '[shipment-entry-single-revenue]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentEntrySingleRevenue extends ShipmentEntryTabContent implements ComponentForm<FormDataSingleShipmentRevenue>, OnInit {
  @ViewChild('formComp') formComp: FormSingleShipmentRevenue;

  getForm(): BaseForm<FormDataSingleShipmentRevenue> {
    return this.formComp;
  }

  onTabActive(active: boolean): void {
    if (active) {
      this.updateSelectLocations();
      this.formModel = {
        settings: {
          isDoNotInvoice: this.modelDraft?.settings?.isDoNotInvoice,
          reasonDoNotInvoice: this.modelDraft?.settings?.reasonDoNotInvoice
        },
        cost: this.modelDraft?.cost
      }
    }
  }

  get modelDraft(): FormDataShipmentEntryDraftSingle {
    return super.modelDraft;
  }

  private _formModel: FormDataSingleShipmentRevenue;
  get formModel(): FormDataSingleShipmentRevenue {
    return this._formModel;
  }
  set formModel(value) {
    this._formModel = value;
  }

  public selectLocations = [];

  ngOnInit(): void {
    this.updateSelectLocations();
  }

  updateSelectLocations() {
    let pickInfo = this.modelDraft?.pickInfo;
    let dropInfo = this.modelDraft?.dropInfo;
    if (!pickInfo || !dropInfo) return;
    this.selectLocations = [
      { deliveryId: pickInfo.id, type: pickInfo.type, label: 'Pickup Location 1'},
      { deliveryId: dropInfo.id, type: dropInfo.type, label: 'Delivery Location 1'},
    ]
  }

}