import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ShortContactInfo } from './short_contact';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
  ],
  declarations: [ShortContactInfo],
  exports: [ShortContactInfo]
})
export class ModuleShortContactInfo {}