import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { Subscription } from "rxjs";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DateUtil } from "@services/date-utils";
import { ExtendValidators } from "@app/admin/base/validator";
import { ThirdPartyPost } from "@wearewarp/types/data-model/types/third-party";
import { thirdPartyPostMapper } from "@wearewarp/universal-libs/src/mapper/ThirdPartyPost";
import { VehicleTypeService } from "@services/vehicle.service";
import { ModalHelper } from '@wearewarp/ng-antd';
import { ThirdPartyPostResultComponent } from "../result/third-party-post-result";

@Component({
  selector: "third-party-post-detail2",
  templateUrl: "./third-party-post.component.html",
  styleUrls: ["./third-party-post.component.scss"],
})
export class ThirdPartyPostDetail2Component extends BaseFormItem {
  @Input() data: ThirdPartyPost;
  @Input() isShowDialog: boolean = false;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeOnSuccess: EventEmitter<any> = new EventEmitter<any>();

  public isError = false;
  public isLoading = false;
  public listAddressAutoComplete: any = {};
  private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;
  equipmentType = [];
  availableOptions = [
    { label: "Lift gate", value: "LIFT_GATE", checked: false, disabled: false },
    { label: "Team", value: "TEAM", checked: false, disabled: false },
    { label: "Drop", value: "DROP", checked: false, disabled: false },
    { label: "Pallet jack", value: "PALLET_JACK", checked: false, disabled: false },
  ];
  contactInfoOption = [];

  availableParties = [
    { label: "DAT", value: "dat", checked: true, logo: "assets/img/logo_dat.png" },
    { label: "Truckstop", value: "truckstop", checked: true, logo: "assets/img/truckstop_logo.png" },
    { label: "Trucker Path", value: "truckerpath", checked: true, logo: "assets/img/truckerpath_logo.png" },
  ]

  carrierBidId: string;
  editId: string;
  isClosed = false;
  // shouldShowTruckstop = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    origin: {
      label: "Origin (City, State)",
      placeHolder: "Origin (City, State)",
      required: true,
      type: "string",
    },
    destination: {
      label: "Destination (City, State)",
      placeHolder: "Destination (City, State)",
      required: true,
      type: "string",
    },
    pickupFromDate: {
      label: "Pickup Earliest",
      placeHolder: "Pickup Earliest",
      required: true,
      type: "date",
      getValue: (val) => this.getPickupDate(val),
      formatValue: (val) => this.formatPickupDate(val),
    },
    pickupToDate: {
      label: "Pickup Latest",
      placeHolder: "Pickup Latest",
      required: false,
      type: "date",
      getValue: (val) => this.getPickupDate(val),
      formatValue: (val) => this.formatPickupDate(val),
    },
    pickupFromTime: {
      label: "Pickup Early Hours",
      placeHolder: "Pickup Early Hours",
      required: false,
      type: "time",
    },
    pickupToTime: {
      label: "Pickup Latest Hours",
      placeHolder: "Pickup Latest Hours",
      required: false,
      type: "time",
    },
    pickupTimezone: {
      label: "Pick up timezone",
      placeHolder: "Pick up timezone",
      required: false,
      type: "string",
    },
    dropFromDate: {
      label: "Drop Off Earliest",
      placeHolder: "Drop Off Earliest",
      required: true,
      type: "date",
      getValue: (val) => this.getDropDate(val),
      formatValue: (val) => this.formatDropDate(val),
    },
    dropToDate: {
      label: "Drop Off Latest",
      placeHolder: "Drop Off Latest",
      required: false,
      type: "date",
      getValue: (val) => this.getDropDate(val),
      formatValue: (val) => this.formatDropDate(val),
    },
    dropFromTime: {
      label: "Drop Off Early Hours",
      placeHolder: "Drop Off Early Hours",
      required: false,
      type: "time",
    },
    dropToTime: {
      label: "Drop Off Latest Hours",
      placeHolder: "Drop Off Latest Hours",
      required: false,
      type: "time",
    },
    dropTimezone: {
      label: "Drop off timezone",
      placeHolder: "Drop off timezone",
      required: false,
      type: "string",
    },
    loadType: {
      label: "Load Type",
      required: true,
      type: "string",
    },
    equipmentType: {
      label: "Equipment Type",
      required: true,
      type: "array",
    },
    lengthFeet: {
      label: "Length (ft)",
      required: true,
      type: "number",
      placeHolder: '.ft'
    },
    widthFeet: {
      label: "Width (ft)",
      required: true,
      type: "number",
      placeHolder: '.ft'
    },
    heightFeet: {
      label: "Height (ft)",
      required: true,
      type: "number",
      placeHolder: '.ft'
    },
    weightPounds: {
      label: "Weight (Ibs)",
      required: true,
      type: "number",
      validators: ExtendValidators.min(1, "Weight must be greater than 0"),
    },
    defaultComment: {
      label: "Default comment",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Comments must be less than or equal 70 characters"),
    },
    additionalComment: {
      label: "Additional comment (optional)",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Comments must be less than or equal 70 characters"),
    },
    commodity: {
      label: "Commodity",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Commodity must be less than or equal 70 characters"),
    },
    isTrackingRequired: {
      label: "Required Tracking",
      required: false,
      type: "boolean",
    },
    bidId: {
      label: "Bid Id",
      required: false,
      type: "string",
    },
    shouldPostToDat: {label: "", required: false, type: "boolean" },
    shouldPostToTruckstop: { label: "", required: false, type: "boolean" },
    equipmentOptions: {
      label: "Equipment Options",
      required: false,
      type: "array",
      getValue: this.getValueEquipmentOptions.bind(this),
      formatValue: this.formatValueEquipmentOptions.bind(this),
    },
    parties: {
      label: "Third-Party",
      required: true,
      type: "array",
      getValue: this.getValueParties.bind(this),
      formatValue: this.formatValueParties.bind(this),
      initialValue: this.availableParties.map(p => p.value)
    },
    showStop: {
      label: "Show stops",
      required: false,
      type: "boolean",
    },
    stops: {
      label: "Stops",
      required: false,
      type: "array",
    }
  };

  vehicleTypeService: VehicleTypeService;

  constructor(api: ApiService, private modalHelper: ModalHelper) {
    super();
    this.api = api;
    this.vehicleTypeService = new VehicleTypeService(this.api);
    this.skipBindingAfterCreate = true;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEquipment();
  }

  protected beforeBindModel(model): any {
    return thirdPartyPostMapper.mapToFormData(model);;
  }

  ngOnChanges(): void {
    if (!this.data) return;

    this.createFormInput(this.data);
    if (this.isCreateNew) {
      this.setItemValue('shouldPostToDat', true);
      this.setItemValue('shouldPostToTruckstop', true);
    }

    this.editId = this.data.id || "";
    if (this.editId) {
      this.model = this.data;
      this.isClosed = !!this.data?.close?.when;
      if (this.isClosed) this.setEnableFormGroup(false);
      else this.onBtnEdit();
    }
  }

  get isCreateNew(): boolean {
    return !this.editId;
  }

  getApiUrl() {
    return this.data.id ? Const.APIURI_THIRD_PARTY_POST : Const.APIURI_THIRD_PARTY_POST  + '/multi';
  }

  buildUrl(): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  onCreateSuccess(resp) {
    this.modalHelper.open(ThirdPartyPostResultComponent, {
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzCancelText: null,
      nzCancelDisabled: true,
      nzComponentParams: {
        data: resp.data?.list_data,
        error: resp.message?.toLowerCase() !== "success" ? resp.message : null,
      },
      onOK: () => {
        this.onBack.emit();
        this.closeOnSuccess.emit();
      }
    })
  }

  onUpdateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo("The item has been updated successfully.");
    }
    this.isEditing = false;
    this.closeOnSuccess.emit();
  }

  onBtnComeBack() {
    this.onBack.emit();
  }

  onBtnCancel() {
    this.bindDataModel(this.model);
    this.isEditing = false;
    if (this.isShowDialog) {
      this.onBack.emit();
    }
  }

  onBtnDelete() {
    const url = this.buildUrl();
    this.onProgress = true;
    this.api.DELETE(url).subscribe(
      (resp) => {
        this.showInfo("The item has been deleted successfully.");
        this.onDelete.emit();
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  onBtnRefresh() {
    const url = this.buildUrl();
    this.onProgress = true;
    this.api.POST(`${url}/refresh`).subscribe(
      (resp) => {
        const data = resp.data;
        if (this.model && data.refresh) {
          this.model.refresh = data.refresh;
        }
        this.showInfo("The item has been refreshed successfully.");
        this.onProgress = false
      },
      (error) => {
        this.showErr(error);
        this.onProgress = false
      },
    );

  }

  onBtnEdit() {
    super.onBtnEdit();
    this.getItemByKey("origin").disable();
    this.getItemByKey("destination").disable();
  }

  getEquipment() {
    this.isLoading = true;
    this.vehicleTypeService.listAll(
      (data) => {
        this.isLoading = false;
        const types = data.filter((it) => it.active !== false);
        const allTypes = {};
        for (let t of types) {
          allTypes[t.code] = t;
        }
        this.equipmentType = data
          .filter((it) => it.active !== false)
          .map((t) =>
            Object.assign(
              {},
              {
                label: t.name,
                groupLabel: allTypes[t.parent]?.name,
                value: t.code,
                disabled: !t.selectable,
                options: t.options || [],
              }
            )
          );

        if (this.data.equipment?.code) {
          this.onVehicleChange(this.data.equipment.code);
        }
      },
      () => {}
    );
  }

  onVehicleChange(event) {
    const vechile = this.equipmentType.find((it) => it.value === event);
    const vehicleOptions = vechile?.options || [];
    this.availableOptions.forEach((o) => {
      o.disabled = !vehicleOptions.includes(o.value);
      o.checked = o.checked? vehicleOptions.includes(o.value) : false;
    });
  }

  get needUpdate() {
    const _needUpdate = super.needUpdate
    return this.availableParties.some(p => p.checked) && _needUpdate;
  }

  // onVehicleOptionChange(event) {
  //   console.log("onVehicleOptionChange", event);
  //   this.availableOptions = event;
  // }

  getValueEquipmentOptions(val) {
    // console.log("getValue", val);
    return this.availableOptions.filter(v => v.checked).map(o => o.value)
  }

  formatValueEquipmentOptions(val = []) {
    // console.log("formatValue", val);
    this.availableOptions.forEach(o => o.checked = val.includes(o.value))
    return this.availableOptions
  }

  getValueParties(val) {
    // console.log("getValue", val);
    return this.availableParties.filter(v => v.checked).map(o => o.value)
  }

  formatValueParties(val = []) {
    // console.log("formatValue", val);
    this.availableParties.forEach(o => o.checked = val.includes(o.value))
    return this.availableParties
  }

  onPartyChange(event) {
    console.log('onPartyChange', event);
    this.availableParties.forEach(p => p.checked = event.includes(p.value));
  }

  getPartyValue(name) {
    return this.availableParties.find(p => p.value === name)?.checked;
  }


  getEquipmentType(type) {
    return `${type?.label} - ${type?.value}`
  }

  getContactInfo(data) {
    this.contactInfoOption = data.map((item) => {
      return {
        label: `${item?.value} (${item.method})`,
        value: item?.value,
      };
    });
  }

  formatDate(date) {
    return DateUtil.convertLocalTime(date, DateUtil.getLocalTimeZone())
  }

  formatPickupDate(date) {
    return DateUtil.convertLocalTime2(date, this.data.pickDt?.timezone)
  }

  getPickupDate(date) {
    return DateUtil.convertLocalTime(date, this.data.pickDt?.timezone)
  }

  formatDropDate(date) {
    return DateUtil.convertLocalTime2(date, this.data.dropDt?.timezone)
  }

  getDropDate(date) {
    return DateUtil.convertLocalTime(date, this.data.dropDt?.timezone)
  }

  private tryAddressSuggestion(elm: HTMLInputElement) {
    clearTimeout(this.timerAddressSuggestion);
    let userInput = elm.value;
    if (!userInput) {
      this.subAddressSuggestion?.unsubscribe();
      this.listAddressAutoComplete[elm.id] = [];
      return;
    }
    this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, userInput), 100);
  }

  onInputChanged(event, key) {
    switch (key) {
      case "origin":
      case "destination":
        return this.tryAddressSuggestion(event.target);
      default:
        return super.onInputChanged(event, key);
    }
  }

  private doAddressSuggestion(elm: HTMLInputElement, userInput: string) {
    this.subAddressSuggestion?.unsubscribe();
    this.subAddressSuggestion = this.api.searchUsCities(userInput).subscribe(
      (resp) => {
        this.listAddressAutoComplete[elm.id] = resp.data.list_data.map((it) => `${it.city}, ${it.stateCode}`);
      },
      (err) => {
        Log.e("doAddressSuggestion failed. ", err);
      }
    );
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

  get isAutoPost() {
    return this.data?.autoPost || false;
  }

  getPartyLogo() {
    switch (this.data?.party) {
      case "dat":
        return "assets/img/logo_dat.png";
      case "truckstop":
        return "assets/img/truckstop_logo.png";
      case "truckerpath":
        return "assets/img/truckerpath_logo.png";
      default:
        return "";
    }
  }

  getTextTimezone(tz) {
    return `${tz} (${DateUtil.timezoneStandardToUsShort(tz)})`
  }

  getStopAddress(stop) {
    return `${this.capitalizeFirstLetter(stop.type.toLowerCase())} at ${stop.info?.addr?.street}, ${stop.info?.addr?.city}, ${stop.info?.addr?.state}, ${stop.info?.addr?.zipcode}`
  }

  getStopTime(stop) {
    return this.displayTimeWindow(stop.info?.windows?.[0], stop.info)
  }
}
