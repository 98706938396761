import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DetailModule } from '../base/detail.module';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { WarehouseList } from './list';
import { WarehouseForm } from './warehouse-form';
import { WarehouseFormV2 } from './warehouse-form-v2';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { WarehouseInventory } from './warehouse-inventory';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { WarehouseDetail } from './detail';
import { WarehouseItems } from './shipments/items';
import { WarehouseItemListItem } from './shipments/item';
import { TransitNetworkModule } from '../transit-network/module';
import { WarehouseItemContent } from './shipments/item_content';
import { WarehouseItemPod } from './shipments/pod';
import { WarehouseItemShipmentItem } from './shipments/shipment_item';
import { WarehouseMap } from './warehouse-map';
import { WarehouseFilter } from './warehouse-filter';
import { WarehouseTask } from './warehouse-task';
import { MapModule } from '../components/map/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    SearchBoxModule,
    DialogsModule,
    DetailModule,
    FormAddressUSModule,
    SelectBySearchingModule,
    SharedModule,
    TransitNetworkModule,
    PdfViewerModule,
    MapModule,
  ],
  declarations: [
    WarehouseList,
    WarehouseFormV2,
    WarehouseForm,
    WarehouseInventory,
    WarehouseDetail,
    WarehouseItems,
    WarehouseItemListItem,
    WarehouseItemContent,
    WarehouseItemPod,
    WarehouseItemShipmentItem,
    WarehouseMap,
    WarehouseFilter,
    WarehouseTask
  ],
  exports: [
    WarehouseList,
    WarehouseFormV2,
    WarehouseForm,
    WarehouseDetail,
    WarehouseItemListItem
  ],
  providers: [
  ]
})
export class WareHouseModule {}