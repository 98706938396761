import { Const } from '@const/Const';
import { Component, Output, EventEmitter, ElementRef, TemplateRef } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { BaseFormItem } from '@app/admin/base/form-item';
import { KeyLang } from '@locale/generated/keys';
import { Utils } from '@services/utils';
import { DialogService } from '@app/dialogs/dialog.service';
import { DrawerService } from '@app/drawers/drawer.service';
import { NotificationDispatchService } from '@app/drawers/notification-dispatch.service';
import { Log } from '@services/log';
import { FreightQuoteExportForm } from '../freight-quote/export-form';
import { isTopbarTitleInterface } from '@app/admin/shared/TopbarTitle';
import { InternalMessageService } from '@services/internal-message.service';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from '@services/socket.service';
import { environment } from '@env/environment';
import { EnvType } from '@env/type';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavAdminComponent extends BaseFormItem {
  private _sideBarStatus;
  public isOnline = false;
  public badgeNumber = 0; // dummy
  public navMenuItems;
  // public pageTitle: string = '';
  protected notifyDispathService: NotificationDispatchService;
  private timerCountNotification;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dlgService: DialogService,
    private drwService: DrawerService,
    private messageService: InternalMessageService,
    private activeRoute:ActivatedRoute,
    private socket: SocketService,
  ) {
    super();
    this.notifyDispathService = this.injector.get(NotificationDispatchService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.countNotificatiosnPeriodically();
    if(this.topbarTitleString === "Cross Dock Management") this.loadData();
    this.activeRoute.queryParams.subscribe(params => {
      setTimeout(()=>{
        this.handleParamsForDispatch(params);
      }, 0);
    });
    this.subscription.add(this.socket.subscribeConnectionStatus(status => this.isOnline = status));
  }

  private countNotificatiosnPeriodically() {
    this.countNotifications();
    if (!this.timerCountNotification) {
      this.timerCountNotification = setInterval(() => this.countNotifications(), 60000);
    }
  }

  private stopCountNotificatiosnPeriodically() {
    if (this.timerCountNotification) {
      clearInterval(this.timerCountNotification);
      this.timerCountNotification = null;
    }
  }

  protected didLogin(): void {
    this.countNotificatiosnPeriodically();
  }

  protected didLogout(): void {
    this.stopCountNotificatiosnPeriodically();
  }

  public menuItemClick(item) {
    if (!item.key) {
      return;
    }
    switch (item.key) {
      case KeyLang.Txt_Profile:
        this.router.navigate([`${this.routeAdminUserList}/${this.authUser._id}`]);
        break;
      case KeyLang.Txt_ChangePw:
        this.dlgService.changePwd();
        break;
      case KeyLang.Txt_Logout:
        this.confirmLogout();
        break;
      case KeyLang.Txt_English:
        this.changeLanguage(Const.LANG_EN);
        break;
      case KeyLang.Txt_Vietnamese:
        this.changeLanguage(Const.LANG_VI);
        break;
      default:
        break;
    }
  }

  public get badgeNotificationDispatchNumber() {
    return this.notifyDispathService.getNotificationsNumber();
  }

  public showNotificationDispath() {
    this.drwService.notificationDispatch();
  }

  public onBtnShowFreightQuoteExportForm() {
    DialogService.openFormDialog1(FreightQuoteExportForm, {
      nzComponentParams: {
      },
      nzClassName: 'modal-no-padding modal-service-option',
    });
  }

  public onBtnShowCreateManualLoad() {
    setTimeout(()=>{
      this.messageService.sendMessageCreateManualLoad(true);
    }, 0);
  }

  private confirmLogout() {
    this.modalService.confirm({
      nzContent: this.text(KeyLang.Txt_ConfirmLogout),
      nzWrapClassName: 'vertical-center-modal',
      nzOkText: this.txtOK,
      nzOkType: 'primary',
      nzOnOk: () => {
        this.api.logout();
        this.goLogin();
      },
      nzCancelText: this.txtCancel,
      nzOnCancel: () => {}
    })
  }

  get userName() {
    return this.authUser ? this.authUser.fullName || this.authUser.email : '';
  }
  get userAvatar() {
    return Utils.defaultAvatarUrl();
  }
  get isSideBarSmall() {
    return this._sideBarStatus === Const.SIDEBAR_SMALL;
  }
  protected setupLanguage() {
    if (!this.navMenuItems) {
      this.initMenuItems();
    }
    const lang = this.trans.currentLang;
    for (let i = 0; i < this.navMenuItems.length; i++) {
      if (!this.navMenuItems[i].key) {
        continue;
      }
      this.navMenuItems[i].label = this.text(this.navMenuItems[i].key);
      if (this.navMenuItems[i].items) {
        for (let j = 0; j < this.navMenuItems[i].items.length; j++) {
          if (!this.navMenuItems[i].items[j].key) {
            continue;
          }
          this.navMenuItems[i].items[j].label = this.text(this.navMenuItems[i].items[j].key);
          switch (this.navMenuItems[i].items[j].key) {
            case KeyLang.Txt_Vietnamese:
              this.navMenuItems[i].items[j].hilight = lang === Const.LANG_VI;
              break;
            case KeyLang.Txt_English:
              this.navMenuItems[i].items[j].hilight = lang === Const.LANG_EN;
              break;
          }
        }
      }
    }
  }

  private initMenuItems() {
    this.navMenuItems = [
      {
        label: this.userName,
        link: `${this.routeAdminUserList}/${this.authUser._id}`,
        nzIcon: 'user'
      },
      {
        label: '',
        key: KeyLang.Txt_TwoFa,
        link: `${this.routeSetting2FA}`,
        nzIcon: 'safety'
      },
      {
        label: '',
        key: KeyLang.Txt_ChangePw,
        nzIcon: 'lock'
      },
      {
        label: '',
        key: KeyLang.Txt_Logout,
        nzIcon: 'logout'
      }
    ];
    if (environment.type == EnvType.portable) {
      this.navMenuItems.unshift({
        label: 'Setup servers',
        link: '/setup-server',
        nzIcon: 'setting'
      });
    }
  }

  private countNotifications() {
    if (!this.auth.getAccessToken()) {
      // Nếu vì lí do gì đó mà bị logout rồi thì thôi, ko count notification nữa
      return this.stopCountNotificatiosnPeriodically();
    }
    this.api.GET(`${Const.APIURI_JOB_NOTIFICATIONS}/get/count_notifications`).subscribe(
      resp => {
        let unReadNotificationNumber = resp.data.unReadNotificationNumber;
        this.notifyDispathService.setNotificationsNumber(unReadNotificationNumber);
      }, err => {
        Log.e(err);
      }
    );
  }

  onActiveComponentChanged(comp) {
    if (isTopbarTitleInterface(comp)) {
      comp.subscribeTopbarTitle((e: any) => {
        this.topbarTitleFlexible = e;
      });
    } else {
      this.topbarTitleFlexible = undefined;
    }
  }

  // Xử lý title trên thanh topbar:
  // Topbar title có thể được khai báo theo 2 cách
  // Cách cũ: khai báo cố định name trong mảng SidebarMenuItem, SidebarComponent sẽ gọi hàm setTopbarTitleFixed(), chỉ dùng được string cố định
  // Cách mới: linh hoạt hơn là component sẽ implement interface TopbarTitle, có thể trả về template hoặc string

  public get topbarTitleString(): string {
    if (typeof this.topbarTitleFlexible == 'string' && this.topbarTitleFlexible.length > 0) {
      return this.topbarTitleFlexible;
    } else {
      return this.topbarTitleFixed;
    }
  }

  public get topbarTitleTemplate(): TemplateRef<any> {
    if (this.topbarTitleFlexible instanceof TemplateRef) {
      return this.topbarTitleFlexible;
    } else {
      return undefined;
    }
  }

  private topbarTitleFixed: string = '';

  public setTopbarTitleFixed(str: string) {
    this.topbarTitleFixed = str;
  }

  private topbarTitleFlexible: string | TemplateRef<any>;

  keys = {
    inboundRoute: 'inbound',
    outboundRoute: 'outbound',
    inventoryManagement: 'inventoryManagement'
  }

  currentKey = this.keys.inboundRoute;

  tabs = [
    {
      name: 'Inbound Routes',
      key: this.keys.inboundRoute,
    },
    {
      name: 'Outbound Routes',
      key: this.keys.outboundRoute
    },
    {
      name: 'Inventory Management',
      key: this.keys.inventoryManagement
    }
  ]

  getTabActive(key): boolean {
    return key === this.currentKey;
  }

  private buildQuery(type = null): any {
    let url = new URL(window.location.href);
    let condition: any = {}
    url.searchParams.forEach((value, key) => {
      condition[key] = value;
    });
    if(condition.type !== type && type) condition.type = type;
    if(!type) this.currentKey = condition.type;
    if(!Object.values(condition).length) condition = { page: 1, loaded: Date.now(), type }
    if(condition.loaded) condition = { ...condition, loaded: Date.now() }
    return condition;
  }

  setTabCurrent(key) {
    this.currentKey = key;
    this.routeWithQueryUrl(this.buildQuery(key));
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery());
  }

  // for MyLoad / All Load tabs:
  selectDispatchTabIndex = 0;
  onDispatchTabChange() {
    setTimeout(()=>{
      this.messageService.sendMessage(this.getDispatchTabName());
    }, 0);
   
  }
  private getDispatchTabName(){
    switch (this.selectDispatchTabIndex) {
      case 0:
        return Const.DispatchTab.all_load;
      case 1:
        return Const.DispatchTab.my_load;
      case 2:
        return Const.DispatchTab.tracking_task;
    }
  }

  handleParamsForDispatch(params) {
    let newSelectedIndex = -1;
    if(params?.tab == Const.DispatchTab.all_load) newSelectedIndex = 0;
    else if (params?.tab == Const.DispatchTab.my_load) newSelectedIndex = 1;
    else if (params?.tab == Const.DispatchTab.tracking_task) newSelectedIndex = 2;
    if(newSelectedIndex!=this.selectDispatchTabIndex){
      this.selectDispatchTabIndex = newSelectedIndex;
    } 
    if(newSelectedIndex!=-1)
    this.messageService.sendMessage(this.getDispatchTabName());
  }
}
