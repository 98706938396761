<div class="photo-album">
    <div class="thumbnails">
        <div *ngFor="let item of items" class="item">
            <div class="info">
                <div class="flex">
                    <div class="flex1 font-medium">{{ barcodeText(item.item.barcodes) }}</div>
                    <div>{{ item.item.actualWeight ?? item.item.weightPerUnit }}{{ item.item.weightUnit }}</div>
                </div>
                <!-- <div>
                    <span>{{ item.item.width }}</span> <span nz-icon nzType="close" nzTheme="outline"></span>
                    <span>{{ item.item.height }}</span> <span nz-icon nzType="close" nzTheme="outline"></span>
                    <span class="right5">{{ item.item.length }}</span>
                    <span>{{ item.item.sizeUnit }}</span>
                </div> -->
            </div>
            <div *ngFor="let photo of item.photos" (click)="onSelectPhoto(photo)" class="thumbnail" [class]="{selected: photo.id == selectedPhoto?.id}">
                <div *ngIf="photo.date" style="font-size: 11px;" class="flex1 font-medium">
                    {{ photo.date }}
                </div>
                <img [src]="photo.url">
            </div>    
        </div>
    </div>
    <div class="viewer">
        <img [src]="selectedPhoto?.url" *ngIf="selectedPhoto">
    </div>
</div>